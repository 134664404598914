import React, { useEffect, useState } from 'react';

function MyComponent() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    console.log(items);
  }, [items])
  

  const addItem = () => {
    const newItem = { id: Date.now(), name: `Item ${items.length + 1}` };
    setItems(prevItems => prevItems ? [...prevItems, newItem] : [newItem]);

    
  };

  const removeItem = (id) => {
    setItems(prevItems => prevItems.filter(item => item.id !== id));
  };

  return (
    <div>
      <button onClick={addItem}>Add Item</button>
      {items.map(item => (
        <div key={item.id}>
          {item.name}
          <button onClick={() => removeItem(item.id)}>Remove</button>
        </div>
      ))}
    </div>
  );
}

export default MyComponent;
