import React, { useEffect } from 'react'
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import { MdKeyboardArrowRight, MdOutlineDeleteOutline, MdSave } from 'react-icons/md';
import {BsBoxes} from 'react-icons/bs';
import {TbToolsKitchen2} from 'react-icons/tb';
import {BiDish} from 'react-icons/bi';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const ManageDiscounts = ({pass}) => {

  const history = useNavigate();



  useEffect(() => {
    history(-1);
  
   
  }, [])
  

  

    const user =  fetchUser();
 

    const userRole = fetchRole();
  


  return (

    user && userRole && (userRole === "admin") ? (

      

        pass === "new" ? (
          <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
            <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Discounts</Link><MdKeyboardArrowRight />Create New Item</div>
      <div className="w-full  flex  justify-center mt-6">
        
        <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col  gap-4 bg-white">
  
      
  
  
                    <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                    <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => window.createNotification("info","Not Available", "Contact Admins")}>Clear <MdOutlineDeleteOutline /></button>
                      <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => window.createNotification("info","Not Available", "Contact Admins")}>Save <MdSave /></button>
                    </div>
        </div>
      </div></div>) :  pass === "manage" ? (<>Under Development</>):(

        /* Stock Types */


        <div className='text-center py-16 w-full flex flex-col gap-8 justify-center items-center m-auto select-none'> 
  
        <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight />Manage Stock</div>
        
        <Link to={"../admin/manageStock/mainStock"} className='w-full md:w-[40%]'>
          <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-red-500 p-3 rounded-lg hover:bg-red-400 gap-2 w-full'>Main Stock <BsBoxes /></button>
        </Link> 
  
        <Link to={"../admin/manageStock/kitchenStock"} className='w-full md:w-[40%]'>
          <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-yellow-700 p-3 rounded-lg hover:bg-yellow-600 gap-2 w-full'>Kitchen Stock<TbToolsKitchen2 /></button>
        </Link>

        
        <Link to={"../admin/manageStock/tempStock"} className='w-full md:w-[40%]'>
          <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-orange-500 p-3 rounded-lg hover:bg-orange-400 gap-2 w-full'>Temp Stock<BiDish /></button>
        </Link>
        </div>


      )
    ) : (
  
      
          
  <Navigate to="/" />
    )
  
    )
   
  
}

export default ManageDiscounts