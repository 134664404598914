import React from 'react';
import { useNavigate} from 'react-router-dom';
import {FaRegMehBlank, FaAngleLeft, FaAngleRight, FaHome} from 'react-icons/fa';

const E404 = ({pass}) => {
  const history = useNavigate();



  return (
    <div className='w-full bg-white p-4 rounded-md shadow-md mb-5 flex flex-col gap-4 justify-center items-center mt-5'><FaRegMehBlank className='text-5xl text-red-600' /><p className='text-base sm:text-lg md:text-xl font-semibold text-red-600 w-fu;; text-center select-none'>

{pass ? pass.split("\n").map(function(item, idx) {
        return (
            <span key={idx}>
                {item}
                <br/>
            </span>
         )
    }) : 'The requested page is not available!'}

     

      
      
      </p>  


      <div className='w-full flex flex-col sm:flex-row justify-center items-center gap-2 mb-10 p-10 select-none'>

      <button className='p-2 bg-red-600 text-white rounded-lg text-lg font-semibold flex flex-row items-center justify-center gap-2 hover:shadow-xl hover:text-xl w-full sm:w-auto' onClick={(e) => history(-1)}><FaAngleLeft /> Go Back</button>
      <button className='p-2 bg-green-600 text-white rounded-lg text-lg font-semibold flex flex-row items-center justify-center gap-2 hover:shadow-xl hover:text-xl w-full sm:w-auto' onClick={(e) => history("../")}><FaHome /> Home</button>
      <button className='p-2 bg-blue-600 text-white rounded-lg text-lg font-semibold flex flex-row items-center justify-center gap-2 hover:shadow-xl hover:text-xl w-full sm:w-auto' onClick={(e) => history(1)}> Go Next <FaAngleRight /></button>

      </div>

</div>
  )
}

export default E404