import React, { useEffect, useRef } from 'react';
import NotSound from '../img/not.wav'

const NotificationSound = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Play the sound when the component mounts
    try {
        audioRef.current.play();
    }catch {
        console.log("Unable to Play!");
    }
  }, []);

  return (
    <audio ref={audioRef} src={NotSound} />
  );
};

export default NotificationSound;