import React, { useEffect, useState } from 'react'
import { fetchAdmin, fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import { motion } from 'framer-motion';

const MyData = () => {

    const userRole = fetchRole();
    const user = fetchUser();
    const admin = fetchAdmin();

    const [admSecs, setAdmSecs] = useState(0);
    const [lastLogin, setLastLogin] = useState(0);

   

    const  formatSeconds = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);
      
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }

  useEffect(() => {
    if(user){

        setInterval(() => {

            const now = new Date(parseFloat(user.lastLoginAt));
    
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hour = String(now.getHours()).padStart(2, '0');
            const minute = String(now.getMinutes()).padStart(2, '0');
            const second = String(now.getSeconds()).padStart(2, '0');
            
            const currentDate = new Date();
        
           const timeDiff = Math.abs(currentDate.getTime() - now.getTime());
        
            const minDiff = Math.floor(timeDiff / (1000 ));
        
            setAdmSecs(86400 -minDiff);
        
            setLastLogin(`${year}-${month}-${day} | ${hour}:${minute}:${second}`);
            
        }, 1000);
       
       }
    
  }, [])
  

  return (
    <div className='top-10 flex flex-row text-center w-full bg-white justify-center p-4 rounded-md drop-shadow-md select-none'>


    <table className='w-full p-5 flex flex-row justify-center items-center overflow-x-auto scrollbar-thin'>

        <tbody className={`${'w-full m-auto block md:inline-block text-center justify-center items-center'} sm:w-auto`}>
            {admin.admImg && ( <tr className='w-full flex text-center items-center justify-center p-3'>
              
                <th colSpan={3} className='p-1 text-center justify-center items-center'><motion.img whileHover={{scale:1.3}} className='w-[150px] content-none h-[150px] object-fill rounded-[100%] border-2 border-slate-800 hover:drop-shadow-xl' src={admin.admImg} alt="DP" /></th>
            </tr>)}

            <tr className='w-full'>
                <th className='p-1 text-left'>Role</th>
                <th>:</th>
                <td className='p-1 text-left'>{userRole.toUpperCase()}</td>
            </tr>

            <tr className='w-full'>
                <th className='p-1 text-left'>Name</th>
                <th>:</th>
                <td className='p-1 text-left'>{admin.admName}</td>
            </tr>

            <tr className='w-full'>
                <th className='p-1 text-left'>Email</th>
                <th>:</th>
                <td className='p-1 text-left'>{admin.admEmail}</td>
            </tr>

            <tr className='w-full'>
                <th className='p-1 text-left'>Phone</th>
                <th>:</th>
                <td className='p-1 text-left'>{admin.admPhone}</td>
            </tr>

            <tr className='w-full'>
                <th className='p-1 text-left'>Last Login:</th>
                <th>:</th>
                <td className='p-1 text-left'>{lastLogin}</td>
            </tr>

            
  


                <tr className='w-full'>
                <th className='p-1 text-left'>Session Expires in</th>
                <th>:</th>
                <td className='p-1 text-left'>{admSecs && (<span>{formatSeconds(admSecs)}</span>)}</td>
                </tr>
           

            </tbody>

           
        </table>



    </div>
  )
}

export default MyData;