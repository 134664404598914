import CryptoJS from 'crypto-js';
import { getUserRole, getVipCustomer} from './firebaseFunctions';

export const fetchUser  = () => {

    try{
        const userInfo = localStorage.getItem('user') !== null ? 
    JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8))

    : localStorage.clear();

    return userInfo;
    }catch{
        console.log("Unable to collect user data!");
    }
     
} 

export const fetchCart  = () => {

  

    try{
       const cartInfo = localStorage.getItem('cartItems') !== null ? 
    JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('cartItems'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8))

    : localStorage.removeItem('cartItems');

    /*const cartInfo = localStorage.getItem('cartItems') !== null ? 
    JSON.parse(localStorage.getItem('cartItems'))

    : localStorage.removeItem('cartItems');*/

    return cartInfo ? cartInfo : [];
    }catch{
        console.log("Unable to collect cart data!");
        return [];
    }
     
} 

export const fetchBranch  = () => {

  

    try{
       const branchInfo = localStorage.getItem('userBranch') !== null ? 
    JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userBranch'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8))

    : localStorage.removeItem('userBranch');

    /*const cartInfo = localStorage.getItem('cartItems') !== null ? 
    JSON.parse(localStorage.getItem('cartItems'))

    : localStorage.removeItem('cartItems');*/

    return branchInfo ? branchInfo : null;
    }catch{
        console.log("Unable to collect branch data!");
        return null;
    }
     
} 




export const fetchRole  = () => {

    
    const user = fetchUser();


   

    if(user && localStorage.getItem('userRole')){
       
        if(getUserRole(user.email,user.phoneNumber)){

    const userRole = localStorage.getItem('userRole') !== null ? 
    CryptoJS.AES.decrypt(localStorage.getItem('userRole'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8)
    : localStorage.clear();

    //console.log("100");
    return userRole;

    }else{

        //console.log("90");
        
    }

    }else{

       // console.log("91");
       
    }

 
     
} 

export const fetchOrder = () => {

    
    const user = fetchUser();


   

    if(user && localStorage.getItem('orders')){
       
        if(getUserRole(user.email,user.phoneNumber)){

    const order = localStorage.getItem('orders') !== null ? 
    CryptoJS.AES.decrypt(localStorage.getItem('orders'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8)
    : localStorage.clear();

    //console.log("100");
    return order;

    }else{

        //console.log("90");
        
    }

    }else{

       // console.log("91");
       
    }

 
     
} 


export const fetchCustomer  = () => {

    
    const user = fetchUser();

   

    if(user){
       
        if(getVipCustomer(user.email, user.phoneNumber)){

    const customer = localStorage.getItem('customer') !== null ? 
    JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('customer'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8))
    : console.log("Trying...");

   // console.log("100");
    return customer;

    }else{

       // console.log("90");
        
    }

    }else{

      // console.log("91");
       
    }

 
     
} 


export const fetchAdmin  = () => {

    
    const user = fetchUser();

   

    if(user){
       
        if(getVipCustomer(user.email,user.phoneNumber)){

    const admin = localStorage.getItem('admin') !== null ? 
    JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('admin'), localStorage.getItem('ffkey')).toString(CryptoJS.enc.Utf8))
    : console.log("Trying... Adm");

   // console.log("100");
    return admin;

    }else{

       // console.log("90");
        
    }

    }else{

      // console.log("91");
       
    }

 
     
} 


