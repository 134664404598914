import React,{ useEffect, useRef, useState } from 'react';
import HomeContainer from './HomeContainer';
import { motion } from 'framer-motion';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import RowContainer from './RowContainer';
import {useStateValue} from '../context/StateProvider';
import { Link } from 'react-router-dom';
import MenuContainer from './MenuContainer';
import { fetchBranch } from '../utils/fetchLocalStorageData';






export const MainContainer = () => {

    const [{foodItems}, dispatch] = useStateValue();

    const [scrollValue, setScrollValue] = useState(0); 
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [branch, setBranch] = useState(fetchBranch());

   useEffect(() => {}, [scrollValue]);

   useEffect(() => {

    if(searchValue.length >= 3){

     let search =  foodItems?.filter(n =>  n.category[0].toLowerCase().indexOf(searchValue) !== -1 || n.category[0].toLowerCase().indexOf(searchValue.replace(/\s/g, "")) !== -1 ||  n.title.toLowerCase().indexOf(searchValue) !== -1 || n.title.toLowerCase().indexOf(searchValue.replace(/\s/g, "")) !== -1 || n.description.toLowerCase().indexOf(searchValue) !== -1 || n.description.toLowerCase().indexOf(searchValue.replace(/\s/g, "")) !== -1);

     const results = [];

    search.map(item => {

      results.push(item);
      
    });

          if(branch){
            setSearchResults(results);
          }else{
            
      setSearchResults([]);
          }

    }else{


      setSearchResults([]);
     
    }
     
   }, [searchValue]);
   
   const toCamelCase = (str) => {
    str = " " + str;
    return str
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(' ');
  };

    return (
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <HomeContainer />

        <motion.div className="w-full text-center justify-center mt-8 border-t-2 p-3" initial={{opacity:0, y:100}} animate={{opacity:1, y:0}} exit={{opacity:0, y:100, }} transition={{  type: "spring", stiffness: 100, delay:2 }}>
          
          <input className='w-full md:w-[50%] h-14 text-lg text-center border-2 border-orange-400 hover:border-orange-600 outline-none rounded-lg p-2 font-semibold z-20' type="search" placeholder='Search for Foods 🔍'

        onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
        value={searchValue}
        
        />

        {searchValue.length >= 3 && searchResults.length > 0 && (
        
        <div className="w-[90%] items-center justify-center text-center flex flex-col absolute rounded-md border-2 border-orange-400 border-t-1 shadow-lg drop-shadow-lg z-10 overflow-scroll scrollbar-none select-none"> 

        {searchResults.map((result) => (
        <motion.div key={result.id} className="bg-gray-50 text-headingColor items-center justify-center text-center w-full" initial={{opacity:0, y:-10}} animate={{opacity:1, y:0}} exit={{opacity:0, y:-10, }} transition={{  type: "spring", stiffness: 100, delay:0.2 }}>
          <Link to={"/item/"+result.id}>
          <div className="font-semibold  text-left cursor-pointer border-b-orange-200 hover:bg-gray-200 border-b-2 p-3 md:flex gap-3 items-center justify-between"><motion.img className="w-10 h-10" whileHover={{scale:1.2, height:'100px', width:'auto'}} src={result?.imageURL} alt="" />{toCamelCase(result.title)}<p className="text-base text-red-500">LKR {result.price}</p></div>
          </Link>
        </motion.div>
      ))}
        </div>
        )}
        
        </motion.div>

        <section className="w-full my-6 select-none">
        <div className="w-full flex items-center justify-between">
          <p className="text-2xl font-semibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-32 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-orange-400 to-orange-600 select-none">Our Tasty Chicken Meals</p>
          <div className="hidden md:flex gap-3 items-center">
              <motion.div whileTap={{scale:0.75}} className="w-8 h-8 rounded-lg bg-orange-400 hover:bg-orange-500 cursor-pointer  hover:shadow-lg flex items-center justify-center" onClick={() => setScrollValue(-200)}>
                <MdChevronLeft className="text-lg text-white " />
              </motion.div>
              <motion.div whileTap={{scale:0.75}} className="w-8 h-8 rounded-lg bg-orange-400 hover:bg-orange-500 cursor-pointer hover:shadow-lg flex items-center justify-center" onClick={() => setScrollValue(200)}>
                <MdChevronRight className="text-lg text-white " />
              </motion.div>
          </div>
        </div>
        <RowContainer
        
        scrollValue={scrollValue}
        flag={true} 
        data={foodItems?.filter(n => {
          
          for (let i = 0; i < n.category.length; i++){

            if(n.category[i] === "chicken"){
           return (n.category[i] === "chicken")

            }

          }

          return (n.category[0] === "chicken")
        
        
        })} />

        </section>

      

            <MenuContainer />


      </div>
    )
}


export default MainContainer;