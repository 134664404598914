import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import {LoaderCustom} from './LoaderCustom';
import { Link, Navigate, useNavigate, useParams} from "react-router-dom";
import { useStateValue } from '../context/StateProvider';
import { motion } from 'framer-motion';
import {MdFastfood, MdCloudUpload, MdDelete, MdFoodBank, MdOutlineFormatAlignJustify, MdSave, MdOutlineDeleteOutline, MdAddBox, MdKeyboardArrowRight, MdOutlineDocumentScanner, MdNumbers, MdOutlineAddBox, MdPriceChange, MdProductionQuantityLimits, MdAdminPanelSettings, MdDateRange} from 'react-icons/md';
import {GiJigsawPiece, GiWeight} from 'react-icons/gi';
import {AiOutlineAppstoreAdd, AiOutlineDelete, AiOutlineNumber, AiTwotoneContainer} from 'react-icons/ai';
import Loader from './Loader';
import {deleteObject, getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import Currency from 'react-currency-icons';
import { storage } from '../firebase.config';
import { getAllFoodItems, getAllBills, saveItem, getAllFoodCategories, deleteItem, saveBill } from '../utils/firebaseFunctions';
import { actionType } from '../context/reducer';
import { fetchAdmin, fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import Select,{ OnChangeValue} from 'react-select';
import makeAnimated from 'react-select/animated';
import {useTable} from 'react-table';
import DatePicker from "react-datepicker";





export const ManageItem  = ({pass = undefined}) => {


    const {billId} = useParams();
    const currentCat = useRef(null);
  

  const history = useNavigate();


  const [uniqueBillId, setUniqueBillId] = useState("");
  const [billNo, setBillNo] = useState("");
  const [supplier, setSupplier] = useState("");
  const [calories, setCalories] = useState(""); 
  const [description, setDescription] = useState("");
const [price, setPrice] = useState("");
  const [category, setCategory] = useState([]);
  const [options, setOptions] = useState([]);
  const [imageAsset, setImageAsset] = useState(null); 
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultCat, setDefaultCat] = useState([]);
  const [{foodCategories, foodItems, supplierBills}, dispatch] = useStateValue();

  //const data = foodItems ? foodItems : [{}];
  const data = supplierBills ? supplierBills : [{}];
  const currentURL = window.location.href;


  useEffect(() => {

    if(pass === 'new'){

      setUniqueBillId(`${Date.now()}`);
      clearData();
    }

    if(pass === 'manage'){

      fetchData();
      
    }

    setCategory([]);

  

  
    
  }, [currentURL]);

  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemQty, setItemQty] = useState('');
  const [itemPieces, setItemPieces] = useState('');
  const [itemUnit, setItemUnit] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [billDate, setBillDate] = useState(new Date());

  const admin = fetchAdmin();

  const calcTotal = () => {

      let tot = 0;

   



    items && items.map((item) => {


      tot += parseFloat(parseFloat(item?.price)*item?.qty);



      
    });
    setTotalPrice(parseFloat(tot).toFixed(2));


  };

  useEffect(() => {


    calcTotal();
    
    
  }, [items])
  


  const addItem = () => {
   

    if( itemName && itemPrice && itemQty && itemPieces && itemUnit){

      let newPrice = parseFloat( itemPrice).toFixed(2);
      let newQty = parseFloat(itemQty).toFixed(2);
      let newPieces = parseFloat(itemPieces).toFixed(2);

      if(newPrice === "NaN"){

        window.createNotification("warning","Insert a valid price!", "", 3000);
        return 0;
      }

      if(newPieces === "NaN"){

        window.createNotification("warning","Insert a valid pieces count!", "", 3000);
        return 0;
      }else{

        newPieces = parseFloat(newPieces);
      }

      if(newQty === "NaN"){

        window.createNotification("warning","Insert a valid quantity!", "", 3000);
        return 0;
      }else{

        newQty = parseFloat(newQty);
      }

      let code = `${items.length + 1}`;

      if (pass === 'solo') {

        setUniqueBillId(billId);
        
      }

      let itemId = `${uniqueBillId}-${items.length + 1}`;

      console.log(itemId);

      let xar = true;
      let counter = 0;

      while(xar){

        console.log(itemId);

        counter += 1;

        let itemToCheck = { id: itemId, name: itemName };

      let itemExists = items.some(item => item.id === itemToCheck.id);

      if(itemExists){

        const sortedArray = items.sort((a, b) => a.code - b.code);;

        let lastId =  `${sortedArray[sortedArray.length-1].id}`;
        lastId = lastId.replace(new RegExp(uniqueBillId + '-', 'gi'),'');

        console.log(sortedArray);
   
        lastId = parseInt(lastId);
        code = lastId  +1;
        itemId = `${uniqueBillId}-${lastId + 1}`;

        if(counter > items.length){

          xar = false;
          console.log("ops");
          return 0;
        }


      }else{

        xar = false;
      }


      }

      
      

    if(!xar){

      const newItem = { id: `${itemId}`, name: `${itemName}`, pieces :  `${newPieces}`, unit : `${itemUnit}` , price: `${newPrice}`, qty: `${newQty}`, code: `${code}` };
      setItems(prevItems => prevItems ? [...prevItems, newItem] : [newItem]);
      window.createNotification("success",`Item (${itemId}) Added`, "", 2000);
      setItemName("");
      setItemPrice("");
      setItemQty("");
      setItemUnit("");
      setItemPieces("");
      setTotalPrice("");

    }

    




  
    }else{

      window.createNotification("warning","Complete all fields under the new item!", "", 3000);
    }

    
  };

  const removeItem = (id) => {
    setItems(prevItems => prevItems.filter(item => item.id !== id));
    window.createNotification("success",`Item (${id}) Removed`, "", 2000);
  };
  

  const validateData = async () => {

   


    if(billId && supplierBills && pass === 'solo'){



      const specItem = supplierBills.find(item => item.id === billId);


     

      

    if(specItem){

      setBillNo(specItem.billNo);
      let sup = specItem.supplier.join(',');

      setSupplier(sup);
      setItems(specItem.items);
   
     if(specItem.billImageURL){
      setImageAsset(specItem.billImageURL);
     }else{
      setImageAsset();
     }
      setDescription(specItem.description);
      setBillDate(specItem.billDate.toDate());


    }else{
      console.log("No Specified Item!");
  
    }
    }else{

      console.log("opi");
   
      //validateData();
 
    }
  };

  useEffect(() => {

    

    validateData();
    


    
    
  }, [billId, data]);


  




  const columns = useMemo(() => [
    {

      Header : "ID",
      accessor: "id"
    },  

    {

      Header : "Bill NO",
      accessor: "billNo"
    },  

    {

      Header : "AMOUNT",
      accessor: "totalPrice"
    },

    {

      Header : "DATE",
      accessor: "billDate",
      Cell: ({ value }) => {
        const formattedTimestamp = new Date(value.seconds * 1000).toLocaleDateString();
        return <span>{formattedTimestamp}</span>;
      },
    }
  ],[]);

  const renderImageCell = (cellProps) => {
    const value = cellProps.value;


    return <div className='w-[100px] md:w-full flex flex-col items-center justify-center'><img className='w-30 h-20' src={value} alt="Image" /></div>;
  };


  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});


  const animatedComponents = makeAnimated();

  const handleSelect =(selectedItems) => {

   
    const cat = [];
    const catVal = [];




    for (let i = 0; i < selectedItems.length; i++){

      cat.push(selectedItems[i].value)
      catVal.push({value :  selectedItems[i].value, label: selectedItems[i].value.toString().toUpperCase()})
    }

    setCategory(cat);

    setDefaultCat(catVal);

    
};


  const user =  fetchUser();
 

  const userRole = fetchRole();

  useEffect(() => {

    const cat = [];
    foodCategories && foodCategories.map(item => (

      
   
        cat.push({ value: item.shortCode, label: item.title })

      
      ));

      
      setOptions(cat);
 


  }, [foodCategories])
  
 

  const uploadImage = async (e) => {
    
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const resizedFile = await window.resizeImage(imageFile,"WEBP","file",undefined,undefined,10);

    const storageRef = ref(storage,`Images/Bills/${Date.now()}-${uniqueBillId}-${resizedFile .name}`);
    const uploadTask = uploadBytesResumable(storageRef, resizedFile);

    uploadTask.on('state_changed', (snapshot) => {

      const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


    }, (error) => {
      
      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);


    }, () => {

      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL =>{

        setImageAsset(downloadURL);
        setIsLoading(false);
        setFields(true);
        setMsg("Image Uploaded Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      });

    });


  };
  const deleteImage = () => {

    setIsLoading(true);
    const deleteRef = ref(storage, imageAsset);
    deleteObject(deleteRef).then(() => {

      setImageAsset(null);
      setIsLoading(false);
      setFields(true);
        setMsg("Image Deleted Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      
    });
  };

  const deleteCurrentItem = () => {


    deleteItem(billId);
    deleteImage();

    window.createNotification("success","Item has been deleted!","",3000);

    history(-1);


    
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevents the default Enter key 
      
     

      if (supplier.trim() !== '' && supplier[supplier.length - 1] !== ' ' && supplier[supplier.length - 1] !== '' && supplier[supplier.length - 1] !== ',') {
        setSupplier(supplier + ', ');
      }else{

        window.createNotification('warning', "Value should be added after a comma!", "", 2000);
      }
    }
  };

  const saveDetails = (mode = 'save') => {


  

   
    setIsLoading(true);



    try{



  

      if((!billNo || !supplier || !billDate || supplier.length <= 0 || !totalPrice || !imageAsset || !items || !category || items.length <= 0 )){

        setFields(true);
        setMsg("All Requeired Fields can't be empty!");
        setAlertStatus('danger');
        //clearData();
  
        setTimeout(() => {
  
          setFields(false);
          setIsLoading(false);
          
        }, 4000);

        fetchData();
  
      }else{

        let id = uniqueBillId;
        
        if(mode === 'update'){

          id = billId;


        }
        

        const data = {
          id: id,
          billNo: billNo,
          billImageURL: imageAsset,
          description : description,
          totalPrice: totalPrice,
          supplier: supplier.split(','),
          items: items,
          billDate: billDate,
          timestamp:  `${Date.now()}`,
          authBy: `${admin.admName}-${admin.admId}`
        }
        saveBill(id,data);

      
        setIsLoading(false);
        setFields(true);
          setMsg("Bill has been saved Successfully!");
          window.createNotification("success","Bill has been saved!","",2000);
          if(mode === 'update'){
            clearData(mode);
          }else{
            clearData();
          }
          setAlertStatus("success");
  
          setTimeout(() => {
            
            setFields(false);
        
          }, 2000);

      }


    }catch (error){

      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);

    }
  };

  const clearData = (mode = 'save') => {

 if(mode === 'update'){




 }else{


  setUniqueBillId(`${Date.now()}`);
  setTotalPrice("");
  setSupplier("");
  setBillNo("");
  setImageAsset(null);
  setCalories("");
  setPrice("");
  setDescription("");
  setCategory([]);
  setItemName("");
  setItemPrice("");
  setItemQty("");
  setItems([]);
 }
   
  };

  
  const fetchData = async () => {

    await getAllBills().then(data => {

      
      dispatch({
        type: actionType.SET_SUPPLIER_BILLS,
        supplierBills : data
      });

     
    });
  };



  return ( 

    user && userRole && (userRole === "admin") ? (

      

      pass === "new" ? (

        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/manageBills"}>Manage Bills</Link><MdKeyboardArrowRight />New Bill</div>
    <div className="w-full  flex  justify-center mt-6">
      
      <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col  gap-4 bg-white">

       

          {
            fields && (

              <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                {msg}
              </motion.p>
            )
          }

        <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdNumbers className="text-xl text-gray-700" />
            <input readOnly type="text" onChange={(e) => null} required value={uniqueBillId} placeholder="Bill Number" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>


          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <AiOutlineNumber className="text-xl text-gray-700" />
            <input type="text" onChange={(e) => setBillNo(e.target.value)} required value={billNo} placeholder="Bill Number" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <AiTwotoneContainer className="text-xl text-gray-700" />
            <input type="text"  onKeyDown={handleKeyPress} onChange={(e) => setSupplier(e.target.value)} required value={supplier} placeholder="Supplier Name(s)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3 border-2 rounded-lg p-2 bg-gray-100">
            
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <MdOutlineAddBox className="text-xl text-gray-700" />
            <input type="text"  onChange={(e) => setItemName(e.target.value)} required value={itemName} placeholder="Item Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <MdPriceChange className="text-xl text-gray-700" />
            <input type="text"  onChange={(e) => setItemPrice(e.target.value)} required value={itemPrice} placeholder="Price (LKR)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <GiJigsawPiece className="text-xl text-gray-700" />
            <input type="text"  onChange={(e) => setItemPieces(e.target.value)} required value={itemPieces} placeholder="Piece(s)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <MdProductionQuantityLimits className="text-xl text-gray-700" />
            <input type="text"   onChange={(e) => setItemQty(e.target.value)} required value={itemQty} placeholder="Quantity" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <GiWeight className="text-xl text-gray-700" />
            <input type="text"   onChange={(e) => setItemUnit(e.target.value)} required value={itemUnit} placeholder="Unit" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>


      <button className=' text-xl hidden md:block items-center bg-emerald-500 p-2 rounded-lg text-white ' onClick={addItem}><AiOutlineAppstoreAdd /></button>
      <button className=' text-xl md:hidden flex flex-row gap-2 items-center mt-4 bg-emerald-500 p-2 rounded-lg text-white hover:bg-emerald-400' onClick={addItem}>Add<AiOutlineAppstoreAdd /></button>

    </div>

    <div className='flex flex-col w-full py-2 border-b border-gray-300 items-center gap-2'>

{items.map((item) => (
    
   

      <div className='w-full flex flex-1 flex-col justify-between items-center rounded-md  gap-3 sm:flex-row drop-shadow-md bg-gray-200 hover:bg-gray-300 p-2' key={item?.id}>

<div className='flex flex-col justify-start w-full sm:text-left'><span className='text-base   sm:text-lg md:text-xl'>{item?.name}</span><span className='font-semibold text-red-500'>{item?.id}</span></div>

<div className='flex flex-col justify-start w-full sm:text-left'><span className='font-semibold text-red-500'>Qty: {item?.qty} {item?.unit}</span><span className='font-semibold text-red-500'>Pcs: {item?.pieces}</span></div>

<div className='flex flex-col justify-end w-full gap-2 sm:text-right'><span className='text-base sm:text-xl font-semibold'>{parseFloat(parseFloat(item?.price)*item?.qty).toFixed(2)} LKR </span><span className='font-semibold text-sm text-red-500'>Unit: {parseFloat(item?.price).toFixed(2)} LKR </span><span className='w-full justify-center sm:justify-end flex text-lg font-semibold'>   <span><button className=' text-xl text-red-600 font-semibold hover:text-red-800 w-max justify-center' onClick={() => removeItem(item.id)}><AiOutlineDelete /></button></span></span></div>


</div>
   
  ))}
</div>

       

          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 md:h-420 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!imageAsset ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Click Here to Upload</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*" onChange={uploadImage} className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={imageAsset} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={deleteImage}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>

                    
                    
                  </div>


                  <div className="w-full flex flex-col md:flex-row items-center gap-3">
          

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <Currency code="LKR (රු.)" size="lae" className="text-gray-700 text-2xl" />
                      <input type="text" required value={totalPrice} onChange={(e) => null} placeholder="Total" className="w-full h-full text-2xl bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
                  </div>

                  <div className="w-full flex flex-col md:flex-row items-center gap-3">
          
                  <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2 select-none">
          <MdDateRange className="text-gray-700 text-2xl" />
          <DatePicker selected={billDate} onChange={(date) => setBillDate(date)} />
          </div>


          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2 select-none">
          <MdAdminPanelSettings className="text-gray-700 text-2xl" />
            <input type="text" required value={admin.admName + "-" + admin.admId} onChange={(e) => null} placeholder="Authority" className="w-full h-full text-xl select-none bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>
          
        </div>


                  <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={saveDetails}>Save <MdSave /></button>
                  </div>
      </div>
    </div></div>):  pass === "solo" ? (
    
      <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/manageBills"}>Manage Bills</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/manageBills/manage"}>Manage All Bills</Link><MdKeyboardArrowRight />{billId}</div>
    <div className="w-full  flex  justify-center mt-6">
      
      <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col  gap-4 bg-white">

       

          {
            fields && (

              <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                {msg}
              </motion.p>
            )
          }

        <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdNumbers className="text-xl text-gray-700" />
            <input readOnly type="text" onChange={(e) => null} required value={billId} placeholder="Bill Number" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>


          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <AiOutlineNumber className="text-xl text-gray-700" />
            <input type="text" onChange={(e) => setBillNo(e.target.value)} required value={billNo} placeholder="Bill Number" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <AiTwotoneContainer className="text-xl text-gray-700" />
            <input type="text"  onKeyDown={handleKeyPress} onChange={(e) => setSupplier(e.target.value)} required value={supplier} placeholder="Supplier Name(s)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3 border-2 rounded-lg p-2 bg-gray-100">
            
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <MdOutlineAddBox className="text-xl text-gray-700" />
            <input type="text"  onChange={(e) => setItemName(e.target.value)} required value={itemName} placeholder="Item Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <MdPriceChange className="text-xl text-gray-700" />
            <input type="text"  onChange={(e) => setItemPrice(e.target.value)} required value={itemPrice} placeholder="Price (LKR)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <GiJigsawPiece className="text-xl text-gray-700" />
            <input type="text"  onChange={(e) => setItemPieces(e.target.value)} required value={itemPieces} placeholder="Piece(s)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <MdProductionQuantityLimits className="text-xl text-gray-700" />
            <input type="text"   onChange={(e) => setItemQty(e.target.value)} required value={itemQty} placeholder="Quantity" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
          <GiWeight className="text-xl text-gray-700" />
            <input type="text"   onChange={(e) => setItemUnit(e.target.value)} required value={itemUnit} placeholder="Unit" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>


      <button className=' text-xl hidden md:block items-center bg-emerald-500 p-2 rounded-lg text-white ' onClick={addItem}><AiOutlineAppstoreAdd /></button>
      <button className=' text-xl md:hidden flex flex-row gap-2 items-center mt-4 bg-emerald-500 p-2 rounded-lg text-white hover:bg-emerald-400' onClick={addItem}>Add<AiOutlineAppstoreAdd /></button>

    </div>

    <div className='flex flex-col w-full py-2 border-b border-gray-300 items-center gap-2'>

{items.map((item) => (
    
   

      <div className='w-full flex flex-1 flex-col justify-between items-center rounded-md  gap-3 sm:flex-row drop-shadow-md bg-gray-200 hover:bg-gray-300 p-2' key={item?.id}>

<div className='flex flex-col justify-start w-full sm:text-left'><span className='text-base   sm:text-lg md:text-xl'>{item?.name}</span><span className='font-semibold text-red-500'>{item?.id}</span></div>

<div className='flex flex-col justify-start w-full sm:text-left'><span className='font-semibold text-red-500'>Qty: {item?.qty} {item?.unit}</span><span className='font-semibold text-red-500'>Pcs: {item?.pieces}</span></div>

<div className='flex flex-col justify-end w-full gap-2 sm:text-right'><span className='text-base sm:text-xl font-semibold'>{parseFloat(parseFloat(item?.price)*item?.qty).toFixed(2)} LKR </span><span className='font-semibold text-sm text-red-500'>Unit: {parseFloat(item?.price).toFixed(2)} LKR </span><span className='w-full justify-center sm:justify-end flex text-lg font-semibold'>   <span><button className=' text-xl text-red-600 font-semibold hover:text-red-800 w-max justify-center' onClick={() => removeItem(item.id)}><AiOutlineDelete /></button></span></span></div>


</div>
   
  ))}
</div>

       

          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 md:h-420 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!imageAsset ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Click Here to Upload</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*" onChange={uploadImage} className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={imageAsset} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={deleteImage}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>

                    
                    
                  </div>


                  <div className="w-full flex flex-col md:flex-row items-center gap-3">
          

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <Currency code="LKR (රු.)" size="lae" className="text-gray-700 text-2xl" />
                      <input type="text" required value={totalPrice} onChange={(e) => null} placeholder="Total" className="w-full h-full text-2xl bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
                  </div>

                  <div className="w-full flex flex-col md:flex-row items-center gap-3">
          
                  <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2 select-none">
          <MdDateRange className="text-gray-700 text-2xl" />
          <DatePicker selected={billDate} onChange={(date) => setBillDate(date)} />
          </div>


          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2 select-none">
          <MdAdminPanelSettings className="text-gray-700 text-2xl" />
            <input type="text" required value={admin.admName + "-" + admin.admId} onChange={(e) => null} placeholder="Authority" className="w-full h-full text-xl select-none bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>
          
        </div>


                  <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => saveDetails('update')}>Update <MdSave /></button>
                  </div>
      </div>
    </div></div>) :  pass === "manage" ? (

      /* Manage Items */

      <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
    <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/manageBills"}>Manage Bills</Link><MdKeyboardArrowRight />Manage All Bills</div>
      <div className="w-full flex flex-auto justify-center items-center mt-6 overflow-auto">


<div className='w-full  flex overflow-auto scrollbar-thin scrollbar-thumb-slate-400 bg-slate-200 shadow-lg rounded-lg'>

            {
              supplierBills ? (
                <table className='w-full' {...getTableProps()}>

              <thead>

              {headerGroups.map((headerGroup) => (

                  <tr className='bg-white hover:bg-slate-50 font-semibold text-xl' {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map((column) => (

                      <th className='py-4' {...column.getHeaderProps()}>

                        {column.render("Header")}

                      </th>
                    ))}

                  </tr>

              ))}

              </thead>

              <tbody className='hover:bg-slate-300' {...getTableBodyProps()}>

                      {
                        rows.map((row) => {

                          prepareRow(row)

                          return (


                            <tr className='text-lg hover:bg-slate-400 border-t-2 border-white hover:text-slate-50 hover:font-semibold cursor-pointer' {...row.getRowProps()} onClick={(e) => {history(row.cells[0].value)}}>

                              {row.cells.map((cell) => (
                              
                                <td className='py-4 px-3' {...cell.getCellProps()}>
                                 {cell.column.id === 'image' ? (
                                    renderImageCell(cell)
                                  ) : (
                                    cell.render('Cell')
                                  )}  
                                 
                                </td>
                               
                              ))}
 
                            </tr>
                          )

                        })
                      }

              </tbody>


            </table>
              ) : (<LoaderCustom />)
            }

          </div>



            </div>

    </div>
    
    
    
    ):(
      <div className='text-center py-16 w-full flex flex-col gap-8 justify-center items-center m-auto select-none'> 

      <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight />Manage Bills</div>
      
      <Link to={"../admin/manageStock/manageBills/new"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-emerald-500 p-3 rounded-lg hover:bg-emerald-400 gap-2 w-full'>New Bill <MdAddBox /></button>
      </Link> 

      <Link to={"../admin/manageStock/manageBills/manage"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-blue-500 p-3 rounded-lg hover:bg-blue-400 gap-2 w-full'>Manage Bills <MdOutlineDocumentScanner /></button>
      </Link>
      </div>
    )
  ) : (

    
    	
<Navigate to="/" />
  )

  )
}

export default ManageItem ;
  