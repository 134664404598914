import React, { useEffect, useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Header, MainContainer,ManageItem, ManageSMS, Item, ManageCategory, ManageUsers, Admin, QRScanner ,Map, ManageBranch, MyAccount, BarCodeScan, LogOut, ManageStock, E404, MainStock, About, Coupen, CheckOut, ManageDiscounts, OrderVerify, Test, SendNotification, MyOrders, ManageBills} from './components';
import { useStateValue } from './context/StateProvider';
import { getAllFoodItems, getAllFoodCategories, getAllBranches} from './utils/firebaseFunctions';
import { actionType } from './context/reducer';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import addNotification from 'react-push-notification';
import Delivery from './img/delivery.png';
import 'react-notifications/lib/notifications.css';
import io from 'socket.io-client';




import Resizer from "react-image-file-resizer";
import runOneSignal from './utils/runOneSignal';
import { getMessage, getNotify } from './utils/socketFunc';





document.addEventListener('dragstart', function(event) {
  event.preventDefault();
});


const App = () => {

  const [{}, dispatch] = useStateValue();
 


  
window.socket = io.connect("https://pos.viphotels.lk/"); 
//window.socket = io.connect("http://localhost:3001");

const runSocket = () => {

  console.log("Running Socket...");

  window.socket.on("recieve_message", (data) => {
 
      getMessage(data);
      
  
    });
    
    window.socket.on("recieve_notif", (data) => {
       
     getNotify(data);

       
      
      
      
    
    
    });

    return 0;
    

};


useEffect(() => {
  
const x = runSocket();

  
});




  const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register("/sw.js", {
          scope: "/",
        });
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }
  };
  
  // …
  
  const sw =  registerServiceWorker();


 

  const [initialized, setInitialized] = useState(false);




  useEffect (() => {
    
   
  if(runOneSignal()){

    setInitialized(true);
  }else{

    setInitialized(false);
  }
    
   
  }, []);
  

  

/*   async function requestPermission(){

    const permission = await Notification.requestPermission();

    if(permission === 'granted'){

      window.token = await getToken(messaging, { vapidKey: 'BJe4Ympwo57vQ7VXOv9YWHpDFpWj7DIXOZZsAnQ1sWZ35FeAZViN5lhxsab7WGhWL9E3nxxiAK0xa-wyCpl0V5Y'});

      
 

    }else if(permission === 'denied'){

      window.createNotification("error","Fail to Notify","You are not enabled notifications for this app!",5000);
    }
  }


  useEffect(() => {

    requestPermission();
    
  }, []) */
  


 


  window.pushNotification = (title = "VIP HOTELS LK", message = "Empty Request!", duration = 4000, logo) => {

  
 
      addNotification({

        title: title,
        message: message,
        duration: duration,
        logo: logo ? logo : Delivery,
        native: true
  
      });
   


  };

  const createNotification = (type,title = "VIP HOTELS LK",message = "Ignore this message!", seconds = 3000) => {
     
    switch (type) {
      case 'info':
        NotificationManager.info(message, title, seconds, () => {
         
        });
        break;
      case 'success':
        NotificationManager.success(message, title, seconds, () => {
         
        });
        break;
      case 'warning':
        NotificationManager.warning(message, title, seconds, () => {
         
        });
        break;
      case 'error':
       
        NotificationManager.error(message, title, seconds, () => {
         
        });
        break;
    }
  
};


window.createNotification = createNotification;

window.check_provider = (user, type) => {



  for (let i = 0; i < user.providerData.length; i++){

    if(user.providerData[i].providerId === type){

     

          return true;
    

        break;
    }
   
  }

  return false;



};

window.resizeImage = (file, type = "WEBP", outType = "base64", maxWidth, maxHeight, quality = 100, rotation = 0, minWidth = 100, minHeight = 100) =>
  new Promise((resolve) => {

    try{

    Resizer.imageFileResizer(
      file,
      parseInt(maxWidth),
      parseInt(maxHeight),
      type,
      parseInt(quality),
      parseInt(rotation),
      (uri) => {
        resolve(uri);
      },
      outType,
      parseInt(minWidth),
      parseInt(minHeight)
    );

  }catch (error){


    console.log(error);

  }});



  

 
  const fetchData = async () => {

    await getAllFoodItems().then(data => {
      dispatch({
        type: actionType.SET_FOOD_ITEMS,
        foodItems : data
      });
    });

    await getAllFoodCategories().then(data => {
      dispatch({
        type: actionType.SET_FOOD_CATEGORIES,
        foodCategories : data
      });
    });

    await getAllBranches().then(data => {
      dispatch({
        type: actionType.SET_BRANCH,
        vipBranches : data
      });
    });
  
  };

  
  

  

useEffect(() =>{
  fetchData();

},[]);
  return (

    
    
   
    <AnimatePresence mode='wait'>
        <div className="w-screen h-auto flex flex-col bg-primary" onContextMenu={(e) => {e.preventDefault();}}>
          <Header />
          <main className="mt-14 md:mt-20 px-6 md:px-16 py-10 md:py-4 w-full">
         <div className='select-none'> <NotificationContainer/></div>

         
            <Routes>
              <Route path="/" element={<MainContainer />} />

              <Route path="/*" element={<E404 />} />

          

              <Route path="/Order/Verify/:orderId" element={<OrderVerify />} />

              <Route path="/Coupons" element={<Coupen />} />

              <Route path="/Test" element={<Test />} />


              <Route path="/MyAccount" element={<MyAccount />} />
              <Route path="/MyOrders/:orderId" element={<MyOrders />} />
              
              <Route path="/LogOut" element={<LogOut />} />

              <Route path="/admin/Notify" element={<SendNotification/>} />

              <Route path="/admin/ManageItem" element={<ManageItem />} />
              <Route path="/admin/ManageItem/manage" element={<ManageItem pass = {'manage'} />} />
              <Route path="/admin/ManageItem/manage/:itemId" element={<ManageItem pass = {'solo'} />} />
              <Route path="/admin/ManageItem/new" element={<ManageItem pass = {'new'}  />} />

              
              <Route path="/admin/ManageStock/ManageBills" element={<ManageBills/>} />
              <Route path="/admin/ManageStock/ManageBills/manage" element={<ManageBills pass = {'manage'} />} />
              <Route path="/admin/ManageStock/ManageBills/manage/:billId" element={<ManageBills pass = {'solo'} />} />
              <Route path="/admin/ManageStock/ManageBills/new" element={<ManageBills pass = {'new'}  />} />

              <Route path="/admin/ManageStock" element={<ManageStock />} />
              <Route path="/admin/ManageStock/MainStock" element={<MainStock />} />
              <Route path="/admin/ManageStock/MainStock/new" element={<MainStock  pass = {'new'} />} />
              <Route path="/admin/ManageStock/MainStock/manage" element={<MainStock  pass = {'manage'} />} />
              <Route path="/admin/ManageStock/MainStock/transfer" element={<MainStock  pass = {'transfer'} />} />
              
              <Route path="/admin/ManageCategory" element={<ManageCategory/>} />
              <Route path="/admin/ManageCategory/manage" element={<ManageCategory  pass = {'manage'} />} />
              <Route path="/admin/ManageCategory/manage/:catId" element={<ManageCategory pass = {'solo'} />} />
              <Route path="/admin/ManageCategory/new" element={<ManageCategory pass = {'new'} />} />

              <Route path="/admin/ManageBranch" element={<ManageBranch/>} />
              <Route path="/admin/ManageBranch/manage" element={<ManageBranch pass = {'manage'} />} />
              <Route path="/admin/ManageBranch/manage/:branchId" element={<ManageBranch pass = {'solo'} />} />
              <Route path="/admin/ManageBranch/new" element={<ManageBranch pass = {'new'} />} />

              <Route path="/admin/systemUsers" element={<ManageUsers />} />
              <Route path="/admin/systemUsers/manage" element={<ManageUsers pass = {'manage'} />} />
              <Route path="/admin/systemUsers/manage/:userId" element={<ManageUsers pass = {'solo'} />} />
              <Route path="/admin/systemUsers/new" element={<ManageUsers pass = {'new'} />} />

              <Route path="/admin/manageDiscounts" element={<ManageDiscounts/>} />

              <Route path="/admin/manageSMS" element={<ManageSMS />} />

              <Route path="/admin" element={<Admin />} />
              

              <Route path="/QRScanner" element={<QRScanner />} />
              <Route path="/BarCodeScanner" element={<BarCodeScan />} />
              <Route path="/Map" element={<Map />} />
              <Route path="/AboutUs" element={<About />} />
              <Route path="/item/:itemId" element={<Item />} />

              <Route path="/checkout/:amount/:hash" element={<CheckOut />} />
            </Routes>
       
          </main>
        </div>
    </AnimatePresence>
   
  
  )
}

export default App