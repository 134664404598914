import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getAuth, signInWithPopup, GoogleAuthProvider, RecaptchaVerifier, PhoneAuthProvider, signOut, setPersistence, browserLocalPersistence, signInWithPhoneNumber } from "firebase/auth";
import { app } from '../firebase.config';
import Logo from '../img/logo.webp';
import Avatar from '../img/avatar.png';
import AvatarKing from '../img/king.png';
import { MdShoppingBasket , MdLogout, MdAccountCircle, MdOutlineClose, MdOutlineAdminPanelSettings, MdPhone, MdSend, MdOutline123 } from "react-icons/md";
import {IoNotificationsSharp} from 'react-icons/io5';
import {HiBars3CenterLeft, HiBars3 } from "react-icons/hi2";
import {FcGoogle, FcPhoneAndroid} from 'react-icons/fc';
import {BiQrScan} from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { getUserRole, saveCustomer } from '../utils/firebaseFunctions';
import { fetchCustomer, fetchRole, } from '../utils/fetchLocalStorageData';
import CryptoJS from 'crypto-js';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import OTPInput, { ResendOTP } from "otp-input-react";
import CartContainer from './CartContainer';
import NotiContainer from './NotiContainer';
import NotificationSound from './NotificationSound';







export const Header = () => {

    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [msg, setMsg] = useState(null);
    

   const [loginOpen, setLoginOpen] = useState(false)
     
      const onOpenModal = () => {
        setLoginOpen(true);
      };
     
      const onCloseModal = () => {
        setLoginOpen(false);
      };
    
    const firebaseAuth = getAuth(app);
    const provider = new GoogleAuthProvider();

  

    const [{user, cartShow, notiShow, cartItems, notifications}, dispatch] = useStateValue();


 


    const [ isMenu, setisMenu] = useState(false);

    const [ isExpand_menu, setisExpand_menu] = useState(false);
    const [expandForm, setExpandForm] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [otpCode, setOtpCode] = useState(null);
    const [otpTerm, setOtpTerm] = useState(0);


    const userRole = fetchRole();
    const customer = fetchCustomer();

    const [avatarSrc, setAvatarSrc] = useState(customer && customer.customerImage ? customer.customerImage : user && user.photoURL ? user.photoURL : user ? AvatarKing : Avatar);

    useEffect(() => {
      
     notiCount();
    },[]);
    

    useEffect(() => {
     setAvatarSrc(customer && customer.customerImage ? customer.customerImage : user && user.photoURL ? user.photoURL : user ? AvatarKing : Avatar);

    }, [user,customer]);

    const showCart = () => {

        dispatch({
            type: actionType.SET_CART_SHOW,
            cartShow: !cartShow
        });



    };

    const showNoti = () => {

        dispatch({
            type: actionType.SET_NOTI_SHOW,
            notiShow: !notiShow
        });



    };

    const notiCount = () => {


       
      
       
        if(localStorage.getItem("noti")){

            dispatch({
                type: actionType.SET_NOTIFY,
                notifications: localStorage.getItem("noti")
            });
        }

        return 0;
        
    }
    
    const expand_menu = (ex) => {


        setisExpand_menu(!isExpand_menu);
        ex.className = "hidden";
    };

    const generateRandomNumber = () => {
        const min = 111111;
        const max = 999999;
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        return randomNumber;
      };
    
    
      const loginPhoneModal = () =>{

        setisMenu(false);
        setLoginOpen(true);
        setExpandForm(false);
        setOtpTerm(3);
      };

      const generateRecaptcha = async() => {

        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //onSignInSubmit();
            }
          }, firebaseAuth);

      };



      const reqestOTP = async(e) => {

     

        if(phoneNumber.length >= 12){

            setExpandForm(true);

           await generateRecaptcha();

           let appVerifier = window.recaptchaVerifier;

           signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier).then(

            confirmationResult => {

                window.confirmationResult = confirmationResult;
                setOtpTerm(3);
                setExpandForm(true);

            }

           ).catch((error) => {

            window.createNotification('error','Code Not Sent!',"Due to a misleading operation, this process cannot be continued! Please reload the page and try again!", 6000);
            console.log("Not Sent! - " + error);
            setExpandForm(false);
           });


        }
      };

      const verifyOTP = async () => {
        if(otpCode.length === 6 && otpTerm != 0){

            let confirmationResult = window.confirmationResult;

            confirmationResult.confirm(otpCode).then((result) => {

                setPhoneNumber(null);
                setOtpCode(null);
                setLoginOpen(false);
                  
                window.createNotification('success','Successfully Logged!',"You have been successfully logged in to the system via Phone Number!");

                const user = result.user;
                dispatch({
                    type: actionType.SET_USER,
                    user: user
                });
               
                localStorage.setItem("ffkey", CryptoJS.MD5(generateRandomNumber()).toString());
                localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user),localStorage.getItem('ffkey')).toString());
             
                const customer_profile = {

                    cusId: user.uid,
                    cusName: user.displayName,
                    cusEmail: user.email,
                    cusPhone: user.phoneNumber,
                    cusDp: user.photoURL,



                }

                saveCustomer(customer_profile,user.email,user.phoneNumber,user.uid,false);
                getUserRole(user.email, user.phoneNumber);
                localStorage.setItem('ex_id',user.uid);

               

            }).catch((error) => {

                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
           
                // The AuthCredential type that was used.
                const credential = PhoneAuthProvider.credentialFromError(error);

           

                if(errorCode == 'auth/user-disabled'){

                   
                    window.createNotification('error','Disabled Account!',"The user account under the phone number, " + phoneNumber + " has been disabled!",5000);
                }else if(errorCode == "auth/invalid-verification-code"){

                    setOtpTerm(parseInt(otpTerm) - 1);
                    window.createNotification('error','Invalid OTP Code',"You only have "+otpTerm+" terms to try!",5000);
                    

                    console.log(errorCode);
                }
            })

            setPhoneNumber(null);
                setOtpCode(null);


        }else{

            setPhoneNumber(null);
                setOtpCode(null);

            console.log("Error OTP");
            
            window.createNotification('error','All Attempts are failed!',"You have exceeded the OTP quota! Please try again!",5000);
            setLoginOpen(false);
        }

      }

    const loginGoogle = async () => {


        setisMenu(false);

        if(!user){

            setPersistence(firebaseAuth, browserLocalPersistence)
            .then(async () => {
              // Existing and future Auth states are now persisted in the current
              // session only. Closing the window would clear any existing state even
              // if a user forgets to sign out.
              // ...
              // New sign-in will be persisted with session persistence.
              await signInWithPopup(firebaseAuth, provider).then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                dispatch({
                    type: actionType.SET_USER,
                    user: user
                });

                window.createNotification('success','Successfully Logged!',"You have been successfully logged in to the system via Google Account!");
                localStorage.setItem("ffkey", CryptoJS.MD5(generateRandomNumber()).toString());
                localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user),localStorage.getItem('ffkey')).toString());
                localStorage.setItem('ex_id',user.uid);
    


                const customer_profile = {

                    cusId: user.uid,
                    cusName: user.displayName,
                    cusEmail: user.email,
                    cusPhone: user.phoneNumber,
                    cusDp: user.photoURL,



                }


                saveCustomer(customer_profile,user.email,user.phoneNumber,user.uid,false);
                getUserRole(user.email, user.phoneNumber);

                customer = fetchCustomer();
                 
                // IdP data available using getAdditionalUserInfo(result)
                // ...
              }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);

             

                if(errorCode == 'auth/user-disabled'){

                
                    window.createNotification('error','Disabled Account!',"The user account under the email, " + email + " has been disabled!",5000);
                }
                // ...
              });

            
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
            });


           

    

          



        }else{//is a user

          
            setisMenu(!isMenu);

        }
    };

    const logout = () => {

        signOut(firebaseAuth).then(() => {
            // Sign-out successful.
          }).catch((error) => {
            // An error happened.
          });

        setisMenu(false);

        localStorage.clear();
        dispatch({
            type: actionType.SET_USER,
            user: null
        });
        dispatch({
            type: actionType.SET_CART_ITEMS,
            cartItems: []
        });
         
       
        //navigate(0);
    }


    const handleChange = otpCode => setOtpCode(otpCode);

    

  return (
<>

    <header className="fixed z-50 w-screen bg-cardOverlay md:drop-shadow-none lg:drop-shadow-none md:bg-slate-100 lg:bg-slate-100 backdrop-blur-lg p-3 px-4 md:p-6 md:px-16 select-none">

        {/* Desktops and Tablets */}

        <div className="hidden md:flex w-full h-full items-center justify-between">

            <Link to={"/"} className="flex gap-2 items-center">
                <motion.div className="flex gap-2 items-center" initial={{opacity:0, x:-200 }} animate={{opacity:1, x:0}} exit={{opacity:0, x:-200 }} >
                <img src={Logo} alt="VIPHOTELS" className="w-10 object-cover" />
                <p className="text-headingColor text-xl font-bold">VIP Hotels</p>
                <p className="text-headingColor text-xs font-bold">&</p>
                <p className="text-headingColor text-xl font-bold">Restaurants</p>
                </motion.div>
            </Link>

           <div className="flex items-center gap-8">

                <motion.ul initial={{opacity:0, x:200 }} animate={{opacity:1, x:0}} exit={{opacity:0, x:200 }} className="flex items-center gap-8">
                        <Link to={'../'}><li className="text-base text-textColor hover:text-headingColor duration-100 transition-all ease-in-out  cursor-pointer">Home</li></Link>
                        <Link to={'../aboutus'}><li className="text-base text-textColor hover:text-headingColor duration-100 transition-all ease-in-out  cursor-pointer">About Us</li></Link>
                        <Link to={'../map'}><li className="text-base text-textColor hover:text-headingColor duration-100 transition-all ease-in-out  cursor-pointer">Branches</li></Link>
                        <Link to={'../qrscanner'}><li className="text-base flex items-center gap-1 text-textColor hover:text-headingColor duration-100 transition-all ease-in-out  cursor-pointer" onClick={() => setisExpand_menu(false)}>Scan QR<BiQrScan /></li></Link>
                </motion.ul>

                    <motion.div className="relative flex items-center justify-center cursor-pointer" whileTap={{scale:0.6}}  whileHover={{scale:1.2}}  onClick={showCart}>
                        <motion.span > <MdShoppingBasket className="text-textColor text-2xl hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer"/></motion.span>
                       
                        {customer && cartItems && cartItems.length > 0 && (
                            <div className="absolute -top-3 -right-2.5 w-5 h-5 flex items-center justify-center rounded-full bg-cartNumBg"><p className="text-xs text-white font-semibold">{cartItems.length}</p></div>
                        )}
                    </motion.div>

                    <motion.div className="relative flex items-center justify-center cursor-pointer" whileTap={{scale:0.6}}  whileHover={{scale:1.2}}  onClick={showNoti}>
                        <motion.span > <IoNotificationsSharp className="text-textColor text-2xl hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer"/></motion.span>
                       
                        {userRole === 'admin' && notifications && notifications.length > 0 && (
                            <div className="absolute -top-3 -right-2.5 w-5 h-5 flex items-center justify-center rounded-full bg-cartNumBg"><p className="text-xs text-white font-semibold">{notifications.length}</p></div>
                        )}
                    </motion.div>


                    <div className="relative"> 
                        <motion.img  whileTap={{scale:0.6}} whileHover={{scale:1.2}}   
                        src={avatarSrc} onError={(e) => {setAvatarSrc(AvatarKing)}}  className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl object-fill  rounded-full cursor-pointer" alt="User Profile" onClick={() => setisMenu(!isMenu)} />

                        {

                            isMenu && (
                                <div className="w-[170px] bg-gray-50 shadow-xl rounded-lg flex flex-col absolute top-12 right-0">

                               {customer && ( <Link to={'/myAccount'}>
                                    <p className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base" onClick={() => setisMenu(false)}><MdAccountCircle /> My Account</p>
                            
                                </Link> )}
                           {
                            user && userRole && (userRole === "admin") ? ( 

                                <div>


                                <Link to={'admin'}>
                                    <p className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base"  onClick={() => setisMenu(false)}><MdOutlineAdminPanelSettings /> Admin Panel</p>
                                    
                                </Link> 
                        

                                <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-red-600 hover:text-white shadow-md"  onClick={logout}>Logout <MdLogout /></p>

                             
                                </div>
                               

            
                            ) : user ? (
                            
                                //Customer
                            <div>
                            
                            
                               
                                <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-red-600 hover:text-white shadow-md" onClick={logout}>Logout <MdLogout /></p>
                            
                            
                            </div>) : (//Not Logged
                            
                            <div>
                            
                            <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-yellow-800 hover:text-white " onClick={loginGoogle}>Login with <FcGoogle /></p>

                            <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-yellow-800 hover:text-white" onClick={loginPhoneModal}>Login with <FcPhoneAndroid /></p>
                            
                            </div>)
                           }
                           
                           
                        </div>
                            )
                        }

                    </div>

           </div>
        </div>

        {/* Mobiles */}

        <div className="flex md:hidden items-center justify-between w-full h-full">
        
        { !isExpand_menu && (
        <Link className="text-2xl hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor" onClick={expand_menu}><HiBars3CenterLeft /></Link>
        )
        }

    { isExpand_menu && (
        <Link className="text-2xl hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor" onClick={expand_menu}><HiBars3 /></Link>
        )
        }

        { isExpand_menu && (

        <div className="w-40 bg-gray-50 shadow-xl rounded-lg flex flex-col absolute top-16 left-1">
                        

                         <ul className="flex flex-col">
                            <Link to={'../'}><li className="cursor-pointer hover:bg-slate-100 hover:ml-2 transition-all duration-100 ease-in-out text-textColor text-base px-4 py-2 flex items-center gap-3 " onClick={()  => setisExpand_menu(false)}>Home</li></Link>
                            <Link to={'../aboutus'}><li className="cursor-pointer hover:bg-slate-100 hover:ml-2 transition-all duration-100 ease-in-out text-textColor text-base px-4 py-2 flex items-center gap-3 " onClick={() => setisExpand_menu(false)}>About Us</li></Link>
                            <Link to={'../map'}><li className="cursor-pointer hover:bg-slate-100 hover:ml-2 transition-all duration-100 ease-in-out text-textColor text-base px-4 py-2 flex items-center gap-3 " onClick={() => setisExpand_menu(false)}>Branches</li></Link>
                            <Link to={'../qrscanner'}><li className="cursor-pointer hover:bg-slate-100 hover:ml-2 transition-all duration-100 ease-in-out text-textColor text-base px-4 py-2 flex items-center gap-3 " onClick={() => setisExpand_menu(false)}>Scan QR <BiQrScan /></li></Link>
                        </ul>


                </div>

        )

        }

        <motion.div className="absolute ml-10 flex items-center justify-center cursor-pointer" whileTap={{scale:0.6}}  whileHover={{scale:1.2}} onClick={showCart}>
                 <motion.span > <MdShoppingBasket className="text-textColor text-2xl hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer"/></motion.span>
                       
                 {customer && cartItems && cartItems.length > 0 && (
                 <div className="absolute -top-3 -right-2.5 w-5 h-5 flex items-center justify-center rounded-full bg-cartNumBg"><p className="text-xs text-white font-semibold">{cartItems.length}</p></div>)}
        </motion.div>

        
        <motion.div className="absolute ml-20 flex items-center justify-center cursor-pointer" whileTap={{scale:0.6}}  whileHover={{scale:1.2}} onClick={showNoti}>
                 <motion.span > <IoNotificationsSharp className="text-textColor text-2xl hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer"/></motion.span>
                       
                 {userRole === 'admin' && notifications && notifications.length > 0 &&  (
                 <div className="absolute -top-3 -right-2.5 w-5 h-5 flex items-center justify-center rounded-full bg-cartNumBg"><p className="text-xs text-white font-semibold">{notifications.length}</p></div>)}
        </motion.div>

            
            <Link to={"/"} className="block text-center justify-center gap-2 items-center">
                <motion.div className="gap-2 items-center block" initial={{opacity:0, y:-200 }} animate={{opacity:1, y:0}} exit={{opacity:0, y:-200 }} >
                <img src={Logo} alt="VIPHOTELS" className="w-10 ml-16 object-cover items-center justify-center" />
           
               
           
                </motion.div>
                
                <p className="text-headingColor hidden sm:block text-sm font-bold ">VIP Hotels & Restaurants</p>
            </Link>


            <div className="relative "> 
                        <motion.img  whileTap={{scale:0.6}} whileHover={{scale:1.2}}   
                        src={avatarSrc} onError={(e) => {setAvatarSrc(AvatarKing)}}  className="w-10 min-w-[40px] h-10 min-h-[40px]  drop-shadow-xl object-fill rounded-full cursor-pointer"alt="User Profile"  onClick={() => setisMenu(!isMenu)}/>

                        {

                            isMenu && (
                                <div className="w-[170px] bg-gray-50 shadow-xl rounded-lg flex flex-col absolute top-12 right-0">

                              {customer && (  <Link to={'/myAccount'}>
                                    <p className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base" onClick={() => setisMenu(false)}><MdAccountCircle /> My Account</p>
                            
                                </Link> )}
                           {
                           user && userRole && (userRole === "admin") ? (

                                <div>

                            
                                          
                                <Link to={'admin'}>
                                    <p className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base"  onClick={() => setisMenu(false)}><MdOutlineAdminPanelSettings /> Admin Panel</p>
                                    
                                </Link> 
                               

                                <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-red-600 hover:text-white shadow-md"  onClick={logout}>Logout <MdLogout /></p>

                                </div>
                              
                                
                            ): user ? (
                            
                                //Customer
                            <div>
                            
                            
                            
                                <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-red-600 hover:text-white shadow-md"  onClick={logout}>Logout <MdLogout /></p>
                            
                            
                            </div>): (//Not Logged
                            
                            <div>
                            
                            <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-yellow-800 hover:text-white " onClick={loginGoogle}>Login with <FcGoogle /></p>

                            <p className="m-2 p-2 rounded-md flex items-center gap-3 cursor-pointer transition-all duration-100 ease-in-out text-textColor text-base bg-gray-100 justify-center hover:bg-yellow-800 hover:text-white" onClick={loginPhoneModal}>Login with <FcPhoneAndroid /></p>
                            
                            </div>)
                           }


                           
                        </div>
                            )
                        }

                    </div>



        </div>

     

        

    </header>

    
    { 
           
           cartShow && ( <CartContainer />)

           }

{ 
           
           notiShow && ( <NotiContainer />)

           }

           {

localStorage.getItem("noti") && localStorage.getItem("noti").length > 0 && (<NotificationSound />)
           }




{loginOpen && !user && (   //Login with Phone




        <div className=' w-full h-full bg-white opacity-100 text-center p-4 fixed bottom-0 z-10 top-20 scrollbar-thin overscroll-auto overflow-auto'>
        
       
       
          <div className='w-full min-h-screen h-auto flex items-center justify-center bg-slate-100 opacity-100 rounded-md border-2 border-slate-600 p-3'><span className='absolute flex top-6 text-base md:text-2xl font-semibold items-center gap-2'>Login with Phone <FcPhoneAndroid /></span><span className='absolute flex right-6 top-6 text-xl md:text-3xl cursor-pointer hover:text-red-500' onClick={() => setLoginOpen(false)}><MdOutlineClose /></span>


           <div className=''>

            {   fields && (
  
  <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
    {msg}
  </motion.p>
)}

      {!expandForm ? (     
      
      
            <div className="w-full py-2 border-b border-gray-300 flex flex-col sm:flex-row items-center gap-5">
                        <MdPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent text-center outline-none border-none placeholder:text-gray-400 text-textColor${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Phone Number (eg: 0756101993)' value={phoneNumber} maxLength={12} 
                        rules={{ required: true }}
                            country="LK"
                            defaultCountry='LK'
                            international
                          
                        onChange={setPhoneNumber} />
                        <button className='rounded-full outline-none bg-emerald-500 text-white text-md p-3 hover:bg-emerald-600 hover:text-lg hover:drop-shadow-lg' onClick={(e) => {reqestOTP(e)}}><MdSend /></button>
            </div>): (<div className="w-full py-2 border-b border-gray-300 flex flex-col md:flex-row items-center gap-5 overflow-auto">
            
            <MdOutline123 className="text-gray-700 text-2xl" />
       

            <OTPInput value={otpCode ? otpCode : ""} placeholder="OTPKEY" onChange={setOtpCode} autoFocus OTPLength={6} otpType="number" disabled={false} secure inputClassName="border-2 border-gray-600 rounded-md p-2 text-base text-center" className="overflow-auto flex flex-row w-fit" />
      
            
           
            <button className='rounded-full outline-none bg-emerald-500 text-white text-md p-3 hover:bg-emerald-600 hover:text-lg hover:drop-shadow-lg' onClick={(e) => {verifyOTP(e)}}><MdSend /></button>
            
            </div>)}

            </div>

            <div id="recaptcha-container"></div>
            
            </div>
    
        </div>
      
    
    )}

 

    </>

    
  )
}

export default Header;