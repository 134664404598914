import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link, Navigate, useNavigate, useParams} from "react-router-dom";
import { useStateValue } from '../context/StateProvider';
import { motion } from 'framer-motion';
import {MdOutlineFormatAlignJustify, MdCode, MdOutlineDeleteOutline, MdSave, MdKeyboardArrowRight, MdOutlineStoreMallDirectory, MdLocationCity, MdOutlineLocationOn, MdOutlineMap, MdPhone} from 'react-icons/md';
import {TbWorldLatitude,TbWorldLongitude} from 'react-icons/tb';
import {GiPathDistance} from 'react-icons/gi';
import Currency from 'react-currency-icons';

import { deleteBranch, getAllBranches, saveBranch} from '../utils/firebaseFunctions';
import { actionType } from '../context/reducer';
import Loader from './Loader';
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import PhoneInput ,{isValidPhoneNumber} from 'react-phone-number-input/input';
import { useTable } from 'react-table';
import LoaderCustom from './LoaderCustom';

const ManageBranch = ({pass = undefined}) => {

  const {branchId} = useParams();
  const history = useNavigate();
  const currentURL = window.location.href;

    const [title, setTitle] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [address, setAddress] = useState("");
    const [shortCode, setShortCode] = useState("");
    const [description, setDescription] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [kmCharge, setKmCharge] = useState("");
    const [maxKm, setMaxKm] = useState("");
    
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [{vipBranches}, dispatch] = useStateValue();
    
  const userRole = fetchRole();
  
    const user =  fetchUser();

    const data = vipBranches ? vipBranches : [{}];


    const toCamelCase = (str) => {
        
      return str
        .split(' ')
        .map((word, index) => {
          if (index === -1) {
            return word.toLowerCase();
          } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        })
        .join(' ');
    };

    useEffect(() => {


      if(pass === 'new'){
  
        clearData();
      }
  
      if(pass === 'manage'){
  
      
        fetchData();
       
      }

      if(pass === 'solo' && branchId && vipBranches){

        const specItem = vipBranches.find(item => item.id === branchId);

       setTitle(specItem.location)
       setShortCode(specItem.shortCode);
        setDescription(specItem.description);
        setKmCharge(specItem.kmCharge);
        setMaxKm(specItem.maxKm);
        setLat(specItem.lat);
        setLng(specItem.lng);
        setAddress(specItem.address);
        setPhoneNumber(specItem.phoneNumber);
//
        
      }
  



     
    }, [currentURL]);  

    



    const columns = useMemo(() => [
      {
  
        Header : "ID",
        accessor: "id"
      },  
  
      {
  
        Header : "NAME",
        accessor: "location"
      },  
  
      {
  
        Header : "ADDRESS",
  
        accessor: "address"
      }
    ],[]);

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});


    const saveDetails = async (mode = 'save') => {

        setIsLoading(true);
    
        try{

         
    
          if(!title || !shortCode || !lat || !lng || !phoneNumber || !address || !kmCharge || !maxKm){

            
           
            setFields(true);
            setMsg("All Requeired Fields can't be empty!");
            setAlertStatus('danger');
            //clearData();
      
            setTimeout(() => {
      
              setFields(false);
              setIsLoading(false);
              
            }, 4000);
    
        
      
          }else{

          
            let id = `${Date.now()}`;
        
            if(mode === 'update'){
    
              id = branchId;
    
    
            }

            const data = {
              id: id,
              location: title,
              shortCode: shortCode,
              description : description,
              lat: lat,
              lng: lng,
              address: address,
              phoneNumber: phoneNumber,
              kmCharge: kmCharge,
              maxKm: maxKm
            
            }
     
    
            if((await saveBranch(id,data,lat,lng,title, shortCode, mode)) == false){

              setIsLoading(false);
              setFields(true);
                setMsg("This branch is already registered in the system!");
                //clearData();
                setAlertStatus("danger");
        
                setTimeout(() => {
                  
                  setFields(false);
              
                }, 2000);
    
            }else{
    
          
            setIsLoading(false);
            setFields(true);
              setMsg("Branch has been saved Successfully!");
              if(mode === 'update'){
                clearData(mode);
              }else{
                clearData();
              }
              setAlertStatus("success");
      
              setTimeout(() => {
                
                setFields(false);
            
              }, 2000);
    
            }
          }
    
          
        


           

   
    
      
    
          
    
    
        }catch (error){
    
          console.log(error);
          setFields(true);
          setMsg("Error while uploading : Try Again!");
          setAlertStatus('danger');
    
          setTimeout(() => {
    
            setFields(false);
            setIsLoading(false);
            
          }, 4000);
    
        }
      };
      const deleteData = () => {


        deleteBranch(branchId);
      
    
        window.createNotification("success","Branch has been deleted!","",3000);
    
        history(-1);
    
    
        
      }
      const clearData = (mode) => {

        
        if(mode === 'update'){




        }else{
       
    
        setTitle("");
        setShortCode("");
        setLat("");
        setLng("");
        setPhoneNumber("");
        setAddress("");
        setDescription("");
        setKmCharge("");
        setMaxKm("");
     
      };

    }


      const fetchData = async () => {

        await getAllBranches().then(data => {

      
 
          dispatch({
            type: actionType.SET_BRANCH,
            vipBranches : data
          });
        });
      };
    

  return (
   
    
    user && userRole && (userRole === "admin") ? (

      pass === "new" ? (
        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageBranch"}>Manage Branches</Link><MdKeyboardArrowRight />Create New Branch</div>
        <div className="w-full  flex mt-6 justify-center ">
          <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center gap-4 bg-white">
    
              {
                fields && (
    
                  <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                    {msg}
                  </motion.p>
                )
              }
    
    {isLoading ? <Loader />: (<>
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdOutlineLocationOn className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setTitle(toCamelCase(e.target.value)) } 
                    
                    required value={title} placeholder="Enter The Location" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>

              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <TbWorldLatitude className="text-gray-700 text-2xl" />
                      <input type="text" required value={lat} onChange={(e) => setLat(e.target.value) } placeholder="Latitude" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <TbWorldLongitude className="text-gray-700 text-2xl" />
                      <input type="text" required value={lng} onChange={(e) => setLng(e.target.value)} placeholder="Longitude" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
                  </div>
              
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdCode className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setShortCode(e.target.value.replace(/\s/g, "").toLowerCase())} required value={shortCode} onDoubleClick={(e) => title.replace(/\s/g, "") && setShortCode(title.replace(/\s/g, "").toLowerCase())}  placeholder="Enter The Short Code" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>

              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <MdPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Phone Number (eg: 0756101993)' value={phoneNumber} maxLength={12} 
                        rules={{ required: true }}
                            country="LK"
                            defaultCountry='LK'
                            international
                          
                        onChange={setPhoneNumber} />
                      </div>
    
           
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineMap className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={address} onChange={(e) => setAddress(e.target.value) } placeholder="Address" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>
  
                        
                      </div>
    
                      <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <Currency code="LKR (රු.)" size="small" className="text-gray-700 text-2xl" />
                      <input type="text" required value={kmCharge} onChange={(e) => setKmCharge(e.target.value)} placeholder="Charge per km" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                     
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                       <GiPathDistance className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => {!isNaN(e.target.value) ? setMaxKm(e.target.value): setMaxKm('0.0')}} required value={maxKm}   placeholder="Enter Maximum Delivery Distance (km)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    </div>
              
    
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={100} ></textarea>
                    </div>
  
                        
                      </div>
    
                      </>)}
                   
    
                      <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={saveDetails}>Save <MdSave /></button>
                  </div>
          </div>
        </div> </div>
      ) : pass === "solo" ? (

        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageBranch"}>Manage Branches</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageBranch/manage"}>Manage All Branches</Link><MdKeyboardArrowRight />{branchId}</div>
        <div className="w-full  flex mt-6 justify-center ">
          <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center gap-4 bg-white">
    
              {
                fields && (
    
                  <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                    {msg}
                  </motion.p>
                )
              }
    
    {isLoading ? <Loader />: (<>
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdOutlineLocationOn className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setTitle(toCamelCase(e.target.value)) } 
                    
                    required value={title} placeholder="Enter The Location" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>

              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <TbWorldLatitude className="text-gray-700 text-2xl" />
                      <input type="text" required value={lat} onChange={(e) => setLat(e.target.value) } placeholder="Latitude" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <TbWorldLongitude className="text-gray-700 text-2xl" />
                      <input type="text" required value={lng} onChange={(e) => setLng(e.target.value)} placeholder="Longitude" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
                  </div>
              
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdCode className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setShortCode(e.target.value.replace(/\s/g, "").toLowerCase())} required value={shortCode} onDoubleClick={(e) => title.replace(/\s/g, "") && setShortCode(title.replace(/\s/g, "").toLowerCase())}  placeholder="Enter The Short Code" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>

              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <MdPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Phone Number (eg: 0756101993)' value={phoneNumber} maxLength={12} 
                        rules={{ required: true }}
                            country="LK"
                            defaultCountry='LK'
                            international
                          
                        onChange={setPhoneNumber} />
                      </div>
    
           
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineMap className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={address} onChange={(e) => setAddress(e.target.value) } placeholder="Address" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>
  
                        
                      </div>
    
                      <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <Currency code="LKR (රු.)" size="small" className="text-gray-700 text-2xl" />
                      <input type="text" required value={kmCharge} onChange={(e) => setKmCharge(e.target.value)} placeholder="Charge per km" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                     
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                       <GiPathDistance className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => {!isNaN(e.target.value) ? setMaxKm(e.target.value): setMaxKm('0.0')}} required value={maxKm}   placeholder="Enter Maximum Delivery Distance (km)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    </div>
              
    
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={100} ></textarea>
                    </div>
  
                        
                      </div>
    
                      </>)}
                   
    
                      <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={deleteData}>Delete <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => saveDetails('update')}>Save <MdSave /></button>
                  </div>
          </div>
        </div> </div>
      ) : pass === "manage" ? (


        /* Manage Items */

      <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
      <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageBranch"}>Manage Branches</Link><MdKeyboardArrowRight />Manage All Branches</div>
      <div className="w-full flex flex-auto justify-center items-center mt-6 overflow-auto">


<div className='w-full  flex overflow-auto scrollbar-thin scrollbar-thumb-slate-400 bg-slate-200 shadow-lg rounded-lg'>

            {
             vipBranches ? (
                <table className='w-full' {...getTableProps()}>

              <thead>

              {headerGroups.map((headerGroup) => (

                  <tr className='bg-white hover:bg-slate-50 font-semibold text-xl' {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map((column) => (

                      <th className='py-4' {...column.getHeaderProps()}>

                        {column.render("Header")}

                      </th>
                    ))}

                  </tr>

              ))}

              </thead>

              <tbody className='hover:bg-slate-300' {...getTableBodyProps()}>

                      {
                        rows.map((row) => {

                          prepareRow(row)

                          return (


                            <tr className='text-lg hover:bg-slate-400 border-t-2 border-white hover:text-slate-50 hover:font-semibold cursor-pointer' {...row.getRowProps()} onClick={(e) => {history(row.cells[0].value)}}>

                              {row.cells.map((cell) => (
                              
                                <td className='py-4 px-3' {...cell.getCellProps()}>
                               
                                    {cell.render('Cell')}
                                    
                                 
                                </td>
                               
                              ))}
 
                            </tr>
                          )

                        })
                      }

              </tbody>


            </table>
              ) : (<LoaderCustom />)
            }

          </div>



            </div>

    </div>
    
    



      ) :(<div className='text-center py-16 w-full flex flex-col gap-8 justify-center items-center m-auto select-none'> 

<div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight />Manage Branches</div>
      
      <Link to={"../admin/manageBranch/new"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-emerald-500 p-3 rounded-lg hover:bg-emerald-400 gap-2 w-full'>Create Branch <MdOutlineStoreMallDirectory /></button>
      </Link> 

      <Link to={"../admin/manageBranch/manage"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-blue-500 p-3 rounded-lg hover:bg-blue-400 gap-2 w-full'>Manage Branch <MdLocationCity /></button>
      </Link>
      </div>)
      ) : (
    
        
            
    <Navigate to="/" />
      )
  )
}

export default ManageBranch