import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getOrders } from '../utils/firebaseFunctions';
import { fetchCustomer, fetchOrder } from '../utils/fetchLocalStorageData';
import { MdVerified } from 'react-icons/md';
import {BiFoodMenu} from 'react-icons/bi';
import {GrTransaction} from 'react-icons/gr';
import { ImPriceTags } from 'react-icons/im';
import {TbCashBanknoteOff} from 'react-icons/tb';
import LoaderCustom from './LoaderCustom';
import { sendNotif } from '../utils/socketFunc';

const OrderVerify = () => {
    const {orderId} = useParams();
    const [order, setOrder] = useState(null);
    const [run, setRun] = useState(0);

    const navigate = useNavigate();

 
    const customer = fetchCustomer()

    const getRealOrder = async () => {

      localStorage.removeItem('orders');

      await getOrders(customer.customerId,orderId);

      const fetchVal = fetchOrder();

      var new_order;
      if(fetchVal){
       new_order = JSON.parse(fetchVal);
      }else{


        console.log("Not a Valid Order!");
        return false;
      }

     


       if(new_order){

     

        setOrder(new_order);

        if(new_order.status === 1){



          setTimeout(() => {

           

            navigate("../myOrders/"+new_order.id);
            
          }, 2000);

        }else{//To Verify

          console.log("Order Verifying...");

          await fetch('https://api.viphotels.lk/ipg/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                
                trans_id : new_order.id,
       
        })
            })
            .then(response => response.json())
            .then(data => {
    
              
              //console.log(JSON.stringify(data.data));

              if(data.data === null){

          
                
             
                new_order.status = 200
              
          
           
               
              }else{

                
                
                
                new_order.status = parseInt((JSON.parse(data.data)).status);

                
              }
               
              setOrder(new_order);
              setRun(1);

              if(new_order.status === 1){

                const customerId = customer.customerId;

                const data = {
      
                  id: "log",
                  time: `${new Date()}`
                }

                sendNotif(data, customerId);
      
           

                setTimeout(() => {

                 navigate("../myOrders/"+new_order.id);
                  
                }, 3000);
              }
            
    
    
            })
            .catch(error => {
    
              
                console.log(error);
                window.createNotification("error","Unable to Process", 'Unable to verify the payment at this moment!',3000);
               
              
            })


        }


        
       }else{

        setOrder(null);
        localStorage.removeItem('orders');
       }

     


    };

    useEffect(() => {

    if(run === 0){

      setTimeout(() => {

        getRealOrder();
        
       }, 1000);
    }
         
    }, []);


    
    
  return (
    <div className='w-full items-center justify-center mt-7 mb-10 select-none overflow-x-auto scrollbar-thin scrollbar-thumb-slate-400 py-2'>

      {
        order ? (

        <div className='w-full flex flex-col gap-3 justify-center items-center'>

            <span className="flex flex-row gap-2 items-center  text-green-700 text-lg sm:text-2xl font-semibold text-center"><BiFoodMenu /> Active Order</span>

            <div className='w-full justify-center items-center flex flex-col gap-2 mt-4 border-t-2 border-t-black  px-2 bg-white rounded-lg drop-shadow-lg py-6'>

          <div className='flex flex-col gap-10 md:gap-4'>
          <span className="flex flex-col md:flex-row gap-2 items-center  text-black text-md sm:text-lg font-mono text-center font-semibold"><GrTransaction /> Transaction ID: <span className=' select-text font-thin'>{order.id}</span></span>

          <span className="flex flex-col md:flex-row gap-2 items-center  text-black text-md sm:text-lg font-mono text-center font-semibold"><GrTransaction /> Reference Code: <span className=' select-text font-thin'>{order.reference}</span></span>

          <span className="flex flex-col md:flex-row gap-2 items-center  text-lg sm:text-xl font-mono text-center font-semibold text-red-600"><ImPriceTags /> Amount: <span className='font-thin'>{order.amount} LKR</span></span>

         {
          order.status === 1 ? (

          <div className='w-full items-center justify-center flex flex-col gap-3'>
              <span className="flex flex-col md:flex-row gap-2 items-center justify-center mt-5 text-lg sm:text-2xl font-mono text-center font-semibold text-green-700">Verified <MdVerified /></span>
            <span className="flex flex-col md:flex-row gap-2 items-center justify-center  text-md sm:text-lg font-mono text-center  text-gray-800"><LoaderCustom height='5' width='5' fg={'fill-black'} /><span className='font-thin'>  Redirecting...</span></span>
          </div>
          ) : order.status === 200 ? (

            <span className="flex flex-col md:flex-row gap-2 items-center justify-center mt-5 text-lg sm:text-2xl font-mono text-center font-semibold text-red-700">Not Paid <TbCashBanknoteOff /></span>


          ) : (

            <span className="flex flex-col md:flex-row gap-2 items-center justify-center mt-5 text-lg sm:text-xl font-mono text-center font-semibold text-red-600"> <LoaderCustom height='8' width='8' fg={'fill-red-600'} /> Verifying...</span>
          )
         }
          </div>

            </div>

        </div>

        ) : (
        
        <div>


        </div>
        
        )
      }


    </div>
  )
}

export default OrderVerify