import {getApp, getApps, initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';


const firebaseConfig = {

    apiKey: "AIzaSyB0TnqW0HV-Ir43GylsGWb4FeX16iUrnGs",
    authDomain: "auth.viphotels.lk", //viphotelsapp.firebaseapp.com
    databaseURL: "https://viphotelsapp-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "viphotelsapp",
    storageBucket: "viphotelsapp.appspot.com",
    messagingSenderId: "919307290981",
    appId: "1:919307290981:web:24221559a090eb1e25398c",

    

    /*apiKey: "AIzaSyCTlv2sEXN_D7-VSU8IYWhcFGK2-cR0VE4",
    authDomain: "viphotelslk.firebaseapp.com",
    projectId: "viphotelslk",
    storageBucket: "viphotelslk.appspot.com",
    messagingSenderId: "266327777370",
    appId: "1:266327777370:web:d465cedaee9d807ce53863*/
  
  };

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

  

  const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

  const appCheck = initializeAppCheck(app, {
 // provider: new ReCaptchaV3Provider('6LcM6NAlAAAAAL9Tw4Xm2zzX0vQaZRSUZE-DEjWw'),
 provider: new ReCaptchaV3Provider('6LdgClklAAAAAKch8eda6E1ubz-saSM-iPDQqDJX'),
//
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,

});



  const firestore = getFirestore(app);
  const storage = getStorage(app);
 // const messaging = getMessaging(app);
  

  


  export {app, firestore, storage};