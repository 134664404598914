export const actionType = {

    SET_USER : 'SET_USER',
    SET_FOOD_ITEMS : 'SET_FOOD_ITEMS',
    SET_SUPPLIER_BILLS : 'SET_SUPPLIER_BILLS',
    SET_FOOD_CATEGORIES : 'SET_FOOD_CATEGORIES',
    SET_BRANCH: 'SET_BRANCH',
    SET_CUSTOMER: 'SET_CUSTOMER',
    SET_CART_SHOW: 'SET_CART_SHOW',
    SET_CART_ITEMS: 'SET_CART_ITEMS',
    SET_NOTI_SHOW: 'SET_NOTI_SHOW',
    SET_NOTIFY: 'SET_NOTIFY',
    SET_USERS: 'SET_USERS',
 
};

const reducer = (state, action) => {

  

    switch(action.type){

        case actionType.SET_USER: 
            return{

                ...state,
                user : action.user,
            };

            case actionType.SET_CUSTOMER: 
            return{

                ...state,
                customerData : action.customerData,
            };

            case actionType.SET_USERS: 
            return{

                ...state,
                vipUsers : action.vipUsers,
            };

            case actionType.SET_BRANCH: 
            return{

                ...state,
                vipBranches : action.vipBranches,
            };

            case actionType.SET_FOOD_ITEMS: 
            return{

                ...state,
                foodItems : action.foodItems,
            };

            case actionType.SET_SUPPLIER_BILLS: 
            return{

                ...state,
                supplierBills : action.supplierBills,
            };

            case actionType.SET_NOTI_SHOW: 
            return{

                ...state,
                notiShow : action.notiShow,
            };
            
            case actionType.SET_NOTIFY: 
            return{

                ...state,
                notifications : action.notifications,
            };

          

            case actionType.SET_FOOD_CATEGORIES: 
            return{

                ...state,
                foodCategories : action.foodCategories,
            };

            case actionType.SET_CART_SHOW: 
            return{

                ...state,
                cartShow : action.cartShow,
            };

            case actionType.SET_CART_ITEMS: 
            return{

                ...state,
                cartItems : action.cartItems,
            };


        



            default :
                return state; 

    }
}

export default reducer;