import React from 'react'
import { useParams } from 'react-router-dom'

const MyOrders = () => {
    const {orderId} = useParams();
  return (
    <div>My Orders : {orderId} <br /> <br /> <h2 className='text-2xl font-semibold'>Under Development!</h2></div>
  )
}

export default MyOrders