import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import { motion } from 'framer-motion';
import { MdKeyboardArrowLeft,  MdOutlinePayment, MdRebaseEdit, MdShoppingBasket } from 'react-icons/md';
import {BiMinus, BiPlus} from 'react-icons/bi';
import Loader from './Loader';
import E404 from './E404';
import { fetchBranch, fetchCart, fetchRole } from '../utils/fetchLocalStorageData';
import { actionType } from '../context/reducer';
import { useEffect } from 'react';



const Item = () => {
    const {itemId} =  useParams();
    const [{foodItems,cartItems, user}, dispatch] = useStateValue();
    const history = useNavigate();
    
    const [items, setItems] = useState(fetchCart());

    const [branch, setBranch] = useState(fetchBranch());
    

    const [quantity, setQuantity] = useState(1);

    const userRole = fetchRole();
   

    const data = foodItems?.filter(n => n?.id === itemId);

    const changeQty = (e,id) => {

        if (/^\d*\.?\d*$/.test(e.target.value) || e.target.value === '') { // regex to check for positive numbers
          
        }else{
      
          window.createNotification("error","Invalid Amount","You can only add positive numbers!", 1000);
          console.log("Not a valid value");
          return false;
        }
      
    
      
            setQuantity(parseInt(e.target.value)?parseInt(e.target.value):1);
          
        
        
      
        
      };
      
      const dropQty = () => {
      
        const value = parseInt(quantity) - 1;

        if(value > 0){
       
             setQuantity(value);

        }else{

            window.createNotification("error","Unable to Deduct","Quantity should be greater than 0!",2000);
            setQuantity(1);
        }


      
       //
      
      
      };
      
      const addQty = () => {
      
       

          setQuantity(parseInt(quantity) + 1);
      
      
      
      };

    
      const validateItem = (item) => {
    
        if(user){
    
         
         
      if(fetchBranch()){
        if(cartItems.findIndex(itemT => itemT.id === item.id) === -1){
    
          item.qty = quantity;
    
          setItems([...cartItems, item]);
        }else{
    
            let index = parseInt(cartItems.findIndex(itemT => itemT.id === item.id));
    
    
            cartItems[index].qty = parseInt(cartItems[index].qty) + quantity;
          
        
            addtocart();
        
         
    
            //Clear the Cart
        
        
          //console.log(cartItems.indexOf(item));
       
    
         
         // window.createNotification("warning","Unables to Add to Cart","This Item is already available in the cart!",3000);
        }
    
      }else{
        window.createNotification("error","Unable to Add to Cart","Please set the branch first!",3000);
    
      }
      }else{
        window.createNotification("error","Unable to Add to Cart","Please login to the system first!",3000);
      }
      };
    
      const addtocart = () => {
    
       
    
      
    
       
          
        dispatch({
          type: actionType.SET_CART_ITEMS,
          cartItems : items,
        });
    
    
    
    
        localStorage.setItem('cartItems',CryptoJS.AES.encrypt(JSON.stringify(items),localStorage.getItem('ffkey')).toString());;
       
        
    
        
    
      };
    
      useEffect(() => {
    
        if(user){
    
        addtocart();
    
      }else{
        console.log("Login to Handle Cart!");
      }
     
      }, [items]);
      
      

   

    
  return (

    <div className='items-center text-center justify-center sm:justify-between w-full mt-5'>
         
       
        {data && data.length > 0 ? data.map(item =>(

            <div  key={item?.id}>

        <div className='w-full text-left flex flex-row items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg mb-10 select-none'><span onClick={(e) => history(-1)} className='text-blue-600 hover:text-blue-500 cursor-pointer'>Back</span><MdKeyboardArrowLeft />{item?.title}</div>

            <div className='block text-center md:flex justify-center md:justify-between  w-full p-4 rounded-2xl drop-shadow-xl items-center md:gap-3 select-none bg-white'>


           <div className='relative block lg:flex items-center justify-between w-full gap-8'>
           <div className="font-semibold text-2xl text-headingColor items-center flex flex-col">
                <p>{item?.title}</p>
                <motion.img src={item.imageURL} alt={item.title} className="text-center items-center justify-center min-w-0 max-w-[300px] max-h-60  drop-shadow-2xl cursor-zoom-in mt-2" whileHover={{scale:1.5}}  />
            </div>
           
           <div className='p-3 flex flex-col w-full lg:w-[30%]'>
                <div className='font-semibold w-full gap-3 flex justify-center items-center bg-yellow-100 hover:bg-yellow-200 p-4 rounded-2xl'><span className='text-2xl'>Price :</span> <span className='text-red-500 text-xl'>LKR</span><span className=' text-2xl'>{item?.price} /=</span></div>
            
                <div className='mt-8 justify-center text-justify text-base font-semibold'><span className='underline cursor-pointer'>{item?.description}</span></div>
                <div className='mt-8 text-base font-semibold'>Category : <span className='underline cursor-pointer'>
                    {

                        item?.category.toString().replace(","," , ").toUpperCase()

                    }
                    </span></div>

            </div>

            <div className='p-3 flex flex-col m-10 gap-4 h-auto justify-center items-center'>

                
          
                
           {/*  <div className="p-2 w-full text-center items-center justify-center"><button className="w-full bg-green-500 p-3 rounded-md hover:bg-green-400 hover:drop-shadow-md items-center flex gap-4 text-white font-semibold text-xl text-center justify-center"><MdOutlinePayment />Buy</button></div> */}
           <div className="group justify-center flex items-center cursor-pointer bg-slate-200 hover:bg-slate-400 outline outline-2 outline-orange-800 rounded-lg">
  
                    <motion.div className='p-1 bg-red-500 hover:bg-red-800 rounded-l-lg text-white text-2xl' whileTap={{scale:0.75}} onClick={(e) => dropQty()}>
                        <BiMinus className='' />
                    </motion.div>

                    <input className=' w-8 h-8 text-center outline outline-2 z-10  outline-orange-800 bg-slate-50 text-gray-800 flex items-center justify-center font-semibold' value={quantity} onChange={(e) => {changeQty(e)}} />

                    <motion.div className='p-1 bg-yellow-500 hover:bg-yellow-600 rounded-r-lg text-white text-2xl' whileTap={{scale:0.75}}  onClick={(e) => addQty()}>
                        <BiPlus className='' />
                    </motion.div>

                    </div>
                <div className="p-2 w-full text-center items-center justify-center"><button className="w-full bg-red-500 p-3 rounded-md hover:bg-red-400 hover:drop-shadow-md items-center flex gap-4 text-white font-semibold text-xl text-center justify-center" onClick={() => validateItem(item)}><MdShoppingBasket />Add</button></div>

               {userRole === 'admin' && (
                 <div className="p-2 w-full text-center items-center justify-center"><Link to={`../admin/manageItem/manage/${itemId}`}><button className="w-full bg-blue-500 p-3 rounded-md hover:bg-blue-400 hover:drop-shadow-md items-center flex gap-4 text-white font-semibold text-xl text-center justify-center"><MdRebaseEdit />Edit</button></Link></div>
               )}
            </div>
            
       
          
           </div>


          
          

        </div>

        <div className='w-full justify-center flex flex-col gap-8 mt-8 bg-white p-4 rounded-2xl shadow-2xl items-center overflow-auto mb-8'>
        <div className='text-xl font-semibold text-center'><div className='mt-5  text-blue-600 font-semibold text-base sm:text-xl md:text-2xl relative before:absolute before:rounded-lg before:content before:w-[70%] before:h-1 before:-bottom-2 before:bg-gradient-to-tr from-blue-400 to-blue-600 transition-all ease-in-out duration-100 mr-auto select-none mb-8'>QRCodes & Barcodes</div></div>
        <div>
        <div className='text-lg font-semibold text-red-600 text-center'>{item?.id}</div>
        <QRCode
            size={150}
            className="w-100 max-w-100 h-auto md:right-0 flex"
            value={window.location.href}
            viewBox={`0 0 500 500`}
            />
        </div>

            <Barcode  value={item?.id} />
         </div> <div className='p-1'></div>

        </div>
        )) : data ? (<div><E404 pass={'The requested Food Item is not available!\n\nItem ID: ' + itemId} /></div>) : (<div><Loader /></div>)}

       
    </div>
    
   
  )
}

export default Item