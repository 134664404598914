import React, { useEffect } from 'react'

const About = () => {

    useEffect(() => {

        window.createNotification("info","Under Development","This page is currently under development!", 5000);
       
      }, [])
      
  return (
    <div>About Us</div>
  )
}

export default About