import React from 'react';
import { useState } from 'react';
import {IoFastFood} from 'react-icons/io5';
import { useStateValue } from '../context/StateProvider';
import { useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useRef } from 'react';
import {motion} from 'framer-motion';
import RowContainer from './RowContainer';


const MenuContainer = () => {

  const [filter, setFilter] = useState('chicken');
  const [{foodCategories, foodItems}, dispatch] = useStateValue();





  const slider = useRef();



  var isDown = false;
  var startX;
  var scrollLeft;
 
  useEffect(() => {
 

      if (slider && slider.current) {


          slider.current.addEventListener("mousedown",  (e) => {


            isDown = true;
            startX = e.pageX - slider.current.offsetLeft;
            scrollLeft = slider.current.scrollLeft;

        

         



          }, false);

          slider.current.addEventListener("mouseleave",  (e) => {

            isDown = false;

          }, false);

          slider.current.addEventListener("mouseup",  (e) => {

            isDown = false;

          }, false);

          slider.current.addEventListener("mousemove",  (e) => {

            if(!isDown) return;
            e.preventDefault();

            const x = e.pageX - slider.current.offsetLeft;

           const walk = x - startX;

           slider.current.scrollLeft = scrollLeft  - walk;

          }, false);

 
         

      }
  }, []);

  return (
    <section className="w-full my-6" id="menu">
        <div className="w-ful flex flex-col items-center justify-center">
       
        <p className="text-2xl font-semibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-16 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-orange-400 to-orange-600 transition-all ease-in-out duration-100 mr-auto select-none">Our Hot Dishes</p>

        <div ref={slider} className="w-full flex items-center justify-start lg:justify-center gap-8 py-6 overflow-x-auto scrollbar-thin scrollbar-thumb-red-400 scrollbar-track-rose-200 select-none" >

    


        {foodCategories && foodCategories.map(category => (
      
      
            <motion.div key={category.id}
            
            whileTap={{scale : 0.75}}
            whileHover={{scale: 1.07}}

            className={`group ${filter == category.shortCode ? 'bg-cartNumBg hover:bg-orange-700 text-textColor' : 'bg-card hover:bg-cartNumBg'} w-24 min-w-[94px] h-28 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-3 items-center justify-center`} onClick={() => {setFilter(category.shortCode)}}>

              <div className={`w-10 h-10 rounded-full  ${filter == category.shortCode ? 'bg-card text-textColor ' : 'bg-cartNumBg'} group-hover:bg-card flex items-center justify-center`}>
                  <IoFastFood className={`text-card group-hover:text-textColor  ${filter == category.shortCode ? ' text-textColor' : ''} text-lg`} />
              </div>
              <p className={`text-sm ${filter == category.shortCode ? 'text-white ' : 'text-textColor'} group-hover:text-white`}>{category.title}</p>

            </motion.div>
        ))}

        </div>

          <div className='w-full '>

            <RowContainer   flag={false} 
        data={foodItems?.filter(n => {
          
          for (let i = 0; i < n.category.length; i++){

            if(n.category[i] === filter){
           return (n.category[i] === filter)

            }

          }

          return (n.category[0] === filter)
        
        
        })} />

          </div>

        </div>
    </section>

  )
}

export default MenuContainer