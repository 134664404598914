import React, { useState } from 'react';
import {getAuth, linkWithPopup, GoogleAuthProvider, updateProfile ,  unlink, ProviderId} from 'firebase/auth';
import { app } from '../firebase.config';
import {FiLink2} from 'react-icons/fi';
import { MdOutline123, MdOutlineLinkOff, MdSend } from 'react-icons/md';
import CryptoJS from 'crypto-js';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { fetchCustomer } from '../utils/fetchLocalStorageData';

const LinkGmail = ({gMail}) => {

  const firebaseAuth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const [{user}, dispatch] = useStateValue();

    const [expandForm, setExpandForm] = useState(false);


    const customer = fetchCustomer();

    const verifyGoogle = async () => {


        linkWithPopup(firebaseAuth.currentUser, provider).then((result) => {

          const credential = GoogleAuthProvider.credentialFromResult(result);
          const user = result.user;

          if(user.email != gMail){

            window.createNotification("error","Unable to Link","The given gmail address and authenticated Google account is not matched!");
            unlinkGoogle();
            return false;
          }
          dispatch({
            type: actionType.SET_USER,
            user: user
        });
       

          localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user),localStorage.getItem('ffkey')).toString());
    



        }).catch((error) => {

          if(error.code === 'auth/credential-already-in-use'){

           window.createNotification("error","Unable to Link","This Google Account is already linked with another user!");
          }else{
          console.log(error);
          }
        });
    }


    const unlinkGoogle = () => {

      

  
      
      unlink(firebaseAuth.currentUser, GoogleAuthProvider.PROVIDER_ID).then((result) => {


      

          const user = result;
          dispatch({
            type: actionType.SET_USER,
            user: user
        });
       
          localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user),localStorage.getItem('ffkey')).toString());

          window.createNotification("success","Unlinked Successfully!", "The Google Authentication to the this account has been unlinked!")
        
   
      
     

    
          // Auth provider unlinked from account
          // ...
        }).catch((error) => {
          // An error happened
          if(error.code === "auth/no-such-provider"){

            window.createNotification("error","Unable to Process","This process is already done!", 5000);
          }else{

            console.log(error);
          }
          // ...
        });
    }

 


  
  return (

    <div className='w-full'>

<div className='flex flex-col w-full gap-4'>
            <p className='text-xl font-semibold text-blue-600 w-full text-center justify-center flex items-center underline underline-offset-4'>DOCS</p>
            <p className='text-lg font-sans text-justify'>You are able to link/unlink your Gmail Address {gMail&& (<span className='font-semibold italic'>({gMail})</span>)} with this user account though this feature. If you link your Gmail with this account, you are able to sign-in with Google Account in the next time. </p>
            
        </div>
        {gMail ? (

<div className='flex flex-col sm:flex-row w-full justify-center items-center mt-10 border-t-2 border-gray-600 p-4'>


                {!expandForm ? (<div className='w-full flex flex-col sm:flex-row gap-4 justify-center items-center'>

{customer.customerEmail !== gMail ? (<div className='font-semibold text-red-600 italic'>Your current saved gmail adrress is "{customer.customerEmail}" and you wish to link "{gMail}". Unsaved gmail address cannot be linked with this user account. First, save the gmail address and link the saved gmail address!</div>) : !window.check_provider(user,"google.com") ? (<button className='p-3 flex flex-row justify-center items-center gap-3 font-semibold text-lg bg-green-600 text-white rounded-md shadow-md w-[150px] hover:bg-green-700' onClick={(e) => {verifyGoogle()}}>Link <FiLink2 /></button>): !window.check_provider(user,"google.com") || !user.phoneNumber ? (<div className='font-semibold text-red-600 italic'>Your account only has been linked with a Google Account. If you wish to unlink the current Google Account, please link your account with a Phone Number!</div>) : (<button className='p-3 flex flex-row justify-center items-center gap-3 font-semibold text-lg bg-red-600 text-white rounded-md shadow-md w-[150px] hover:bg-red-700' onClick={(e) => {unlinkGoogle();}}>Unlink <MdOutlineLinkOff /></button>)}



</div>


): expandForm === 'success' ? (<div className=' flex flex-col md:flex-row justify-center items-center gap-3'>

    <p className='text-md sm:text-lg md:text-xl text-emerald-700 font-semibold'>Successfully Linked!</p>

</div>):  (<div className=' flex flex-col md:flex-row justify-center items-center gap-3'>
    


</div>)}

            </div>
        
        
        
        
        ) : (<div className='text-lg font-semibold text-red-600'>Unable to proceed with this Phone Number!</div>)}



    </div>

  )
}

export default LinkGmail