import I1 from '../img/i1.png';
import F1 from '../img/f1.png';
import C3 from '../img/c3.png';
import Fi1 from '../img/fi1.png';

export const static_food_data = [
    {id: 1, name: 'Ice Cream', desc: 'Chocolate & Vanilla', price_from: '100', image: I1},
    {id: 2, name: 'Strawberries', desc: 'Fresh Straberries', price_from: '150', image: F1},
    {id: 3, name: 'Chicken Kebab', desc: 'Mexied Kebab Plate', price_from: '200', image: C3},
    {id: 4, name: 'Fish Kebab', desc: 'Mixed Fish Kebab', price_from: '100', image: Fi1},
  ];


  export const categories = [
    {
      id: 1,
      name: "Chicken",
      urlParamName: "chicken",
    },
    {
      id: 2,
      name: "Curry",
      urlParamName: "curry",
    },
    {
      id: 3,
      name: "Rice",
      urlParamName: "rice",
    },
    {
      id: 4,
      name: "Fish",
      urlParamName: "fish",
    },
    {
      id: 5,
      name: "Fruits",
      urlParamName: "fruits",
    },
    {
      id: 6,
      name: "Icecreams",
      urlParamName: "icecreams",
    },
  
    {
      id: 7,
      name: "Soft Drinks",
      urlParamName: "drinks",
    },
  ];