import { useEffect, useState } from 'react';
import { fetchRole } from './fetchLocalStorageData';



export const sendNotif = (data, room,apply = "send_notif") => {



    const role = fetchRole();
    const socket = window.socket;

   if(data){

    socket.emit(apply,{
data: data,
room: role
    }); 
   }else{

    window.createNotification("error","Invalid Message!","Don't keep the message as blank!",2000);

   }



 };




export const sendMessage = (title,message,mode,apply = "send_message") => {

    const socket = window.socket;
    
  

   if(message && message.replace(/\s/g, "") !== ''){

    socket.emit(apply,{
        title: title,
        message: message,
        sender_id: localStorage.getItem('ex_id'),
        receiver_id: null,
        segmant: [],
        ioid: socket.id,
        type: mode //callback, user, segmant
    }); 
   }else{

    window.createNotification("error","Invalid Message!","Don't keep the message as blank!",2000);

   }



 };

export const getMessage = (data) => {


 
    if(data){

        console.log(data);

        //window.createNotification("info", "Notification Sent", "Your Notification has been sent!", 3000);

    }else{
 
        //window.createNotification("warn", "Notification Not Sent", "Your Notification has not been sent!", 3000);
    }
    
 } 

 export const getNotify = (data) => {
    
   localStorage.setItem("noti", 2);
 };