import React from 'react';
import CryptoJS from 'crypto-js';
import { motion} from 'framer-motion';
import {MdOutlineKeyboardBackspace, MdShoppingCartCheckout} from 'react-icons/md';
import {RiRefreshFill} from 'react-icons/ri';
import {BiMinus, BiPlus} from 'react-icons/bi';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { Link } from 'react-router-dom';
import EmptyCart from '../img/emptyCart.svg';
import { fetchCustomer } from '../utils/fetchLocalStorageData';



const CartContainer = () => {
  const [{cartShow, cartItems}, dispatch] = useStateValue();


  const customer = fetchCustomer();

  

  
  const calcPrice = () => {

    let price =  0;

    cartItems && cartItems.map(item => {

      let subPrice = parseFloat(item.price) * parseFloat(item.qty);

      price += subPrice;


    });

    price = parseFloat(price).toFixed(2);




    return price;

  };

  const clearCart = () => {

    localStorage.removeItem("cartItems");

    dispatch({
      type: actionType.SET_CART_ITEMS,
      cartItems: []
  });


  };

  
  const hideCart = () => {

    dispatch({
        type: actionType.SET_CART_SHOW,
        cartShow: false
    });



};

const changeQty = (e,id) => {

  if (/^\d*\.?\d*$/.test(e.target.value) || e.target.value === '') { // regex to check for positive numbers
    
  }else{

    window.createNotification("error","Invalid Amount","You can only add positive numbers!", 1000);
    console.log("Not a valid value");
    return false;
  }


  let index = parseInt(cartItems.findIndex(itemT => itemT.id === id));

  if(index !== -1){

    cartItems[index].qty = parseInt(e.target.value)?parseInt(e.target.value):1;

    if(!(parseInt(cartItems[index].qty) > 0)){
      cartItems.splice(index,1);
    }else{}

    dispatch({
      type: actionType.SET_CART_ITEMS,
      cartItems: cartItems
  });

  localStorage.setItem('cartItems',CryptoJS.AES.encrypt(JSON.stringify(cartItems),localStorage.getItem('ffkey')).toString());
  
  }else{

    console.log("NOP");
  }

  
};

const dropQty = (id) => {

 

  let index = parseInt(cartItems.findIndex(itemT => itemT.id === id));

  if(index !== -1){

    cartItems[index].qty = parseInt(cartItems[index].qty) - 1;

    if(!(parseInt(cartItems[index].qty) > 0)){
      cartItems.splice(index,1);
    }

    dispatch({
      type: actionType.SET_CART_ITEMS,
      cartItems: cartItems
  });

  localStorage.setItem('cartItems',CryptoJS.AES.encrypt(JSON.stringify(cartItems),localStorage.getItem('ffkey')).toString());
   


  }else{
    console.log("NOP");
  }

 //


};

const addQty = (id) => {

 

  let index = parseInt(cartItems.findIndex(itemT => itemT.id === id));

  if(index !== -1){

    cartItems[index].qty = parseInt(cartItems[index].qty) + 1;

    

    dispatch({
      type: actionType.SET_CART_ITEMS,
      cartItems: cartItems
  });

  localStorage.setItem('cartItems',CryptoJS.AES.encrypt(JSON.stringify(cartItems),localStorage.getItem('ffkey')).toString());
   


  }else{
    console.log("NOP");
  }

 //


};



  return (
    <motion.div className='fixed top-0 right-0 w-full md:w-375 h-screen bg-white drop-shadow-md flex flex-col select-none z-[101]' initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100, }} transition={{  type: "spring", stiffness: 150, delay:0 }}>
      
      <div className="w-full flex flex-row items-center justify-between p-4">
        
        <motion.div whileTap={{scale : 0.75}} onClick={hideCart} className='cursor-pointer'><MdOutlineKeyboardBackspace className='text-textColor text-3xl' />
        </motion.div>
        <p className='text-textColor text-lg font-semibold'>Cart</p>

        <motion.p whileTap={{scale : 0.75}}  className='flex items-center gap-2 p-1 px-2 my-2 bg-gray-100 rounded-md hover:bg-red-500 hover:text-white hover:shadow-md  cursor-pointer text-textColor text-base' onClick={clearCart}>Clear <RiRefreshFill /></motion.p>
        
        
      </div> 

      {/* Bottom Section */}

      { customer && cartItems && cartItems.length > 0 ? (

      <div className='w-full h-full bg-cartBg rounded-t-[2rem] flex flex-col overflow-y-scroll scrollbar-track-transparent  scrollbar-thumb-gray-600'>

        
          {/* Cart Item Section */}

        <div className="w-full h-340 md:h-42 px-6 py-10 flex flex-col gap-3 overflow-y-scroll scrollbar-thin scroll-m-5 scrollbar-track-transparent  scrollbar-thumb-gray-600">

          {/* Cart Item */}


          {
            cartItems && cartItems.length > 0 ? cartItems.map(item => parseInt(item.qty) > 0 && (

              <div key={item?.id} className='w-full p-1 px-2 rounded-lg bg-cartItem flex items-center gap-2'>
              <img src={item?.imageURL} className='w-20 h-20 max-w-[60px] rounded-full object-contain' alt="" />
  
              {/* Name Section */}
              <div className="flex flex-col gap-2">
                <p className="text-base text-gray-50">
                  {item?.title}
                </p>
                <p className='text-sm block text-gray-300 font-semibold'>
                  LKR {item?.price}
                </p>
              </div>
  
              {/* Button Section */}
              <div className="group flex items-center gap-2 ml-auto cursor-pointer">
  
                <motion.div whileTap={{scale:0.75}} onClick={(e) => dropQty(item?.id)}>
                  <BiMinus className='text-gray-50' />
                </motion.div>
  
                <input className=' w-8 h-5 text-center outline-none rounded-sm bg-cartBg text-gray-50 flex items-center justify-center' value={item?.qty} onChange={(e) => {changeQty(e,item?.id)}} />
  
                <motion.div whileTap={{scale:0.75}}  onClick={(e) => addQty(item?.id)}>
                  <BiPlus className='text-gray-50' />
                </motion.div>
  
              </div>
            
            
            </div>

            )) 
             : (<div className='w-full h-full text-2xl text-red-400 font-semibold flex justify-center items-center'>Cart is Empty</div>)
          }

        
    

        </div>

        {/* Cart Total Section */}

        <div className="w-full flex-1 bg-cartTotal rounded-t-[2rem]  flex flex-col items-center justify-evenly px-8 py-2">

          <div className='w-full flex items-center justify-between'>

              <p className='text-gray-400 text-lg'>Sub Total</p>
              <p className='text-gray-400 text-lg'>{calcPrice()} LKR</p>

          </div>

          

         

        <div className='w-full flex items-center justify-between'>

          <p className='text-gray-400 text-lg'>Discounts</p>
          <p className='text-gray-400 text-lg'>0.00 LKR</p>

        </div>

        <div className='w-full flex items-center text-center justify-center'>

        <p className='text-gray-50 text-sm'><span className='font-semibold text-red-500'>*</span>Delivery Fees will be counted in the <span className='font-semibold text-red-500'>Checkout</span>!</p>
     

        </div>

        <div className='w-full flex flex-col gap-1 items-center justify-center'>

     
          <input className='text-white text-center uppercase placeholder:text-gray-300  w-full p-2 m-1 rounded-lg font-semibold bg-orange-800 text-base border-2 border-yellow-500' placeholder='Enter Coupon Code' type="text" name="" id="" />
          <div className='text-gray-400 text-sm font-semibold justify-end flex w-full'><Link to={'../coupons'}>
            <button className=' hover:text-blue-300 cursor-pointer hidden md:block'>GET COUPON ?</button>
            <button className=' hover:text-blue-300 cursor-pointer block md:hidden' onClick={hideCart}>GET COUPON ?</button>
            </Link></div>

        </div>

        <div className="w-full border-b border-gray-600 my-1"></div>

        <div className="w-full flex items-center justify-between">
          <p className="text-gray-200 text-xl font-semibold">Total</p>
          <p className="text-gray-200 text-xl font-semibold">{calcPrice()} LKR</p>
        </div>

       {

calcPrice() > 0 && cartItems  ? ( <Link  className='w-full' to={'../checkout/'+calcPrice()+'/' + CryptoJS.MD5(JSON.stringify(cartItems)).toString()}><motion.button
        
          whileTap={{scale:0.8}}
          whileHover={{scale:1.01}}
          type="button"
          onClick={hideCart}
          className='w-full items-center justify-center flex flex-row gap-2 font-semibold p-2 rounded-full bg-gradient-to-tr from-orange-400 to-orange-600 hover:to-orange-400 hover:from-orange-600 text-gray-50 text-lg my-2 hover:shadow-lg transition-all duration-150 ease-out'
          >
              Check Out <MdShoppingCartCheckout />
          </motion.button></Link>
  ):( <motion.button
       
    disabled
    whileTap={{scale:0.8}}
    whileHover={{scale:1.01}}
    type="button"
    className='w-full items-center justify-center flex flex-row gap-2 font-semibold p-2 rounded-full bg-gradient-to-tr from-orange-400 to-orange-600 hover:to-orange-400 hover:from-orange-600 text-gray-50 text-lg my-2 hover:shadow-lg transition-all duration-150 ease-out'
    >
         Check Out <MdShoppingCartCheckout />
    </motion.button>
)
       }

        </div>

      </div>

      ) :  (

        

        <div className="w-full h-full flex flex-col items-center justify-center gap-6 overflow-y-auto py-2">

          <img src={EmptyCart} alt="Empty Cart" className='w-300' />
          <p className='text-xl text-textColor font-semibold'>Add Items to The Cart!</p>


        </div>

      )}
    
    </motion.div>
  )
}

export default CartContainer