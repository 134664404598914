import React from 'react';
import {RiEmotionSadLine} from 'react-icons/ri';
import {FaBoxes, FaRegAddressCard, FaSms, FaUsers} from 'react-icons/fa';
import {BiCategoryAlt, BiGitBranch} from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { MdDiscount, MdFastfood, MdNotificationsActive, MdOutlineClose } from 'react-icons/md';
import { useState } from 'react';
import MyData from './MyData';
import { fetchAdmin, fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import E404 from './E404';

const Admin = () => {

    const userRole = fetchRole();

    const admin = fetchAdmin();

    const [isDetails, setIsDetails] = useState(false);
    const [lastLogin, setLastLogin] = useState(0);
  
    const user =  fetchUser();

    

    var admMins= 0;

    if(user){

        const currentDate = new Date();

            // Create a new Date object for the given date and time
            const givenDate = new Date(parseFloat(user.lastLoginAt));

       
    
            const year = givenDate.getFullYear();
            const month = String(givenDate.getMonth() + 1).padStart(2, '0');
            const day = String(givenDate.getDate()).padStart(2, '0');
            const hour = String(givenDate.getHours()).padStart(2, '0');
            const minute = String(givenDate.getMinutes()).padStart(2, '0');
            const second = String(givenDate.getSeconds()).padStart(2, '0');


           setTimeout(() => {
            
                    setLastLogin(`${year}-${month}-${day} | ${hour}:${minute}:${second}`);
           }, 1000);
     

            // Calculate the time difference in milliseconds
            const timeDiff = Math.abs(currentDate.getTime() - givenDate.getTime());

            // Convert the time difference from milliseconds to Minutes
            const minDiff = Math.floor(timeDiff / (1000 * 60 ));

            admMins = minDiff;


       
    }



   


  return (
user && userRole && (userRole === "admin") && (admMins < 1440) ? (


<div className='w-full bg-white h-auto justify-center flex mt-10 scrollbar-track-slate-200 scrollbar-thumb-slate-600  scrollbar-thin scroll-smooth flex-wrap gap-3 overflow-auto p-4 rounded-2xl select-none'>
    
    <div onClick={() => setIsDetails('adminData')} className='min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90 p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50 overflow-y-auto scrollbar-none scrollbar-track-transparent scrollbar-thumb-red-700 cursor-pointer'>
    
        <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><FaRegAddressCard /> My Details</p>


        <table className='w-full'>
        <tbody>
        <tr className='w-full'>
                <th className='p-1'>Role</th>
                <th>:</th>
                <td className='p-1'>{userRole.toUpperCase()}</td>
            </tr>

            <tr className='w-full'>
                <th className='p-1'>Name</th>
                <th>:</th>
                <td className='p-1'>{admin.admName}</td>
            </tr>

            </tbody>

           
        </table>
    
    </div>

    <Link to={'../admin/manageSMS'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
        <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><FaSms /> Manage SMS</p>
        <p className='p-1'>Send, Manage, Recharge SMS</p>
    </Link>

    <Link to={'../admin/notify'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
        <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><MdNotificationsActive /> Notifications</p>
        <p className='p-1'>Notify to Customers / Admins</p>
    </Link>


    <Link to={'../admin/manageBranch'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
    <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><BiGitBranch /> VIP Branches</p>
    <p className='p-1'>Create, Manage, Update Branch Network</p>
    </Link>

    <Link to={'../admin/manageCategory'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
    <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><BiCategoryAlt/> Manage Cat.</p>
    <p className='p-1'>Create, Manage, Update Categories</p>
    </Link>

    <Link to={'../admin/manageItem'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
    <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><MdFastfood />Manage Items</p>
    <p className='p-1'>Create, Manage, Update Food Items</p>
    </Link>

    <Link to={'../admin/manageStock'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
    <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><FaBoxes />Manage Stock</p>
    <p className='p-1'>Create, Manage, Update Stock</p>
    </Link>

    <Link to={'../admin/manageDiscounts'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
    <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><MdDiscount /> Discounts</p>
    <p className='p-1'>Create, Manage, Update Coupons and Discounts</p>
    </Link>


    <Link to={'../admin/systemUsers'} className='w-full min-w-[250px] max-w-[250px] min-h-[150px] max-h-[150px] bg-gradient-to-t from-orange-500 to-yellow-500 hover:opacity-90  p-4 rounded-xl drop-shadow-sm hover:drop-shadow-lg hover:text-slate-50'>
    
    <p className='flex items-center justify-center gap-2 text-xl font-semibold border-b-2 border-b-slate-500 p-1 w-full text-center'><FaUsers/>Manage Users</p>
    <p className='p-1'>Manage Details of Employees and System Users</p>
    </Link>

    {isDetails && (


<div className=' w-full h-full bg-white text-center p-4 fixed bottom-0 z-10 top-20 scrollbar-thin overscroll-auto overflow-auto'>
        
       
       
<div className='w-full min-h-screen h-auto flex justify-center bg-slate-100 opacity-100 rounded-md border-2 border-slate-600 p-3'><span className='absolute flex top-6 text-base md:text-2xl font-semibold items-center gap-2'><FaRegAddressCard /> My Details</span><span className='absolute flex right-6 top-6 text-xl md:text-3xl cursor-pointer hover:text-red-500' onClick={() => setIsDetails(false)}><MdOutlineClose /></span>


 <div className='mt-10 mb-20 w-full'>

    {

        isDetails == 'adminData' ? (<MyData />) : (<>Invalid Request!</>)
    }
   
  </div>
  
  </div>

</div>


    )}

    
   
</div>

): admMins >= 1440 ? (<div className='flex w-full flex-col gap-5 select-none'><div className='mt-10 text-base sm:text-2xl md:text-4xl font-semibold justify-center  text-center text-red-600 w-full flex gap-3'><RiEmotionSadLine /> Admin Session is expired! Please Login Again! </div><div className='w-full flex flex-col justify-center items-center gap-3'><span className='font-semibold text-xl'>Last Login Session</span><span className='text-xs sm:text-lg'>({lastLogin})</span></div></div>) :( <E404 />)
  )
}

export default Admin;