import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, Navigate} from "react-router-dom";
import { motion } from 'framer-motion';
import { MdCloudUpload, MdDelete, MdOutlineFormatAlignJustify, MdSave, MdSignpost, MdPhone, MdKeyboardArrowRight, MdOutlineEmail, MdOutlineAddLocation, MdOutlineClose, MdOutlineWrongLocation, MdOutlineModeEditOutline, MdOutlineSettingsPhone} from 'react-icons/md';
import {FcGoogle} from 'react-icons/fc';
import {FiLink2} from 'react-icons/fi';
import Loader from './Loader';
import {deleteObject, getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import { app, storage } from '../firebase.config';
import {saveCustomer} from '../utils/firebaseFunctions';
import { HiOutlineCake} from 'react-icons/hi2';
import {BiIdCard, BiRename, BiReset} from 'react-icons/bi';

import { BsPen} from 'react-icons/bs';
import DatePicker from "react-datepicker";
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input/input';
import "react-datepicker/dist/react-datepicker.css";
import { GiRotaryPhone } from 'react-icons/gi';
import { fetchCustomer, fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import SetMap from './SetMap';
import ViewMap from './ViewMap';
import LinkPhone from './LinkPhone';
import { getAuth } from 'firebase/auth';
import LinkGmail from './LinkGmail';





const MyAccount = ({pass = undefined}) => {


    const [fullName, setFullName] = useState(null);


    const [gender, setGender] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);


    const [nicNo, setNicNo] = useState(null);
    const [bDate, setBDate] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [homePhoneNumber, setHomePhoneNumber] = useState(null);
    const [officePhoneNumber, setOfficePhoneNumber] = useState(null);
    const [businessPhoneNumber, setBusinessPhoneNumber] = useState(null);
    const [homeAddress, setHomeAddress] = useState(null);
    const [alternativeAddress, setAlternativeAddress] = useState(null);
    const [officeAddress, setOfficeAddress] = useState(null);
    const [businessAddress, setBusinessAddress] = useState(null);

    const [homeAddressPos, setHomeAddressPos] = useState(null);
    const [alternativeAddressPos, setAlternativeAddressPos] = useState(null);
    const [officeAddressPos, setOfficeAddressPos] = useState(null);
    const [businessAddressPos, setBusinessAddressPos] = useState(null);

    const [note, setNote] = useState(null);

    const [joinedDate, setJoinedDate] = useState(null);


    
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);




    const user = fetchUser();
   


    const [mapOpen, setMapOpen] = useState(false);
  
    const [viewLat, setViewLat] = useState(true);
  
    const [viewLng, setViewLng] = useState(true);

    const [phoneVerifyOpen, setPhoneVerifyOpen] = useState(false);
    const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);


  
   


  

  const userRole = fetchRole();
  const customer = fetchCustomer();

  const firebaseAuth = getAuth(app);
  useEffect(() => {clearData();},[]);

  const isGmail = (email) => {
    var gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return gmailRegex.test(email);
  };
  







  const setMapPos = (pass, status = true) => {


    if(pass === 'home'){

      if(status){

      setHomeAddressPos(localStorage.getItem('addrPos'));

      }else{

        setHomeAddressPos(null);
      }
    }
    else if(pass === 'alt'){



      if(status){

        setAlternativeAddressPos(localStorage.getItem('addrPos'));
  
        }else{
  
          setAlternativeAddressPos(null);
        }



    }
    else if(pass === 'office'){


      if(status){

        setOfficeAddressPos(localStorage.getItem('addrPos'));
  
        }else{
  
          setOfficeAddressPos(null);
        }



      
    }

    else if(pass === 'business'){


      if(status){

        setBusinessAddressPos(localStorage.getItem('addrPos'));
  
        }else{
  
          setBusinessAddressPos(null);
        }



      
    }


  }
   

    


    const toCamelCase = (str) => {
        
        return str
          .split(' ')
          .map((word, index) => {
            if (index === -1) {
              return word.toLowerCase();
            } else {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
          })
          .join(' ');
      };
    

  const uploadImage = async (e,to) => {

    
    setIsLoading(true);
    const imageFile = e.target.files[0];

    const resizedFile = await window.resizeImage(imageFile,"WEBP","file",undefined,undefined,10);


    //const imageFile = e.target.files[0];
    const storageRef = ref(storage,`CustomerProfile/${Date.now()}-${resizedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, resizedFile);

    uploadTask.on('state_changed', (snapshot) => {

      const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


    }, (error) => {
      
      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);


    }, () => {

      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL =>{

        console.log(downloadURL);

        if(to == 'profile'){

            setProfilePhoto(downloadURL);
        
        }else{


           //deleteImage(downloadURL);
        }

       
        setIsLoading(false);
        setFields(true);
        setMsg("Image Uploaded Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      });

    });


  };
  const deleteImage = (url, to = undefined) => {

    setIsLoading(true);
    const deleteRef = ref(storage, url);
    deleteObject(deleteRef).then(() => {

        if(to == 'profile'){

            setProfilePhoto(null);
        }
      setIsLoading(false);
      setFields(true);
        setMsg("Image Deleted Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      
    });
  };
  const saveDetails = async () => {

  

    setIsLoading(true);

    try{

      if(( !fullName || !bDate || !phoneNumber || !homeAddress || !homeAddressPos)){


      

        setFields(true);
        setMsg("All Requeired Fields can't be empty!");
        setAlertStatus('danger');
        //clearData();
  
        setTimeout(() => {
  
          setFields(false);
          setIsLoading(false);
          
        }, 4000);

      
  
      }else{


        

        const data = {
          cusId: customer.customerId,
          cusName: fullName,
          cusDp: profilePhoto ? profilePhoto : customer.customerImage ? customer.customerImage : null,
          cusBirthdate: bDate.toString(),
          cusNic: nicNo,
          cusEmail: email,
          cusPhone: phoneNumber,
          cusHomePhone: homePhoneNumber ? homePhoneNumber : null,
          cusOfficePhone: officePhoneNumber,
          cusBusinessPhone: businessPhoneNumber,
          cusHomeAddress: homeAddress,
          cusHomeAddressPosition: homeAddressPos,
          cusAlternativeAddress: alternativeAddress,
          cusAlternativeAddressPosition: alternativeAddressPos,
          cusOfficeAddress: officeAddress,
          cusOfficeAddressPosition: officeAddressPos,
          cusBusinessAddress: businessAddress,
          cusBusinessAddressPosition: businessAddressPos,
          cusGender: gender,
          cusNote: note

        
        };

     


        if(await saveCustomer(data,email,phoneNumber, user.uid, true) == false){

          setIsLoading(false);
          setFields(true);
        
            setMsg("This Phone Number / Email Address is already registered in the system!");
            clearData();
            setAlertStatus("danger");
    
            setTimeout(() => {
              
              setFields(false);
          
            }, 2000);

        }else{

      
        setIsLoading(false);
        setFields(true);
          setMsg("Account Details have been saved Successfully!");
          //clearData();
          setAlertStatus("success");
  
          setTimeout(() => {
            
            setFields(false);
        
          }, 2000);

        }
      }


    }catch (error){

      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);

    }
  };

  const addLocation = (to) =>{


    if(to == 'home'){
      setHomeAddressPos(1);
    }


  }



  





    const clearData = () => {

        setFullName(customer.customerName);
        setGender(customer.customerGender);
        setProfilePhoto("");
        setNicNo(customer.customerNic);
        setEmail(customer.customerEmail);
        setBDate(new Date(customer.customerBirthDate));

        setPhoneNumber(customer.customerPhone);
        setHomePhoneNumber(customer.customerHomePhone);
        setOfficePhoneNumber(customer.customerOfficePhone);
        setBusinessPhoneNumber(customer.customerBusinessPhone);

        setHomeAddress(customer.customerHomeAddress);
        setAlternativeAddress(customer.customerAlternativeAddress);
        setOfficeAddress(customer.customerOfficeAddress);
        setBusinessAddress(customer.customerBusinessAddress);

        setHomeAddressPos(customer.customerHomeAddressPosition); 
        setAlternativeAddressPos(customer.customerAlternativeAddressPosition); 
        setOfficeAddressPos(customer.customerOfficeAddressPosition); 
        setBusinessAddressPos(customer.customerBusinessAddressPosition);


        setJoinedDate(new Date(parseFloat(user.createdAt)));
        setNote(customer.customerNote);


        

        return true;
      };
    



     
  return (

    
    user && customer  ?
    
    ( 

   
        

          <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>

          
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../"}>Home</Link><MdKeyboardArrowRight />My Account</div>
        
        <div className="w-full min-h-screen h-auto flex items-center justify-center mt-1">
        <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center justify-center gap-4 bg-white">
  
    
  
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BiRename className="text-gray-700 text-2xl" />
                        <input type="text" required value={fullName !== null ? fullName : (customer.customerName !== null ? customer.customerName : '')} onChange={(e) => setFullName(toCamelCase(e.target.value)) } placeholder="*Full Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                      </div>
  
                
            </div>

    
            
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
       
  
              <select onChange={(e) => setGender(e.target.value)} className="w-full bg-white outline-none text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer" value={gender !== null ? gender : (customer.customerGender !== null ? customer.customerGender : 'default')} >
                <option disabled value="default" className="bg-white text-gray-400">Gender (optional)</option>
                <option value='male' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Male</option>
                <option value='female' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Female</option>
                <option value='other' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Other</option>
         
              </select>

              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BsPen className="text-gray-700 text-2xl" />
                      <DatePicker disabled selected={joinedDate !== null ? joinedDate: new Date(parseFloat(user.createdAt))}  className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor disabled:text-gray-400"  onChange={(date) => setJoinedDate(date)} />
                      </div>
                      
            </div>

    
  
        
  
            <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                  {isLoading ? <Loader />: (<>
                  
                  {!profilePhoto ? (<>
                  
                  <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">
  
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                      
                      {!customer.customerImage && !profilePhoto ? (<><MdCloudUpload className="text-3xl" /></>): (<><img className='w-[150px] border-2 border-gray-600 rounded-md' src={customer.customerImage} onError={(e) => customer.customerImage = null} alt="Profile Photo" /></>)}
                      <p className="">Upload Profile Picture (Optional)</p>
                    </div>
                    <input type="file" name="uploadimage" accept="image/*" onChange={(e) => uploadImage(e,'profile')} className="w-0 h-0" />
                  </label>
                  
                  </>) : (<>
                  
                    <div className="relative h-full">
                      <img src={profilePhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                      <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={(e) => deleteImage(profilePhoto,'profile')}><MdDelete className="text-white" /></button>
                    </div>
  
                  </>)}
  
                  </>)}
            </div>

        


          
     
                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BiIdCard className="text-gray-700 text-2xl" />
                        <input type="text" required value={nicNo !== null ? nicNo : (customer.customerNic !== null ? customer.customerNic : '')} onChange={(e) => setNicNo(e.target.value) } placeholder="NIC / Driving License /  Passport Number (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={12} />
                      </div>
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <HiOutlineCake className="text-gray-700 text-2xl" />
                      <DatePicker selected={bDate !== null ? bDate: new Date(customer.customerBirthDate)} className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor"  onChange={(date) => setBDate(date)} />
                      </div>
                      
                </div>

                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <MdOutlineEmail className="text-gray-700 text-2xl" />
                        <input type="email" required value={email !== null ? email : (customer.customerEmail !== null ? customer.customerEmail : '')} disabled={window.check_provider(user,'google.com') ? true : false} onChange={(e) => setEmail(e.target.value) } placeholder="e-mail Address" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor disabled:text-gray-400" maxLength={150} />

                        {window.check_provider(user,'google.com')? (<button className='flex flex-row justify-center items-center gap-1 text-green-700 font-semibold' onClick={(e) => {email && isGmail(email) ? setEmailVerifyOpen(true) : setEmailVerifyOpen(false);}}>Linked <FiLink2 /></button>) : <button className='text-red-700 font-semibold' onClick={(e) => {email && isGmail(email) ? setEmailVerifyOpen(true) : setEmailVerifyOpen(false); !isGmail(email) && (window.createNotification('error','Unable to Link Gmail','Please insert a valid Gmail Address!'));}}>Link?</button>}
                      </div>
                  </div>

                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                  
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <MdPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 disabled:text-gray-400 text-textColor${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Phone Number (eg: 0756101993)' disabled={user.phoneNumber === phoneNumber ? true : false}  value={phoneNumber} maxLength={12} 
                        rules={{ required: true }}
                            country="LK"
                            defaultCountry='LK'
                            international
                            
                          
                        onChange={setPhoneNumber} />
                        
                        {user.phoneNumber && user.phoneNumber === phoneNumber ? (<button className='flex flex-row justify-center items-center gap-1 text-green-700 font-semibold' onClick={(e) => {phoneNumber && phoneNumber.length > 10 ? setPhoneVerifyOpen(true) : setPhoneVerifyOpen(false);}}>Linked <FiLink2 /></button>) : <button className='text-red-700 font-semibold' onClick={(e) => {phoneNumber && phoneNumber.length > 10 ? setPhoneVerifyOpen(true) : setPhoneVerifyOpen(false); !(phoneNumber && phoneNumber.length > 10) && window.createNotification('error','Unable to Link Phone Number','Please insert a valid Phone Number!');}}>Link?</button>}
                      </div>

                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <GiRotaryPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${homePhoneNumber && isValidPhoneNumber(homePhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Home Phone Number (Optional)' value={homePhoneNumber} maxLength={12} 
                        rules={{ required: true }}
                        country="LK"
                        defaultCountry='LK'
                        international
                      
                        onChange={setHomePhoneNumber} />
                      </div>

                      
                </div>

              

                <div className="w-full flex flex-col md:flex-row items-center gap-3">

                <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <GiRotaryPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${officePhoneNumber && isValidPhoneNumber(officePhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Office Phone Number (Optional)' value={officePhoneNumber} maxLength={12} 
                        rules={{ required: true }}
                        country="LK"
                        defaultCountry='LK'
                        international
                      
                        onChange={setOfficePhoneNumber} />
                      </div>


                  
                  <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <GiRotaryPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${businessPhoneNumber && isValidPhoneNumber(businessPhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Business Phone Number (Optional)' value={businessPhoneNumber} maxLength={12} 
                        rules={{ required: true }}
                        country="LK"
                        defaultCountry='LK'
                        international
                      
                        onChange={setBusinessPhoneNumber} />
                      </div>

                    </div>

                
                <div className="w-full flex flex-col md:flex-row items-center gap-3">

                  
              
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdSignpost className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={homeAddress !== null ? homeAddress : (customer.customerHomeAddress !== null ? customer.customerHomeAddress : '')} onChange={(e) => setHomeAddress(e.target.value) } placeholder="Home Address" className="w-full h-[155px] text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>

                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">

                        {homeAddressPos ? (<div className='w-full flex flex-col items-center justify-center gap-3'>


                       

                          <div className='w-full h-[100px] overflow-hidden border-2 border-green-700' onContextMenu={(e) => {setMapOpen('home');setViewLat((JSON.parse(homeAddressPos)).lat); setViewLng((JSON.parse(homeAddressPos)).lng);}}>
                            <ViewMap lat={(JSON.parse(homeAddressPos)).lat} lng={(JSON.parse(homeAddressPos)).lng} />
                          </div>

                          <div>
                          <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-orange-500 hover:bg-orange-700 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => {setMapOpen('home');setViewLat((JSON.parse(homeAddressPos)).lat); setViewLng((JSON.parse(homeAddressPos)).lng);}}>Edit <MdOutlineModeEditOutline /></button>
                          </div>

                
                        
                        </div>) : (<div className='w-full items-center justify-center flex'>
                        
                                
                            <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-800 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => {setMapOpen('home')}}>Must Add Loc. <MdOutlineAddLocation /></button>
                        </div>)}

                      
                        
                      </div>
  
                    
                </div>

                <div className="w-full flex flex-col md:flex-row items-center gap-3">

                  
              
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdSignpost className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={alternativeAddress !== null ? alternativeAddress: (customer.customerAlternativeAddress !== null ? customer.customerAlternativeAddress : '')} onChange={(e) => setAlternativeAddress(e.target.value) } placeholder="Alternative Address (Optional)" className="w-full h-[155px] text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>

                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">

                        {alternativeAddressPos ? (<div className='w-full flex flex-col items-center justify-center gap-3'>


                          <div className='w-full h-[100px] overflow-hidden border-2 border-green-700' onContextMenu={(e) => {setMapOpen('alt');setViewLat((JSON.parse(alternativeAddressPos)).lat); setViewLng((JSON.parse(alternativeAddressPos)).lng);}}>
                            <ViewMap lat={(JSON.parse(alternativeAddressPos)).lat} lng={(JSON.parse(alternativeAddressPos)).lng} />
                          </div>

                          <div>
                          <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-orange-500 hover:bg-orange-700 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => {setMapOpen('alt');setViewLat((JSON.parse(alternativeAddressPos)).lat); setViewLng((JSON.parse(alternativeAddressPos)).lng);}}>Edit <MdOutlineModeEditOutline /></button>
                          </div>

                        
                        
                        </div>) : (<div className='w-full items-center justify-center flex'>
                        
                                
                            <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-green-600 px-12 py-2 rounded-lg text-lg text-white font-semibold"  onClick={(e) => {setMapOpen('alt')}}>Add Location <MdOutlineAddLocation /></button>
                        </div>)}

                      
                        
                      </div>
  
                    
                </div>


                <div className="w-full flex flex-col md:flex-row items-center gap-3">

                  
              
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdSignpost className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={officeAddress !== null ? officeAddress : (customer.customerOfficeAddress !== null ? customer.customerOfficeAddress : '')} onChange={(e) => setOfficeAddress(e.target.value) } placeholder="Office Address (Optional)" className="w-full h-[155px]  text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>

                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">

                        {officeAddressPos ? (<div className='w-full flex flex-col items-center justify-center gap-3'>


                          <div className='w-full h-[100px] overflow-hidden border-2 border-green-700' onContextMenu={(e) => {setMapOpen('office');setViewLat((JSON.parse(officeAddressPos)).lat); setViewLng((JSON.parse(officeAddressPos)).lng);}}>
                            <ViewMap lat={(JSON.parse(officeAddressPos)).lat} lng={(JSON.parse(officeAddressPos)).lng} />
                          </div>

                          <div>
                          <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-orange-500 hover:bg-orange-700 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => {setMapOpen('office');setViewLat((JSON.parse(officeAddressPos)).lat); setViewLng((JSON.parse(officeAddressPos)).lng);}}>Edit <MdOutlineModeEditOutline /></button>
                          </div>

                        
                        
                        </div>) : (<div className='w-full items-center justify-center flex'>
                        
                                
                            <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-green-500 px-12 py-2 rounded-lg text-lg text-white font-semibold"  onClick={(e) => {setMapOpen('office')}}>Add Location <MdOutlineAddLocation /></button>
                        </div>)}

                      
                        
                      </div>
  
                    
                </div>



                <div className="w-full flex flex-col md:flex-row items-center gap-3">

                  
              
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdSignpost className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={businessAddress !== null ? businessAddress : (customer.customerBusinessAddress !== null ? customer.customerBusinessAddress : '')} onChange={(e) => setBusinessAddress(e.target.value) } placeholder="Business Address (Optional)" className="w-full h-[155px]  text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>

                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">

                        {businessAddressPos ? (<div className='w-full flex flex-col items-center justify-center gap-3'>


                          <div className='w-full h-[100px] overflow-hidden border-2 border-green-700' onContextMenu={(e) => {setMapOpen('business');setViewLat((JSON.parse(businessAddressPos)).lat); setViewLng((JSON.parse(businessAddressPos)).lng);}}>
                            <ViewMap lat={(JSON.parse(businessAddressPos)).lat} lng={(JSON.parse(businessAddressPos)).lng} />
                          </div>

                          <div>
                          <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-orange-500 hover:bg-orange-700 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => {setMapOpen('business');setViewLat((JSON.parse(businessAddressPos)).lat); setViewLng((JSON.parse(businessAddressPos)).lng);}}>Edit <MdOutlineModeEditOutline /></button>
                          </div>

                        
                        
                        </div>) : (<div className='w-full items-center justify-center flex'>
                        
                                
                            <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-green-600 px-12 py-2 rounded-lg text-lg text-white font-semibold"  onClick={(e) => {setMapOpen('business')}}>Add Location <MdOutlineAddLocation /></button>
                        </div>)}

                      
                        
                      </div>
  
                    
                </div>
            

                

                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={note !== null ? note : (customer.customerNote !== null ? customer.customerNote : '')} onChange={(e) => setNote(e.target.value) } placeholder="Note about you (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>
  
                    
                </div>
  
                {
              fields && (
  
                <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                  {msg}
                </motion.p>
              )
            }
                
  
                    <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                    <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Reset <BiReset /></button>
                      <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={saveDetails}>Save <MdSave /></button>
                    </div>
        </div>

        {mapOpen && viewLat && viewLng && (


<div className=' w-full h-full bg-white text-center p-4 fixed bottom-0 z-10 top-20 scrollbar-thin overscroll-auto overflow-auto'>
        

       
<div className='w-full overflow-auto scrollbar-thin min-h-screen h-auto flex flex-col items-center justify-center bg-slate-100 opacity-100 rounded-md border-2 border-slate-600 p-3'><span className='absolute flex top-6 text-base md:text-2xl font-semibold items-center gap-2'>Add Location : {mapOpen === 'home' ? (<>for Home</>) : mapOpen === 'alt' ?(<>for an Alternative Place</>) : mapOpen === 'office' ? (<>for the Office</>) : mapOpen === 'business' ? (<>for the Business</>) : (<>Not Defined</>)}<MdOutlineAddLocation /></span><span className='absolute flex right-6 top-6 text-xl md:text-3xl cursor-pointer hover:text-red-500' onClick={() => setMapOpen(false)}><MdOutlineClose /></span>


<div className='my-4 flex flex-col md:flex-row gap-4 w-full justify-center'>
<button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-green-600 px-12 py-2 rounded-lg text-lg text-white font-semibold"  onClick={(e) => {setMapPos(mapOpen)}}>Set Location <MdOutlineAddLocation /></button>

<button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-600 px-12 py-2 rounded-lg text-lg text-white font-semibold"  onClick={(e) => {setMapPos(mapOpen,false)}}>Unset Location <MdOutlineWrongLocation /></button>
  </div>

 <div className='overflow-auto'>
  <SetMap pass={mapOpen} latV={viewLat === true ? null : viewLat} lngV={viewLng === true ? null : viewLng} />
  </div>


  
  </div>

</div>



        )}


        {phoneVerifyOpen  && (


<div className=' w-full h-[100%] bg-white text-center p-4 fixed bottom-0 z-10 top-20 scrollbar-thin overscroll-auto overflow-auto'>
        

       
        <div className='w-full overflow-auto scrollbar-thin min-h-screen h-auto flex flex-col items-center  bg-slate-100 opacity-100 rounded-md border-2 border-slate-600 p-3'><span className='absolute flex top-6 text-base md:text-2xl font-semibold items-center gap-2'><MdOutlineSettingsPhone /> Link Phone Number</span><span className='absolute flex right-6 top-6 text-xl md:text-3xl cursor-pointer hover:text-red-500' onClick={() => setPhoneVerifyOpen(false)}><MdOutlineClose /></span>
        
        
  
        
         <div className='overflow-auto flex  mt-20 justify-start w-full'>
          <LinkPhone pNumber={phoneNumber} />
          </div>
        
        
          
          </div>
        
        </div>

        )

        }


{emailVerifyOpen && (


<div className=' w-full h-[100%] bg-white text-center p-4 fixed bottom-0 z-10 top-20 scrollbar-thin overscroll-auto overflow-auto'>
        

       
        <div className='w-full overflow-auto scrollbar-thin min-h-screen h-auto flex flex-col items-center  bg-slate-100 opacity-100 rounded-md border-2 border-slate-600 p-3'><span className='absolute flex top-6 text-base md:text-2xl font-semibold items-center gap-2'><FcGoogle /> Link Gmail Account</span><span className='absolute flex right-6 top-6 text-xl md:text-3xl cursor-pointer hover:text-red-500' onClick={() => setEmailVerifyOpen(false)}><MdOutlineClose /></span>
        
        
  
        
         <div className='overflow-auto flex  mt-20 justify-start w-full'>

          <LinkGmail gMail={email} />
       
          </div>
        
        
          
          </div>
        
        </div>

        )

        }
       
      </div> </div>
      ) : (
        
        (<Navigate to="/" />)
      
      )
    

    
    )
    
   
}

export default MyAccount