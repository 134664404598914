import React, {useEffect, useState } from 'react';
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import E404 from './E404';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight, MdNotificationAdd } from 'react-icons/md';
import {AiOutlineReload} from 'react-icons/ai';
import { sendMessage } from '../utils/socketFunc';
import { useNavigate} from 'react-router-dom';

const SendNotification = () => {

    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [mode, setMode] = useState("");
    const [pushStatus, setPushStatus] = useState(false);
    const user =  fetchUser();
    const history = useNavigate();
   

  const userRole = fetchRole();

  const sender_id = localStorage.getItem("ex_id");

  const sendMsg = sendMessage;



 useEffect(() => {

  const pushGet = localStorage.getItem('isPushNotificationsEnabled');
  console.log("Is One Enabled : " + pushGet);
  localStorage.removeItem("onesignal-notification-prompt");

  if(pushGet){

    setPushStatus(pushGet);
    
  }else{

    setPushStatus(false);
  
  }

  console.log("Is One Enabled 2: " + pushGet);
   

 }, []);
 


 
  return (

    user && userRole && (userRole === "admin") ? (
    
    
         <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
        <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /> Send Notifications</div> 



        <div className='w-full mt-7 bg-slate-100 rounded-lg drop-shadow-lg  px-2 py-5'>

            <div className='w-full flex flex-col justify-center items-center gap-4'>
                <span className='font-semibold text-xl text-red-500 underline-offset-1'>Send Notification (BETA)</span>

                <span className='font-semibold text-md text-blue-800'>Sender ID: {sender_id}</span>

                <span className='font-semibold text-md flex flex-row gap-2 justify-center items-center'>[ Status : {pushStatus === 'true' ? (<span className='text-green-600'>Active</span>) : (<span className=' text-red-500 flex flex-row justify-center items-center gap-2'>Inactive <AiOutlineReload className=' cursor-pointer' onClick={(e) => {history(0)}} /></span>)} ]</span>

                <select className='w-full md:w-[500px] outline outline-2 outline-gray-500 rounded-md p-4 cursor-pointer' name="" id="" onChange={(e) => {setMode(e.target.value)}} value={mode}>
                  <option value="" disabled>Select Audience</option>
                  <option value="callback">Yourself</option>
                  <option value="all">All Subscribers</option>
                  <option value="admins">All Admins</option>
                  <option value="customers">All Customers</option>
                  <option value="spec">Specific Person</option>
                </select>

            <input  className='w-full md:w-[500px] outline outline-2 outline-gray-500 rounded-md p-4' type="text" name="" id="" placeholder='Message Title' value={title} onChange={(e) => setTitle(e.target.value)}/>
                <input  className='w-full md:w-[500px] outline outline-2 outline-gray-500 rounded-md p-4' type="text" name="" id="" placeholder='Enter your message...' value={message} onChange={(e) => setMessage(e.target.value)}/>
                <button className='bg-blue-500 text-white text-xl hover:bg-blue-800 p-2 rounded-md font-semibold flex flex-row gap-2 items-center justify-center' onClick={(e) => {sendMsg(title,message,mode)}}>Send <MdNotificationAdd /></button>
            </div>

        </div>
        
        
        
        </div>

   
    
        
    
    
    ) : (<div className='w-full'><E404 /></div>)

  )
}

export default SendNotification