import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import {GoogleMap, useJsApiLoader, Marker, InfoWindow, DirectionsService, DirectionsRenderer} from '@react-google-maps/api';
import pinrest from '../img/pinrest.png';
import pinhome from '../img/pinhome.png';
import { useStateValue } from '../context/StateProvider';
import { FcShop } from 'react-icons/fc';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { saveCustomer } from '../utils/firebaseFunctions';
import { fetchCustomer } from '../utils/fetchLocalStorageData';




  

const Map = () => {

  const history = useNavigate();
  //AIzaSyB3oP4tYA04fcICAntjIidCe3GEbp2JU3A
  const google = window.google;
  const {isLoaded} = useJsApiLoader({

    googleMapsApiKey: "AIzaSyB3oP4tYA04fcICAntjIidCe3GEbp2JU3A",


  });

  const containerStyle = {
    width: '80vw',
    height: '80vh'
  };
  
  const setMyBranch = (branch) => {

    const customer = fetchCustomer();

    if(!customer){

      window.createNotification("error","Login First","Please login first before set the branch!",3000)
      return 0;
    }

    if(localStorage.getItem('ffkey')){

      saveCustomer({cusBranch: branch.id},customer.customerEmail,customer.customerPhone, customer.customerId, true,true);

    localStorage.setItem('userBranch',CryptoJS.AES.encrypt(JSON.stringify(branch),localStorage.getItem('ffkey')).toString());
    window.createNotification("success","Successfully Set","Your branch has been set!", 2000);
    localStorage.removeItem('cartItems');
    }else{
      window.createNotification("error","Unable to Set","Please login to the system to proceed this!", 5000);
    }
  };


  const [map, setMap] = useState(null);
  const [showInfo, setShowInfo] = useState(true);
  const [marLat, setMarLat] = useState(null);
  const [marLng, setMarLng] = useState(null);

  const center = {
    lat: marLat,
    lng: marLng
  };


  const markers = [

    {
      name:"shop_loc",
      status: "pinrest",
      tag: "VIP Hotels and Restaurants",
      location : {
        lat: 7.8583505,
        lng: 79.3869161
          
      },
      draggable:false,
    },

    {
      name:"shop_loc_2",
      status: "pinhome",
      tag: "Customer",
      location : {
        lat: marLat,
        lng: marLng
          
      },
    draggable:true,
   
    
    }


  ];
  
 
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
  
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [response, setResponse] = useState(null);

  const [distance, setDistance] = useState('');

  const [deliveryCharge, setDeliveryCharge] = useState('');

  const [duration, setDuration] = useState('');
  const [{vipBranches}, dispatch] = useStateValue();
  const [branch, setBranch] = useState("");

  var destination;
 

  const calculateRoute = async (lat,lng) => {

    const directionsService = new google.maps.DirectionsService();
    const origin = { lat: parseFloat(JSON.parse(branch).lat),
      lng: parseFloat(JSON.parse(branch).lng)
};

  

    const results = await directionsService.route({

      origin: origin,
     

     
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING

    });
//.routes[0].legs[0].distance.text
//.routes[0].legs[0].duration.text
    if((parseFloat(results.routes[0].legs[0].distance.value))/1000 > parseFloat(JSON.parse(branch).maxKm)){

     
        window.createNotification('error','Out of Range',"Sorry! We do not deliver over "+parseFloat(JSON.parse(branch).maxKm)+" km s from the "+ JSON.parse(branch).location +" Branch",5000);
        setDistance(null);
        setDeliveryCharge(null);
        return false;

    }else{

      setDistance(results.routes[0].legs[0].distance.text);
      setDeliveryCharge(parseFloat(JSON.parse(branch).kmCharge) * Math.ceil((((results.routes[0].legs[0].distance.value))/1000)))
      setResponse(results);
      setMarLat(lat);
      setMarLng(lng);
      return true;
    }
   

 


  }

  const onMarkerDragEnd = async (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();

  
    const des = { lat: lat, lng: lng};
    
    destination = des;
  
    const load =  await calculateRoute(lat,lng);
 
   
  };



 


  return isLoaded ? (
    <div><div className='items-center text-center justify-center sm:justify-between w-full mt-5 select-none'>
    <div className='w-full text-left flex flex-row items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base sm:text-lg mb-10 select-none'><span onClick={(e) => history(-1)} className='text-blue-600 hover:text-blue-500 cursor-pointer' >Back</span><MdKeyboardArrowLeft />Branches (Map)</div>
    </div>
    <div className='w-full flex flex-col items-center justify-center text-center border-2 gap-2 rounded-lg broder-slate-400  bg-slate-50'>

        
       <div className='flex flex-col text-center justify-center items-center'>
       <div className='mt-5 text-red-600 font-semibold text-base sm:text-xl md:text-2xl relative before:absolute before:rounded-lg before:content before:w-[70%] before:h-1 before:-bottom-2 before:bg-gradient-to-tr from-red-400 to-red-600 transition-all ease-in-out duration-100 mr-auto select-none'>
          Our Branches and Delivery Charges
        </div>
       </div>
        
        <div className="w-full sm:w-[300px] mt-8 select-none">
            <select onChange={(e) => setBranch(e.target.value)} className="w-full bg-white outline-none text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer" value={!branch && ("default") || branch && (branch)} >
              <option disabled value="default" className="bg-white text-gray-400">Please Select a Branch</option>
              {vipBranches && vipBranches.map(item => (
                <option value={JSON.stringify(item)} key={item.id} className="text-base border-0 outline-none capitalize bg-white text-headingColor">{item.location}</option>
              ))}
            </select>
          </div>

          {branch && ( 



<div className="mt-10 w-full justify-center text-center flex flex-col">  

<div className="flex flex-col justify-center items-center mb-5 select-none"><button className='flex flex-row justify-center items-center gap-2 rounded-lg bg-yellow-500 font-semibold text-xl p-3 hover:drop-shadow-lg hover:bg-yellow-600'  onClick={(e) => setMyBranch(JSON.parse(branch))}><FcShop /> Set Branch</button>
<span className='p-1 text-xs font-semibold text-red-500 italic'>* Changing Branch will affect to the cart and the checkout process!</span>
</div>
<div className='border-2 border-gray-500 rounded-md flex flex-col bg-slate-100 text-base font-light mx-10 p-4 whitespace-pre-wrap'>
<span className='font-semibold font-sans text-lg text-orange-700'>Address:</span>
<div>{JSON.parse(branch).address.split("\n").map(function(item, idx) {
        return (
            <span key={idx}>
                {item}
                <br/>
            </span>
         )
    })}</div>

<span className='font-semibold font-sans text-lg text-orange-700 mt-3'>Hotline:</span>
<div><a href={'tel:' + JSON.parse(branch).phoneNumber}>{JSON.parse(branch).phoneNumber}</a></div>
</div>



<div className='mt-10 w-full flex flex-col justify-center items-center text-center border-t-2 border-gray-600 p-4'>
<div className='text-base italic text-red-600 p-4 font-semibold border-b-2 border-dotted border-red-600 mb-4 select-none'>( Please double click or double tap/touch on your delivery location to calculate the delivery charges. )</div>
   <GoogleMap
   onDblClick={(e) => {onMarkerDragEnd(e)}}
      mapContainerStyle={containerStyle}
      center={{lat: parseFloat(JSON.parse(branch).lat),
        lng: parseFloat(JSON.parse(branch).lng)

      }
      }
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{streetViewControl: false, fullscreenControl: false}}
      clickable
      
    >

   
            <div >
              <Marker
               clickable
               draggable={false}
               
              position={{lat: parseFloat(JSON.parse(branch).lat),
                lng: parseFloat(JSON.parse(branch).lng)
        
              }
              }
              icon={{


                url:pinrest,
                anchor: new window.google.maps.Point(40, 90),

                scaledSize: new window.google.maps.Size(80, 80)
              }}

              onDragEnd={(e) => {onMarkerDragEnd(e)}}
              
              >

 
             {/*distance && ( 
              
              

                  <InfoWindow
                  position={{lat: parseFloat(JSON.parse(branch).lat),
                    lng: parseFloat(JSON.parse(branch).lng)
            
                  }}

                  onCloseClick={() => setShowInfo(false)}
                      

                  >
                  <div className='p-3 bg-yellow-600 rounded-xl font-semibold text-white text-base md:text-lg'>
                    <p>{distance}</p>
                  </div>
                  </InfoWindow>
                )*/}
              </Marker>

              <Marker
              zIndex={20}
               clickable
               draggable={true}
               
              position={{lat: parseFloat(marLat),
                lng: parseFloat(marLng)
        
              }
              }
              icon={{


                url:pinhome,
                anchor: new window.google.maps.Point(40, 90),

                scaledSize: new window.google.maps.Size(80, 80)
              }}

              onDragEnd={(e) => {onMarkerDragEnd(e)}}
              
              >

 
             
              </Marker>
            </div>

     

       

{response !== null && (
        <DirectionsRenderer
          directions={response}
        />
      )}


  
      
    </GoogleMap>
    </div>

   { distance && (

        <div className="w-full justify-center items-center font-semibold text-lg bg-slate-100 mt-2 mb-[200px] border-t-2 border-gray-600 p-4"> 
        <div>
        <div className='text-red-500'>Distance:</div>
        <div>{distance}</div>
        </div>

        <div className='mt-5'>
        <div className='text-red-500'>Estimated Delivery Charges:<br /><i>(LKR {JSON.parse(branch).kmCharge} per 1 km)</i></div>
        <div>LKR {deliveryCharge} /=</div>
        </div>
        
        
        </div>
   )}
   </div>



          )}
   
    </div></div>
) : <div className='w-full text-center justify-center items-center font-semibold text-base sm:text-xl md:text-2xl text-red-600'>Loading..</div>
}

export default Map;