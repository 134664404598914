import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import {LoaderCustom} from './LoaderCustom';
import { Link, Navigate, useNavigate, useParams} from "react-router-dom";
import { useStateValue } from '../context/StateProvider';
import { motion } from 'framer-motion';
import {MdFastfood, MdCloudUpload, MdDelete, MdFoodBank, MdOutlineFormatAlignJustify, MdSave, MdOutlineDeleteOutline, MdAddBox, MdKeyboardArrowRight} from 'react-icons/md';
import Loader from './Loader';
import {deleteObject, getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import Currency from 'react-currency-icons';
import { storage } from '../firebase.config';
import { getAllFoodItems, saveItem, getAllFoodCategories, deleteItem } from '../utils/firebaseFunctions';
import { actionType } from '../context/reducer';
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import Select,{ OnChangeValue} from 'react-select';
import makeAnimated from 'react-select/animated';
import {useTable} from 'react-table';





export const ManageItem  = ({pass = undefined}) => {


    const {itemId} = useParams();
    const currentCat = useRef(null);
  

  const history = useNavigate();

  const [title, setTitle] = useState("");
  const [calories, setCalories] = useState(""); 
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState([]);
  const [options, setOptions] = useState([]);
  const [imageAsset, setImageAsset] = useState(null); 
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultCat, setDefaultCat] = useState([]);
  const [{foodCategories, foodItems}, dispatch] = useStateValue();

  const data = foodItems ? foodItems : [{}];
  const currentURL = window.location.href;

  useEffect(() => {

    if(pass === 'new'){

      clearData();
    }

    if(pass === 'manage'){

      fetchData();
    }

    setCategory([]);

   
    
  }, [currentURL]);
  

  const validateData = async () => {

   


    if(itemId && foodItems && pass === 'solo'){



      const specItem = foodItems.find(item => item.id === itemId);

    if(specItem){


      setTitle(specItem.title);
      setImageAsset(specItem.imageURL);
      setDescription(specItem.description);
      setCalories(specItem.calories);
      setPrice(specItem.price)
     // setOptions(specItem.category);

      const cat = [];



     for (let i = 0; i < specItem.category.length; i++){
 
       cat.push({value : specItem.category[i], label: specItem.category[i].toString().toUpperCase()})
     }


 


    
    setDefaultCat(cat);
    if(category.length <= 0 ){
      setCategory(specItem.category);
    }


    

   

     

     // currentCat.Select.defaultValue = specItem.category;



    }else{
      console.log("No Specified Item!");
  
    }
    }else{

      console.log("opi");
   
      //validateData();
 
    }
  };

  useEffect(() => {

    

    validateData();
    


    
    
  }, [itemId, data]);


  




  const columns = useMemo(() => [
    {

      Header : "ID",
      accessor: "id"
    },  

    {

      Header : "TITLE",
      accessor: "title"
    },  

    {

      Header : "ITEM",
      id: 'image',
      accessor: "imageURL"
    }
  ],[]);

  const renderImageCell = (cellProps) => {
    const value = cellProps.value;


    return <div className='w-[100px] md:w-full flex flex-col items-center justify-center'><img className='w-30 h-20' src={value} alt="Image" /></div>;
  };


  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});


  const animatedComponents = makeAnimated();

  const handleSelect =(selectedItems) => {

   
    const cat = [];
    const catVal = [];




    for (let i = 0; i < selectedItems.length; i++){

      cat.push(selectedItems[i].value)
      catVal.push({value :  selectedItems[i].value, label: selectedItems[i].value.toString().toUpperCase()})
    }

    setCategory(cat);

    setDefaultCat(catVal);

    
};


  const user =  fetchUser();
 

  const userRole = fetchRole();

  useEffect(() => {

    const cat = [];
    foodCategories && foodCategories.map(item => (

      
   
        cat.push({ value: item.shortCode, label: item.title })

      
      ));

      
      setOptions(cat);
 


  }, [foodCategories])
  
 

  const uploadImage = async (e) => {
    
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const resizedFile = await window.resizeImage(imageFile,"WEBP","file",undefined,undefined,10);

    const storageRef = ref(storage,`Images/${Date.now()}-${resizedFile .name}`);
    const uploadTask = uploadBytesResumable(storageRef, resizedFile);

    uploadTask.on('state_changed', (snapshot) => {

      const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


    }, (error) => {
      
      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);


    }, () => {

      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL =>{

        setImageAsset(downloadURL);
        setIsLoading(false);
        setFields(true);
        setMsg("Image Uploaded Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      });

    });


  };
  const deleteImage = () => {

    setIsLoading(true);
    const deleteRef = ref(storage, imageAsset);
    deleteObject(deleteRef).then(() => {

      setImageAsset(null);
      setIsLoading(false);
      setFields(true);
        setMsg("Image Deleted Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      
    });
  };

  const deleteCurrentItem = () => {


    deleteItem(itemId);
    deleteImage();

    window.createNotification("success","Item has been deleted!","",3000);

    history(-1);


    
  }

  const saveDetails = (mode = 'save') => {

   
    setIsLoading(true);



    try{


  

      if((!title || !calories || !imageAsset || !price || !category || category.length <= 0 || !description)){

        setFields(true);
        setMsg("All Requeired Fields can't be empty!");
        setAlertStatus('danger');
        //clearData();
  
        setTimeout(() => {
  
          setFields(false);
          setIsLoading(false);
          
        }, 4000);

        fetchData();
  
      }else{

        let id = `${Date.now()}`;
        
        if(mode === 'update'){

          id = itemId;


        }

        const data = {
          id: id,
          title: title,
          imageURL: imageAsset,
          category: category,
          calories : calories,
          description : description,
          qty : 1,
          price: price
        }
        saveItem(id,data);

      
        setIsLoading(false);
        setFields(true);
          setMsg("Item has been saved Successfully!");
          if(mode === 'update'){
            clearData(mode);
          }else{
            clearData();
          }
          setAlertStatus("success");
  
          setTimeout(() => {
            
            setFields(false);
        
          }, 2000);

      }


    }catch (error){

      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);

    }
  };

  const clearData = (mode = 'save') => {

 if(mode === 'update'){




 }else{

  setTitle("");
  setImageAsset(null);
  setCalories("");
  setPrice("");
  setDescription("");
  setCategory([]);
 }
   
  };

  
  const fetchData = async () => {

    await getAllFoodItems().then(data => {
      dispatch({
        type: actionType.SET_FOOD_ITEMS,
        foodItems : data
      });
    });
  };



  return ( 

    user && userRole && (userRole === "admin") ? (

      

      pass === "new" ? (
        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageItem"}>Manage Items</Link><MdKeyboardArrowRight />Create New Item</div>
    <div className="w-full  flex  justify-center mt-6">
      
      <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col  gap-4 bg-white">

       

          {
            fields && (

              <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                {msg}
              </motion.p>
            )
          }

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdFastfood className="text-xl text-gray-700" />
            <input type="text" onChange={(e) => setTitle(e.target.value)} required value={title} placeholder="Enter The Title" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full">
            
            
          
            {foodCategories && (

              <Select
              
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder="Select Food Category / Categories"
              isMulti
              onChange={(e) => handleSelect(e)}
              options={options} 
              />
            )}
          </div>

          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 md:h-420 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!imageAsset ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Click Here to Upload</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*" onChange={uploadImage} className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={imageAsset} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={deleteImage}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>

                    
                    
                  </div>


                  <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <MdFoodBank className="text-gray-700 text-2xl" />
                      <input type="text" required value={calories} onChange={(e) => setCalories(e.target.value) } placeholder="Calories" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <Currency code="LKR (රු.)" size="small" className="text-gray-700 text-2xl" />
                      <input type="text" required value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
                  </div>

                  <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={saveDetails}>Save <MdSave /></button>
                  </div>
      </div>
    </div></div>):  pass === "solo" ? (
    
    <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageItem"}>Manage Items</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageItem/manage"}>Manage All Items</Link><MdKeyboardArrowRight />{itemId}</div>
    <div className="w-full  flex  justify-center mt-6">
      
      <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col  gap-4 bg-white">

       

          {
            fields && (

              <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                {msg}
              </motion.p>
            )
          }

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdFastfood className="text-xl text-gray-700" />
            <input type="text" onChange={(e) => setTitle(e.target.value)} required value={title} placeholder="Enter The Title" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
          </div>

          <div className="w-full">
            
            
          
            {
              
              foodCategories && defaultCat && (

                


                <Select

                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="Select Food Category / Categories"
                isMulti
                onChange={(e) => handleSelect(e)}
                ref={currentCat}
                defaultValue={defaultCat}
                value={defaultCat}
                options={options} 
                
                />
            
              )
            }
          </div>

          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 md:h-420 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!imageAsset ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Click Here to Upload</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*" onChange={uploadImage} className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={imageAsset} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={deleteImage}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>

                    
                    
                  </div>


                  <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <MdFoodBank className="text-gray-700 text-2xl" />
                      <input type="text" required value={calories} onChange={(e) => setCalories(e.target.value) } placeholder="Calories" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <Currency code="LKR (රු.)" size="small" className="text-gray-700 text-2xl" />
                      <input type="text" required value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
                  </div>

                  <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={deleteCurrentItem}>Delete<MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => saveDetails('update')}>Save <MdSave /></button>
                  </div>
      </div>
    </div></div>) :  pass === "manage" ? (

      /* Manage Items */

      <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
      <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageItem"}>Manage Items</Link><MdKeyboardArrowRight />Manage All Items</div>
      <div className="w-full flex flex-auto justify-center items-center mt-6 overflow-auto">


<div className='w-full  flex overflow-auto scrollbar-thin scrollbar-thumb-slate-400 bg-slate-200 shadow-lg rounded-lg'>

            {
              foodItems ? (
                <table className='w-full' {...getTableProps()}>

              <thead>

              {headerGroups.map((headerGroup) => (

                  <tr className='bg-white hover:bg-slate-50 font-semibold text-xl' {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map((column) => (

                      <th className='py-4' {...column.getHeaderProps()}>

                        {column.render("Header")}

                      </th>
                    ))}

                  </tr>

              ))}

              </thead>

              <tbody className='hover:bg-slate-300' {...getTableBodyProps()}>

                      {
                        rows.map((row) => {

                          prepareRow(row)

                          return (


                            <tr className='text-lg hover:bg-slate-400 border-t-2 border-white hover:text-slate-50 hover:font-semibold cursor-pointer' {...row.getRowProps()} onClick={(e) => {history(row.cells[0].value)}}>

                              {row.cells.map((cell) => (
                              
                                <td className='py-4 px-3' {...cell.getCellProps()}>
                                 {cell.column.id === 'image' ? (
                                    renderImageCell(cell)
                                  ) : (
                                    cell.render('Cell')
                                  )}  
                                 
                                </td>
                               
                              ))}
 
                            </tr>
                          )

                        })
                      }

              </tbody>


            </table>
              ) : (<LoaderCustom />)
            }

          </div>



            </div>

    </div>
    
    
    
    ):(
      <div className='text-center py-16 w-full flex flex-col gap-8 justify-center items-center m-auto select-none'> 

      <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight />Manage Items</div>
      
      <Link to={"../admin/manageItem/new"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-emerald-500 p-3 rounded-lg hover:bg-emerald-400 gap-2 w-full'>Create Item <MdAddBox /></button>
      </Link> 

      <Link to={"../admin/manageItem/manage"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-blue-500 p-3 rounded-lg hover:bg-blue-400 gap-2 w-full'>Manage Item <MdFastfood /></button>
      </Link>
      </div>
    )
  ) : (

    
    	
<Navigate to="/" />
  )

  )
}

export default ManageItem ;
  