import React, { useEffect, useState } from 'react';
import {GoogleMap, useJsApiLoader, Marker, InfoWindow, DirectionsService, DirectionsRenderer} from '@react-google-maps/api';
import pinhome from '../img/pinhome.png';


const SetMap = ({pass,latV = null,lngV = null}) => {
  const google = window.google;



  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const [marLat, setMarLat] = useState(null);
  const [marLng, setMarLng] = useState(null);

  useEffect(() => {

    navigator.geolocation.getCurrentPosition((position) => {

     
      setCurrentLat(position.coords.latitude);
      setCurrentLng(position.coords.longitude);
    })

  });


  const [map, setMap] = useState(null);
  const {isLoaded} = useJsApiLoader({

    googleMapsApiKey: "AIzaSyB3oP4tYA04fcICAntjIidCe3GEbp2JU3A",
   


  });

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!

  
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const containerStyle = {
    width: '80vw',
    height: '80vh'
  };

  const onMarkerDragEnd = async (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();

  
    const des = { lat: lat, lng: lng};

    setMarLat(lat);
    setMarLng(lng);


  

      localStorage.setItem('addrPos', JSON.stringify(des));
    

   

    
  
    //const load =  await calculateRoute(lat,lng);
 
   
  };
  




  return (
    <div className='bg-white'> 

      {isLoaded && currentLat && currentLng ? ( 


     <div className='w-fit border-4 border-red-500 rounded-md'>

      <GoogleMap


mapContainerStyle={containerStyle}
center={{

  lat:  marLat ? parseFloat(marLat) : latV !== null ?  parseFloat(latV) : currentLat ?  parseFloat(currentLat) : parseFloat(7.873054),
  lng: marLng ? parseFloat(marLng) : lngV !== null ?  parseFloat(lngV) : currentLng ?  parseFloat(currentLng) : parseFloat(80.771797)

}
}
zoom={15}
onLoad={onLoad}
onUnmount={onUnmount}
onDblClick={(e) => {onMarkerDragEnd(e)}}
options={{streetViewControl: false, fullscreenControl: false}}
clickable



>

            <Marker
              zIndex={20}
               clickable
               draggable={true}

               
               
              position={{lat: marLat ? parseFloat(marLat) : latV !== null ?  parseFloat(latV) : currentLat ?  parseFloat(currentLat) : parseFloat(7.873054),
                lng: marLng ? parseFloat(marLng) : lngV !== null ?  parseFloat(lngV) : currentLng ?  parseFloat(currentLng) : parseFloat(80.771797)
        
              }
              }
              icon={{


                url:pinhome,
                anchor: new window.google.maps.Point(40, 90),

                scaledSize: new window.google.maps.Size(80, 80)
              }}

              onDragEnd={(e) => {onMarkerDragEnd(e)}}
              
              >

 
             
              </Marker>




</GoogleMap>
     </div>


      ):(


        <div className='w-full items-center justify-center text-center font-semibold text-xl flex flex-wrap text-red-600'>
          Please "Allow" permissions to access your location!
        </div>

      )}
      
   </div>
  )
}

export default SetMap