import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from 'react-router-dom';
import { StatteProvider } from './context/StateProvider';
import { initialState } from './context/initialState';
import reducer from './context/reducer';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Router>
        <StatteProvider initialState={initialState} reducer={reducer}>
            <App/>
        </StatteProvider>
    </Router>
    );

