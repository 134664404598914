import React from 'react';
import { getAuth,  signOut } from "firebase/auth";
import { app } from '../firebase.config';
import { useStateValue } from '../context/StateProvider';
import { useEffect } from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { actionType } from '../context/reducer';

const LogOut = () => {
    const firebaseAuth = getAuth(app);

    const [isLogged, setIsLogged] = useState(true);
    
    const [{user}, dispatch] = useStateValue();

    useEffect(() => {
      logout();
    
     
    }, []);
    

    const logout = () => {

        signOut(firebaseAuth).then(() => {
            // Sign-out successful.

            console.clear();
            setIsLogged(false);
          }).catch((error) => {
            // An error happened.
          });

    

        localStorage.clear();
        dispatch({
            type: actionType.SET_USER,
            user: null
        });
        dispatch({
          type: actionType.SET_CART_ITEMS,
          cartItems: []
      });
       
        //navigate(0);
    }
  return (
    <div>
        
        Logging Out...

        {!isLogged && (

            <Navigate to="/" />
        )}

    </div>
  )
}

export default LogOut