import React, {useState,useEffect} from 'react';
import { motion } from 'framer-motion';
import {HiOutlineBan} from 'react-icons/hi';
import {GiHotMeal} from 'react-icons/gi';

const CheckOpened = () => {

const [unixTimeData, setunixTimeData] = useState();

var opened_store = false;

   useEffect(() => {
    fetch(`https://api.viphotels.lk/`)
     .then((response) => response.json())
     .then((actualData) => (setunixTimeData(actualData.unixtime)));
   }, []);

  
   var sys_date = new Date(unixTimeData * 1000);
const now_open = new Date();
const now_close = new Date();

const open_hours = 5; //Opening Time
const open_minutes = 30;
now_open.setHours(open_hours, open_minutes);

const close_hours = 23; //Closing Time
const close_minutes = 0;
now_close.setHours(close_hours, close_minutes);

// Log the new date object with the specified time

 
   if(sys_date > now_open && sys_date < now_close){
    
    opened_store = <motion.button type="button" className="flex gap-2 text-center text-[1.3rem] text-gray-100 justify-center m-auto bg-gradient-to-br from-orange-400 to-orange-500 w-auto md:w-full lg:w-auto px-4 py-2 rounded-lg hover:shadow-lg hover:text-white font-semibold hover:font-bold transition-all ease-in-out duration-100" initial={{opacity:0, y:-50}} animate={{opacity:1, y:0}} exit={{opacity:0, y:-50, }} transition={{  type: "spring", stiffness: 100, delay:2 }}>Order Now <GiHotMeal className="text-[1.5rem]"/></motion.button>;
    //console.log("Opened!");
   }else{

    opened_store =  <motion.button type="button" className="flex gap-2 text-center text-[1.3rem] text-gray-100 justify-center m-auto bg-gradient-to-br from-red-400 to-red-500 w-auto md:w-full lg:w-auto px-4 py-2 rounded-lg hover:shadow-lg hover:text-white font-semibold hover:font-bold transition-all ease-in-out duration-100 cursor-not-allowed" initial={{opacity:0, y:-50}} animate={{opacity:1, y:0}} exit={{opacity:0, y:-50, }} transition={{  type: "spring", stiffness: 100, delay:2 }}>We are closed!<HiOutlineBan className="text-[1.5rem]"/></motion.button>;
    //console.log("Closed!");
   }

   return (
      <div>{opened_store}</div>
    )
    
  
}


   


export default CheckOpened;
