import React, { useEffect, useState } from 'react';
import {GoogleMap, useJsApiLoader, Marker, InfoWindow, DirectionsService, DirectionsRenderer} from '@react-google-maps/api';
import pinhome from '../img/pinhome.png';


const ViewMap = ({lat, lng}) => {
  const google = window.google;

  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const [marLat, setMarLat] = useState(null);
  const [marLng, setMarLng] = useState(null);




  const [map, setMap] = useState(null);
  const {isLoaded} = useJsApiLoader({

    googleMapsApiKey: "AIzaSyB3oP4tYA04fcICAntjIidCe3GEbp2JU3A",
   


  });

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!

  
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const containerStyle = {
    width: '100%',
    height: '200px'
  };


  




  return (
    <div className='bg-transparent'> 

      {isLoaded && lat && lng ? ( 


     <div className=''>
      <GoogleMap


mapContainerStyle={containerStyle}
center={{

  lat:  parseFloat(lat),
  lng: parseFloat(lng)

}
}
zoom={15}
onLoad={onLoad}
onUnmount={onUnmount}

options={{streetViewControl: false, fullscreenControl: false, disableDefaultUI: true, draggableCursor: false, draggable: false, disableDoubleClickZoom: true, clickableIcons: false, clickable: false}}




>

            <Marker
              zIndex={20}
               clickable
               draggable={false}
               
              position={{lat: parseFloat(lat),
                lng:  parseFloat(lng)
        
              }
              }
              icon={{


                url:pinhome,
                anchor: new window.google.maps.Point(40, 90),

                scaledSize: new window.google.maps.Size(60, 60)
              }}

             
              
              >

 
             
              </Marker>




</GoogleMap>
     </div>


      ):(


        <div className='w-full items-center justify-center text-center font-semibold text-xl flex flex-wrap text-red-600'>
          Loading...
        </div>

      )}
      
   </div>
  )
}

export default ViewMap