import React from 'react';
import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import {BsCameraVideoFill, BsCameraVideoOffFill} from 'react-icons/bs';
import { Link } from 'react-router-dom';

const QRScanner = () => {
    const [data, setData] = useState('No result');
    const [onCam, setOnCam] = useState(false);

  return (
    <div className='w-full flex flex-col items-center justify-center text-center select-none bg-white rounded-lg drop-shadow-md gap-3'>
    {onCam ? (
                <>
            <QrReader className='w-[300px] md:w-[300px] rounded-lg border-2 border-yellow-600 bg-yellow-500 p-2'
               constraints={{
                facingMode: 'environment'
              }}
                onResult={(result, error) => {
                    if (!!result) {
                    let data = result?.text;

                        let j = data.indexOf("://");
                        let host = "";
        
                        for (let i = j + 3; i < data.length; i++) {
                            if (data.charAt(i) != '/') {
                                host = host + "" + data.charAt(i);
                            } else {
                                break;
                            }
                        }
                        if(host == 'viphotels.lk' || host == "www.viphotels.lk"){
                            setData(data);
                            window.location.href=data;
                        }else{

                            setData("Invalid QR");
                        }
                        

                    }

                    if (!!error) {
                    setData("Scanning...");
                    }
                }}
                
                />

                <p>{data}</p>
                <button className='p-3 flex items-center gap-3 font-semibold text-3xl text-red-500 bg-yellow-400 rounded-3xl hover:bg-yellow-300 m-10' onClick={() => setOnCam(false)}><BsCameraVideoOffFill /> Turn Off</button>
                </>

    ):(<div className='flex flex-col mt-5 gap-3 w-full text-center items-center justify-center'> <i className='text-base font-serif text-red-600'>(*You can only scan the QR Codes which have been generated by VIP Hotels and Restaurants)</i><button className='p-3 flex items-center gap-3 font-semibold text-3xl text-red-500 bg-yellow-400 rounded-3xl hover:bg-yellow-300 m-5' onClick={() => setOnCam(true)}><BsCameraVideoFill /> Turn On</button>
    
    <Link to={"../BarCodeScanner"}><button className='p-2 my-2 bg-gray-400 rounded-xl text-white font-semibold shadow-sm hover:scale-110'>Switch to Bar Code Scanner</button></Link>

    </div>)}
   
  </div>
  )
}

export default QRScanner;