import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Feel_Good from '../img/feel_good.png';
import heroBg from '../img/heroBg.png';
import logo from '../img/logo.webp';


import {HiOutlineClock} from 'react-icons/hi';
import CheckOpened from '../context/CheckOpened';
import { static_food_data } from '../utils/data';
import { FaSearchLocation } from 'react-icons/fa';
import { Link } from 'react-router-dom';



export const HomeContainer = () => {

  const [loadTimer, setLoadTimer] = useState(true);

  const timeLoader = () =>{

    setLoadTimer(false);

    setTimeout(() => {
      setLoadTimer(true);
    }, 1000);
  };


  setInterval(() => { //Load Order Now Button
    timeLoader();
  }, 1800000);

  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-2 select-none w-full" id="home">
        <div className="py-2 flex-1 flex flex-col items-start md:items-center justify-center gap-4">
          <motion.div className="flex items-center gap-2 justify-center bg-orange-100 px-2 py-1 rounded-full" initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:-100, }} transition={{  type: "spring", stiffness: 100, delay:1.1 }}>
            <p className="text-base text-orange-500 font-semibold">Feel The Royal Taste</p>
            <div className="w-8 h-8 bg-white rounded-full overflow-hidden drop-shadow-xl">
              <motion.img src={Feel_Good} className="w-full h-full object-contain" alt="VIP Hotels" animate={{ rotate:360}} transition={{duration:1, repeat:1}} />
              </div>
          </motion.div>

          <motion.div className="flex items-center gap-2 justify-center w-full px-2 py-1 rounded-full" initial={{opacity:0, x:-100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:-100, }} transition={{  type: "spring", stiffness: 100, delay:1.1 }}>
            <motion.img className='w-24 border-yellow-100 border-4 rounded-full' src={logo} alt="" animate={{ rotateY:360}} transition={{duration:3, repeat:Infinity}} />
          </motion.div>

          <motion.p className="text-[1.6rem] md:text-[2rem] font-bold tracking-wide text-headingColor justify-center items-center text-center" initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100, }} transition={{  type: "spring", stiffness: 100, delay:1.2 }}>Now Available in <span className="text-orange-600 text-[1.8rem] md:text-[2.2rem]">Puttalam </span> <span className="text-[1.5rem]">and</span> <span className="text-orange-600 text-[1.8rem] md:text-[2.2rem]"> Galle<sup className="text-base " >(Soon)</sup></span></motion.p>

          <motion.p className="text-base text-textColor text-center md:text-justify md:w-[90%]" initial={{opacity:0, y:100}} animate={{opacity:1, y:0}} exit={{opacity:0, y:100, }} transition={{  type: "spring", stiffness: 100, delay:1.5 }}>
           You can easily make a delicious order via <motion.span className="text-orange-700 font-bold text-[1.1rem]"  initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100, }}  transition={{ type: "spring", stiffness: 100, delay:2 , repeat:4 }}>Online</motion.span> at home and our Royal Delivery Team will deliver your package as soon as possible with Love! For the first time in your city, you will feel a Royal Restaurant Service from us!
           </motion.p>
           <motion.div className="flex mt-3 m-auto text-base font-bold font-sans text-center gap-2" initial={{opacity:0, y:100}} animate={{opacity:1, y:0}} exit={{opacity:0, y:100, }} transition={{  type: "tween", stiffness: 100, delay:1.4 }}><motion.span animate={{ rotate:360}} transition={{duration:1, repeat:Infinity}} ><HiOutlineClock className="flex m-1 outline-dashed rounded-full" /></motion.span> (10.00 a.m. to 10.00 p.m.)</motion.div>
         

          <div className="w-[90%] md:px-5 mt-5 h-full pointer-events-auto">
         { loadTimer && (<CheckOpened />)}

        
          </div>

          <div className="w-[90%]  h-full pointer-events-auto">
          <Link to={'./map'}><motion.button type="button" className="flex gap-2 text-center text-[1.3rem] text-gray-100 justify-center m-auto bg-gradient-to-br from-green-700 to-green-500 w-auto md:w-full lg:w-auto px-4 py-2 rounded-lg hover:shadow-lg hover:text-white font-semibold hover:font-bold transition-all ease-in-out duration-100" initial={{opacity:0, y:50}} animate={{opacity:1, y:0}} exit={{opacity:0, y:50, }} transition={{  type: "spring", stiffness: 100, delay:2 }}>FIND US <FaSearchLocation className="text-[1.5rem]"/></motion.button></Link>
          </div>


        </div>

        <motion.div className="py-2 flex-1 flex items-center relative" initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:100, }} transition={{  type: "spring", stiffness: 100, delay:1.1 }}>
      

                <img src={heroBg} className="ml-auto h-420 w-full lg:h-650 lg:w-auto" alt="hero-bg" />

                <div className="md:w-full h-full absolute top-4 left-0  flex items-center justify-center px-4 lg:px-32 md:py-16 py-4 gap-16 overflow-auto scrollbar-thin lg:mb-8 flex-wrap">

                  {
                    static_food_data && static_food_data.map(n =>(

                    <motion.div key={n.id} className="w-190 bg-cardOverlay backdrop-blur-md rounded-3xl p-4 flex flex-col items-center justify-center drop-shadow-lg" whileHover={{ scale: 1.1}} animate={{ rotateY:360}} transition={{scale:{duration:0.5, repeat:0, delay:0}, rotateY:{duration:1, repeat:0, delay:1.3}}} >
                        <img src={n.image} alt="Ice-Cream" className="w-20 lg:w-40 -mt-10 lg:-mt-20 " />
                        <p className="text-xl font-semibold text-textColor mt-2 lg:mt-4">{n.name}</p>

                        <p className="text-[12px] lg:text-sm text-lighttextGray font-semibold my-1 lg:my-3">{n.desc}</p>

                        <p className="text-sm font-semibold text-headingColor"><sup className='text-gray-500'>from</sup> <span className="text-xs text-red-600 px-1">LKR</span>{n.price_from}</p>
                    </motion.div>


                    ))
                  }
                    
                </div>
            
        </motion.div>
    </section>
  )
}

export default HomeContainer;