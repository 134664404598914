import React, { useState } from 'react';
import useScanDetection from 'use-scan-detection';

const BarCodeScan = () => {

    const [barcodeScan, setBarcodeScan] = useState("No Barcode Scanned!");


    useScanDetection({

        onComplete: setBarcodeScan,
        minLength: 3

    });


  return (
    <div className='w-full flex items-center justify-center'>

        <p className='font-semibold text-red-600 text-xl'>Barcode: {barcodeScan}</p>
    </div>
  )
}

export default BarCodeScan