import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link, Navigate, useNavigate, useParams} from "react-router-dom";
import { useStateValue } from '../context/StateProvider';
import { motion } from 'framer-motion';
import {MdOutlineFormatAlignJustify, MdListAlt, MdCode, MdOutlineDeleteOutline, MdSave, MdPostAdd, MdKeyboardArrowRight} from 'react-icons/md';

import { deleteCat, getAllFoodCategories,saveCats} from '../utils/firebaseFunctions';
import { actionType } from '../context/reducer';
import Loader from './Loader';
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import {useTable} from 'react-table';
import LoaderCustom from './LoaderCustom';

const ManageCategory = ({pass = undefined}) => {
  
  
  const {catId} = useParams();
  const history = useNavigate();


    const [title, setTitle] = useState("");
    const [shortCode, setShortCode] = useState("");
    const [description, setDescription] = useState("");
    
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [{foodCategories}, dispatch] = useStateValue();
    
  const userRole = fetchRole();
  const currentURL = window.location.href;

  const data = foodCategories ? foodCategories : [{}];
  
    const user =  fetchUser();


    useEffect(() => {

      if(pass === 'new'){
  
        clearData();
      }
  
      if(pass === 'manage'){
  
        fetchData();
      }

      if(pass === 'solo' && catId && foodCategories){

        const specItem = foodCategories.find(item => item.id === catId);

        setTitle(specItem.title)
        setShortCode(specItem.shortCode);
        setDescription(specItem.description);

        
      }
  

  
     
    }, [currentURL]);  

    const columns = useMemo(() => [
      {
  
        Header : "ID",
        accessor: "id"
      },  
  
      {
  
        Header : "TITLE",
        accessor: "title"
      },  
  
      {
  
        Header : "CODE",
  
        accessor: "shortCode"
      }
    ],[]);

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});

    const saveDetails = (mode = 'save') => {

        setIsLoading(true);
    
        try{

         
    
          if(!title || !shortCode){

            
           
            setFields(true);
            setMsg("All Requeired Fields can't be empty!");
            setAlertStatus('danger');
            //clearData();
      
            setTimeout(() => {
      
              setFields(false);
              setIsLoading(false);
              
            }, 4000);
    
            fetchData();
      
          }else{

            fetchData();

            let search =  foodCategories?.filter(n => n.shortCode.toLowerCase().indexOf(shortCode.toLowerCase()) !== -1 || n.title.toLowerCase().indexOf(title.toLowerCase()) !== -1 );

            const results = [];
       
         if(mode !== 'update'){

          search.map(item => {
       
            results.push(item);
            
          });
         }

           if(results.length > 0){

          
            setFields(true);
            setMsg("This record is already exist in our server!");
            setAlertStatus('danger');
      
            setTimeout(() => {
      
              setFields(false);
              setIsLoading(false);
              clearData();
              
            }, 4000);


           }else{


            let id = `${Date.now()}`;
        
            if(mode === 'update'){
    
              id = catId;
    
    
            }

            const data = {
              id: id,
              title: title,
              shortCode: shortCode,
              description : description,
            
            }
            saveCats(id,data);
    
          
            setIsLoading(false);
            setFields(true);
              setMsg("Category has been saved Successfully!");
              if(mode === 'update'){
                clearData(mode);
              }else{
                clearData();
              }
              setAlertStatus("success");
      
              setTimeout(() => {
                
                setFields(false);
            
              }, 2000);



           }

   
    
      
    
          }
    
    
        }catch (error){
    
          console.log(error);
          setFields(true);
          setMsg("Error while uploading : Try Again!");
          setAlertStatus('danger');
    
          setTimeout(() => {
    
            setFields(false);
            setIsLoading(false);
            
          }, 4000);
    
        }
      };

      const deleteCurrentCat = () => {


        deleteCat(catId);
      
    
        window.createNotification("success","Category has been deleted!","",3000);
    
        history(-1);
    
    
        
      }
    
      const clearData = (mode = 'save') => {

        if(mode === 'update'){




        }else{
       
          setTitle("");
          setShortCode("");
          setDescription("");
        }
    
   
     
      };


      const fetchData = async () => {

        await getAllFoodCategories().then(data => {
          dispatch({
            type: actionType.SET_FOOD_CATEGORIES,
            foodCategories : data
          });
        });
      };
    

  return (
   
    
    user && userRole && (userRole === "admin") ? (

      pass === "new" ? (
        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageCategory"}>Manage Categories</Link><MdKeyboardArrowRight />Create New Category</div>
        <div className="w-full  flex mt-6 justify-center ">
          <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center gap-4 bg-white">
    
              {
                fields && (
    
                  <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                    {msg}
                  </motion.p>
                )
              }
    
    {isLoading ? <Loader />: (<>
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdListAlt className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setTitle(e.target.value) } 
                    
                    required value={title} placeholder="Enter The Title" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>
              
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdCode className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setShortCode(e.target.value.replace(/\s/g, "").toLowerCase())} required value={shortCode} onDoubleClick={(e) => title.replace(/\s/g, "") && setShortCode(title.replace(/\s/g, "").toLowerCase())}  placeholder="Enter The Short Code" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>
    
           
    
              
    
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={100} ></textarea>
                    </div>
    
                        
                        
                      </div>
    
                      </>)}
                   
    
                      <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={saveDetails}>Save <MdSave /></button>
                  </div>
          </div>
        </div> </div>

      ) :  pass === "solo" ? (

        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageCategory"}>Manage Categories</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageCategory/manage"}>Manage All Categories</Link><MdKeyboardArrowRight />{catId}</div>
        <div className="w-full  flex mt-6 justify-center ">
          <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center gap-4 bg-white">
    
              {
                fields && (
    
                  <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                    {msg}
                  </motion.p>
                )
              }
    
    {isLoading ? <Loader />: (<>
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdListAlt className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setTitle(e.target.value) } 
                    
                    required value={title} placeholder="Enter The Title" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>
              
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                <MdCode className="text-xl text-gray-700" />
                <input type="text" onChange={(e) => setShortCode(e.target.value.replace(/\s/g, "").toLowerCase())} required value={shortCode} onDoubleClick={(e) => title.replace(/\s/g, "") && setShortCode(title.replace(/\s/g, "").toLowerCase())}  placeholder="Enter The Short Code" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
              </div>
    
           
    
              
    
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={description} onChange={(e) => setDescription(e.target.value) } placeholder="Description (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={100} ></textarea>
                    </div>
    
                        
                        
                      </div>
    
                      </>)}
                   
    
                      <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={deleteCurrentCat}>Delete <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => saveDetails('update')}>Save <MdSave /></button>
                  </div>
          </div>
        </div> </div>
      )  : pass === "manage" ? (

        

      /* Manage Items */

      <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
      <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageCategory"}>Manage Categories</Link><MdKeyboardArrowRight />Manage All Categories</div>
      <div className="w-full flex flex-auto justify-center items-center mt-6 overflow-auto">


<div className='w-full  flex overflow-auto scrollbar-thin scrollbar-thumb-slate-400 bg-slate-200 shadow-lg rounded-lg'>

            {
              foodCategories ? (
                <table className='w-full' {...getTableProps()}>

              <thead>

              {headerGroups.map((headerGroup) => (

                  <tr className='bg-white hover:bg-slate-50 font-semibold text-xl' {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map((column) => (

                      <th className='py-4' {...column.getHeaderProps()}>

                        {column.render("Header")}

                      </th>
                    ))}

                  </tr>

              ))}

              </thead>

              <tbody className='hover:bg-slate-300' {...getTableBodyProps()}>

                      {
                        rows.map((row) => {

                          prepareRow(row)

                          return (


                            <tr className='text-lg hover:bg-slate-400 border-t-2 border-white hover:text-slate-50 hover:font-semibold cursor-pointer' {...row.getRowProps()} onClick={(e) => {history(row.cells[0].value)}}>

                              {row.cells.map((cell) => (
                              
                                <td className='py-4 px-3' {...cell.getCellProps()}>
                               
                                    {cell.render('Cell')}
                                    
                                 
                                </td>
                               
                              ))}
 
                            </tr>
                          )

                        })
                      }

              </tbody>


            </table>
              ) : (<LoaderCustom />)
            }

          </div>



            </div>

    </div>
    
    
    
    
      ) 
      
      
      :(<div className='text-center py-16 w-full flex flex-col gap-8 justify-center items-center m-auto select-none'> 

<div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight />Manage Categories</div>
      
      <Link to={"../admin/manageCategory/new"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-emerald-500 p-3 rounded-lg hover:bg-emerald-400 gap-2 w-full'>Create Category <MdPostAdd /></button>
      </Link> 

      <Link to={"../admin/manageCategory/manage"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-blue-500 p-3 rounded-lg hover:bg-blue-400 gap-2 w-full'>Manage Category <MdListAlt /></button>
      </Link>
      </div>)
      ) : (
    
        
            
    <Navigate to="/" />
      )
  )
}

export default ManageCategory