import { collection, doc, getDocs, getDoc, orderBy, query, setDoc, deleteDoc, where, or } from "firebase/firestore";
import CryptoJS from 'crypto-js';
import { firestore } from "../firebase.config";
import { fetchUser } from "./fetchLocalStorageData";
import { onHandleTag } from "./runOneSignal";

//Deleting Item

export const deleteItem = async (id) => {

    await deleteDoc(doc(firestore,'foodItems',`${id}`));

    

    
}


//Deleting Category

export const deleteCat = async (id) => {

    await deleteDoc(doc(firestore,'foodCategory',`${id}`));

    

    
}

//Deleting Branch

export const deleteBranch = async (id) => {

    await deleteDoc(doc(firestore,'vipBranch',`${id}`));

    

    
}

//Saving New Item
export const saveItem = async (id,data) => {

    await setDoc(
        
         doc(firestore, 'foodItems',`${id}`,), data , {
            
            merge: true
        
        });
};

//Saving New Bill
export const saveBill = async (id,data) => {

    await setDoc(
        
         doc(firestore, 'supplierBills',`${id}`,), data , {
            
            merge: true
        
        });
};

//Saving New Category
export const saveCats = async (id,data) => {

    await setDoc(
        
         doc(firestore, 'foodCategory',`${id}`,), data , {
            
            merge: true
        
        });
};

//Saving New User
export const saveUser = async (id,data,email,nic,phone,mode) => {

    const items = await getDocs(
        query(collection(firestore,"systemUser"), or (where('gmail','==',email), where('nicNo','==',nic), where('phoneNumber','==',phone)))
    );

   if((items.docs.map((doc) => doc.data())).length > 0 && mode !== 'update'){

    
    return false;

   }else{


    await setDoc(
        
        doc(firestore, 'systemUser',`${id}`,), data , {
           
           merge: true
       
       });
   }

 
};

//Saving Orders
export const saveOrders = async (trans_id,data,payref,amount,timestamp,status = 0) => { 

    const sendData = {

        id: trans_id,
        reference: payref,
        items: data,
        amount: amount,
        timestamp: timestamp,
        status: status
    };

    const user =  fetchUser();
    
    let w1 = where("cusId", "==", user.uid);

    const q = query(collection(firestore, 'vipCustomer'),w1);

    const querySnapshot = await getDocs(q);

  const queryData = querySnapshot.docs.map((detail) => ({

    ...detail.data(),
    id: detail.id
  }));

  console.log(queryData);

  queryData.map(async () => {

    
    await setDoc(
        
        doc(firestore, `vipCustomer/${user.uid}/orders/`,`${trans_id}`), sendData , {
           
           merge: true
       
       });
   



  });



};



//Saving New Customer
export const saveCustomer = async (data,email,phone,uid,upd=false,isBranch=false) => {

    let w1 = where("cusId", "!=", uid);
    let w2 = where("cusPhone", "==", phone);
    let w3 = where("cusEmail", "==", email);



    const items = await getDocs(
        query(collection(firestore, "vipCustomer"), w1, w2, orderBy("cusId"))
    );

    const items2 = await getDocs(
        query(collection(firestore, "vipCustomer"), w1, w3, orderBy("cusId"))
    );

   if((items.docs.map((doc) => doc.data())).length > 0 || (items2.docs.map((doc) => doc.data())).length > 0){

   



       getVipCustomer(email,phone);
   

        return false;
    

    
       



   
    


   }else{


        

        const docRef = doc(firestore, "vipCustomer", uid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
        if(upd){

            await setDoc(
            
                doc(firestore, 'vipCustomer',`${uid}`,), data , {
                   
                   merge: true
                
               });
        
        }
           
           getVipCustomer(email,phone);
           return true;

        } else {
          // docSnap.data() will be undefined in this case
         if(upd){

            console.log("No such document!");
          
            getVipCustomer(email);
            return false;
         }else{



           if(isBranch){

       

            const data = {cusBranch: data.cusBranch};

            await setDoc(
                
                doc(firestore, 'vipCustomer',`${uid}`,), data , {
                   
                   merge: true
                
               });


           }else{

            await setDoc(
                
                doc(firestore, 'vipCustomer',`${uid}`,), data , {
                   
                   merge: true
                
               });
           }
        
               
               getVipCustomer(email,phone);
               return true;
        }
    
        }
        
     
    
           
                 
}
 
};

//Saving New Branch
export const saveBranch = async (id,data,lat, lng, loc, shortCode, mode) => {

   if(!lat || !lng || !loc || !shortCode){

    return false;
   }

    const items = await getDocs(

        
        query(collection(firestore,"vipBranch"), or (where('lat','==',lat), where('lng','==',lng), where('location','==',loc), where('shortCode','==',shortCode)))

    );

   if((items.docs.map((doc) => doc.data())).length > 0 && mode !== 'update'){

    
    return false;

   }else{


    await setDoc(
        
        doc(firestore, 'vipBranch',`${id}`,), data , {
           
           merge: true
       
       });
   }

 
};

//Get All Orders
export const getOrders = async (userId,ref) => {

    let w1 = where("reference", "==", ref);





    const q =  query(collection(firestore, `vipCustomer/${userId}/orders/`), w1, orderBy("id"));
   



    var msg = false;

    const querySnapshot = await getDocs(q).then(collection => {

        collection.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let orders = JSON.stringify(doc.data());


         
      
      
           msg = true;

     

      
        
           localStorage.setItem('orders',CryptoJS.AES.encrypt(orders,localStorage.getItem('ffkey')).toString()
           );
    
          
      
           return orders;
            //return(JSON.stringify(doc.data()))
          })
       
       
    })
    .catch(error => console.log("fford" + error));

 if(msg === false){

       
        console.log("No Active Orders!");

    
 }
    



};

//Get user Role
export const getUserRole = async (gmail = null, phone = null) => {
  


    if(!gmail){

        const user =  fetchUser();
        
        gmail = user.email;

        if(!gmail){
            gmail = "";
        }
    }

    
    if(!phone){

        const user =  fetchUser();
        phone = user.phoneNumber;
    }


   
    let w1 = where("gmail", "==", gmail);





    const q =  query(collection(firestore, "systemUser"), w1, orderBy("id"));
   



    var msg = false;

    const querySnapshot = await getDocs(q).then(collection => {

        collection.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let data = JSON.stringify(doc.data());


         
      
      
           msg = true;

           onHandleTag('userMode',(JSON.parse(data).jobRole));
      
      
           let jobRole = CryptoJS.AES.encrypt((JSON.parse(data).jobRole), localStorage.getItem('ffkey')).toString();
           let adminName = JSON.parse(data).surname + " " + JSON.parse(data).fname +" " + JSON.parse(data).mname + " " +JSON.parse(data).lname;
           let adminEmail = JSON.parse(data).gmail; 
           let adminPhone = JSON.parse(data).phoneNumber;
           let adminId = JSON.parse(data).id;
           let adminImage = JSON.parse(data).profilePhoto;

           let admin = {

            admName : adminName,
            admImg : adminImage,
            admEmail: adminEmail,
            admId: adminId,
            admPhone : adminPhone
           };
      
           localStorage.setItem('userRole',jobRole);
           localStorage.setItem('admin',CryptoJS.AES.encrypt(JSON.stringify(admin),localStorage.getItem('ffkey')).toString()
           );
    
          
      
           return jobRole;
            //return(JSON.stringify(doc.data()))
          })
       
       
    })
    .catch(error => console.log("ffss" + error));

 if(msg === false){

       

           localStorage.removeItem('userRole');
           localStorage.removeItem('admName');
           localStorage.removeItem('admImg');
           localStorage.removeItem('admEmail');
           localStorage.removeItem('admPhone');
    

    
 }
    

    
    

    //return items.docs.map((doc) => doc.data());
    
}


//Get user Role
export const getVipCustomer = async (gmail = null,phone = null) => {
  

  
   

    /*if(!gmail){

        const user =  fetchUser();
        gmail = user.email;

        if(!gmail){
            gmail = null;
        }
        
     
    }

    
    if(!phone){

        const user =  fetchUser();
        phone = user.phoneNumber;

        if(!phone){
            phone = null;
        }
    }*/

    const user =  fetchUser();

    let w1 = where("cusId", "==", user.uid);
    //let w2 = where("cusEmail", "==", gmail);

    





    const q =  query(collection(firestore, "vipCustomer"),  w1);
   

    var msg = false;


    
    const querySnapshot = await getDocs(q).then(collection => {

        collection.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let data = JSON.stringify(doc.data());


         
      
      
           msg = true;
      
   
      
           let customerImage = JSON.parse(data).cusDp;
           let customerId = JSON.parse(data).cusId;
           let customerName = JSON.parse(data).cusName;
           let customerBirthDate = JSON.parse(data).cusBirthdate;
           let customerNic = JSON.parse(data).cusNic;
           let customerEmail = JSON.parse(data).cusEmail;
           let customerPhone = JSON.parse(data).cusPhone;
           let customerHomePhone = JSON.parse(data).cusHomePhone;
           let customerOfficePhone = JSON.parse(data).cusOfficePhone;
           let customerBusinessPhone = JSON.parse(data).cusBusinessPhone;
           let customerHomeAddress = JSON.parse(data).cusHomeAddress;
           let customerHomeAddressPosition = JSON.parse(data).cusHomeAddressPosition;
           let customerAlternativeAddress = JSON.parse(data).cusAlternativeAddress;
           let customerAlternativeAddressPosition = JSON.parse(data).cusAlternativeAddressPosition;
           let customerOfficeAddress = JSON.parse(data).cusOfficeAddress;
           let customerOfficeAddressPosition = JSON.parse(data).cusOfficeAddressPosition;
           let customerBusinessAddress = JSON.parse(data).cusBusinessAddress;
           let customerBusinessAddressPosition = JSON.parse(data).cusBusinessAddressPosition;
           let customerGender = JSON.parse(data).cusGender;
           let customerNote = JSON.parse(data).cusNote;
           let customerBranch = JSON.parse(data).cusBranch;


           let customer = {

            customerName: customerName ? customerName : null,
            customerId: customerId ? customerId : null,
            customerImage: customerImage ? customerImage : null,
            customerBirthDate: customerBirthDate ? customerBirthDate : null,
            customerGender: customerGender ? customerGender : null,
            customerNic: customerNic ? customerNic : null,
            customerEmail: customerEmail ? customerEmail : null,
            customerPhone: customerPhone ? customerPhone : null,
            customerHomePhone: customerHomePhone ? customerHomePhone : null,
            customerOfficePhone: customerOfficePhone ? customerOfficePhone : null,
            customerBusinessPhone: customerBusinessPhone ? customerBusinessPhone : null,

            customerHomeAddress: customerHomeAddress ? customerHomeAddress : null,
            customerHomeAddressPosition: customerHomeAddressPosition ? customerHomeAddressPosition : null,

            customerAlternativeAddress: customerAlternativeAddress ? customerAlternativeAddress : null,
            customerAlternativeAddressPosition: customerAlternativeAddressPosition ? customerAlternativeAddressPosition : null,

            customerOfficeAddress: customerOfficeAddress ? customerOfficeAddress : null,
            customerOfficeAddressPosition: customerOfficeAddressPosition ? customerOfficeAddressPosition : null,

            customerBusinessAddress: customerBusinessAddress ? customerBusinessAddress : null,
            customerBusinessAddressPosition: customerBusinessAddressPosition ? customerBusinessAddressPosition : null,

            customerNote: customerNote ? customerNote : null,
            customerBranch: customerBranch ? customerBranch: null,
           }
           ;
          
           localStorage.setItem('customer',CryptoJS.AES.encrypt(JSON.stringify(customer),localStorage.getItem('ffkey')).toString()

           );

           getAllBranches(customer.customerBranch);

                      


      
          // return JSON.stringify(customer);
            return(JSON.stringify(doc.data()))
          })
       
       
    })
    .catch(error => console.log("ff" + error));

 if(msg === false){

       

          // localStorage.removeItem('userRole');
         /// localStorage.removeItem('admName');
          // localStorage.removeItem('admImg');
          // localStorage.removeItem('admEmail');
           //localStorage.removeItem('admPhone');

           console.log("Not In");
           return false;
    

    
 }
    

    
    

    //return items.docs.map((doc) => doc.data());
    
}

//Get All Food Items
export const getAllFoodItems = async () => {


    const items = await getDocs(
        query(collection(firestore,"foodItems"), orderBy("title","asc"))
    );

    return items.docs.map((doc) => doc.data());
}

export const getAllBills = async () => {


    const items = await getDocs(
        query(collection(firestore,"supplierBills"), orderBy("id","asc"))
    );

  
    return items.docs.map((doc) => doc.data());
}


//Get All Branches
export const getAllBranches = async (setBranch) => {

    if(setBranch){

        let w1 = where("id", "==", setBranch);

        const items = await getDocs(
         
            query(collection(firestore,"vipBranch"),w1)
        );

    
        const branch = items.docs.map((doc) => doc.data());

       
        
        localStorage.setItem('userBranch',CryptoJS.AES.encrypt(JSON.stringify(branch[0]),localStorage.getItem('ffkey')).toString());
        

        return true;
    }


    const items = await getDocs(
        query(collection(firestore,"vipBranch"), orderBy("location","asc"))
    );


  

    return items.docs.map((doc) => doc.data());
}


//Get All Categories
export const getAllFoodCategories= async () => {


    const items = await getDocs(
        query(collection(firestore,"foodCategory"), orderBy("title","asc"))
    );

    return items.docs.map((doc) => doc.data());
}

//Get All Users
export const getAllUsers= async () => {


    const items = await getDocs(
        query(collection(firestore,"systemUser"), orderBy("fname","asc"))
    );

    return items.docs.map((doc) => doc.data());
}