import {useState,useEffect} from 'react';


const CheckSmsBalance = () => {


const [sms_bal, setSmsBal] = useState();



   useEffect(() => {
    fetch(`https://api.viphotels.lk/sms/balance`)
     .then((response) => response.json())
     .then((actualData) => (setSmsBal(actualData.balance)));
   }, []);

  


   return (
     sms_bal
    )
    
  
}


   


export default CheckSmsBalance;
