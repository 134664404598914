import OneSignal from 'react-onesignal';
import { fetchCustomer, fetchUser } from './fetchLocalStorageData';

export const onHandleTag = (key,tag) => {

  const stored_tag = localStorage.getItem(key);

  if(!stored_tag){

    console.log("Tagging...");
    OneSignal.sendTag(key, tag).then(() => {

      console.log(`Tagged ${key} as ${tag}`);
      localStorage.setItem(key,tag);
    });

  }else{

    if(stored_tag === tag){

      //Done


    }else{

      console.log("Re-Tagging...");
      OneSignal.sendTag(key, tag).then(() => {
  
        console.log(`Re-Tagged ${key} as ${tag}`);
        localStorage.setItem(key,tag);
      });


    }

  }

  }

export const onHandleExId = (id) => {

    console.log("EX ID CREATING...");
   OneSignal.setExternalUserId(id).then(() => {

    localStorage.setItem("ex_id", id);
    console.log("Ex-Id-Done : " + id);
   })
  }

  

export default async function runOneSignal() {

  OneSignal.setSubscription(false);
  

  OneSignal.isPushNotificationsEnabled(function (isEnabled) {
    if (isEnabled) {
      //Good
    } else {
      //window.createNotification("error","Unable to Notify!","Please clear the browser history and try to login again or enable notifications!", 5000);
      console.log("Can't Notify");
    }
  });
    await OneSignal.init({ appId: '405b68e5-2e56-4683-a2ad-ea7af3a87801'}).then(() => {

      navigator.serviceWorker.register('https://viphotels.lk/OneSignalSDKWorker.js').then(function(registration) {
        // Service worker registration successful
        OneSignal.registerForPushNotifications();
      }).catch(function(error) {
        // Service worker registration failed
        console.error('Service worker registration failed:', error);
      });
        
        const user = fetchCustomer();
        var my_title = "";

        if(user && user.customerName){

            my_title = (user.customerName.split(" ")[0]) + ", ";

        }
        //console.log(my_title);
        onHandleTag('userMode','customer');
        OneSignal.setDefaultTitle(my_title + "You have a new message!");
        OneSignal.setSubscription(true);

        if(user && user.customerId){

          onHandleExId(user.customerId);
        }else{
          onHandleExId(`${Date.now()}`);
        }
        
        
        OneSignal.showSlidedownPrompt().then(() => {
  
  
      
            return true;
  
          
         
        }).catch((error) => {

            return false;


        });

      })
}