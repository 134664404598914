import { sha256 } from 'crypto-hash';
import React, {  useEffect, useRef, useState }  from 'react';
import { MdContentCopy, MdContentPaste, MdDeleteOutline, MdKeyboardArrowRight, MdOutlineBalance, MdOutlineCurrencyExchange, MdSend } from 'react-icons/md';
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { Link, Navigate } from 'react-router-dom';
import CheckSmsBalance from '../context/CheckSmsBalance';
import LoaderCustom  from './LoaderCustom';
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';




const ManageSMS = () => {

  const user =  fetchUser();
  const [renderComponentB, setRenderComponentB] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [textMsg, setTextMsg] = useState('');
  const [copiedRef, setCopiedRef] = useState(false);
  const [payRef, setPayRef] = useState('');

  const refCode = useRef(null);


  useEffect(() => {
    const ref = new Date().getTime();
    setPayRef('VIPHOTELS_'+ ref);
  }, []);
  



  const userRole = fetchRole();



  const handleCopy = () => {

if(refCode.current && !copiedRef){
    
    refCode.current.select(); 



    document.execCommand('copy'); 
    window.getSelection().removeAllRanges();
    window.createNotification('info',"Reference Code Copied!",refCode.current.defaultValue,2000);
    setCopiedRef(true);

    setTimeout(() => {

      setCopiedRef(false);
      
    }, 3000);

  }else{
    navigator.clipboard.readText()
  .then(clipboardData => {
    window.createNotification("info","Already Copied!",clipboardData,2000);
  })
  .catch(error => {
    window.createNotification("info","Already Copied!","",2000);
  });

  }
  }


 async function SendSms(e){

  e.target.disabled = true;


    if(phoneNumber && textMsg && isValidPhoneNumber( phoneNumber) && textMsg.length < 160){

      let hash = await sha256(textMsg + phoneNumber);

     
     
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ msg: textMsg, phone: phoneNumber, token:  hash})
        };
        await fetch('https://api.viphotels.lk/sms/send', requestOptions)
            .then(response => response.json())
            .then(data => {

            if(data.status === 1){

           
              window.createNotification('success','Successfully Sent!',"Your message has been sent successfully! \n\n Avl. SMS Balance is: " + data.balance,5000);
            }else{

              window.createNotification('error','Unable to Send!',"Sorry! The message has not been sent!",5000);
            }


          e.target.disabled = false;
          
              balance_load();

            });


            
   
    
    
    



      
      
    }else{

     
      window.createNotification('error','Invalid Operation!',"Please check the phone number and also the message cannot be nulled!",5000);
      e.target.disabled = false;
      
    }

    
 }

 const clearForm = () =>{

  setPhoneNumber(null);
  setTextMsg('');
  setRenderComponentB(true);

 };

  const recharge = () => {

    //window.createNotification('info','Under Development!',"This option is currently not available!",5000);

    window.open('https://shop.geniebiz.lk/6406fb54ba0f0e0008107f7c/product/1000-sms-plan-jYLO9mRo5', '_blank');
    //
  };

  const balance_load = () =>  {
    setRenderComponentB(false);

    setTimeout(() => {
      setRenderComponentB(true);
  
    }, 1000);
   
   } 

  return (

    
      
    user && userRole && (userRole === "admin") ? (

      

    <div className="w-full h-auto bg-transparent">

<div className='py-16 w-full flex flex-col justify-center items-center  m-auto select-none'> 

<div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight />Manage SMS</div>
        <div className="text-textColor text-2xl flex  gap-3 justify-center items-center mt-6">Current SMS Balance: <span className="font-semibold text-red-600" id="curBal">{renderComponentB && (<CheckSmsBalance />)}{!renderComponentB && (<LoaderCustom fg={'fill-red-500'} />)}</span></div>

        <div className='w-full mt-5 md:w-max flex flex-col sm:flex-row justify-center items-center text-center font-semibold text-md sm: text-lg md:text-xl gap-3 border-2 rounded-lg border-gray-600 bg-slate-100 p-4'><span className='text-black'>Reference : </span><input className='text-red-600 select-text text-base outline-none bg-transparent min-w-max' readOnly  ref={refCode} value={payRef} onChange={(e) => {setPayRef(e.target.value)}} title='Click or Select to Copy' /><button title='copy' onClick={handleCopy} className=' outline-none bg-transparent p-1'>{copiedRef ? (<MdContentPaste className='text-red-800 bg-yellow-200 rounded-xl' />) : (<MdContentCopy />)}</button></div>

        <div className="w-full justify-center items-center flex flex-col sm:flex-row text-center  gap-2">
          <button className="flex flex-row text-xl gap-2   bg-slate-400 text-white hover:bg-slate-500 p-2 rounded-md mt-5 items-center" onClick={recharge}>Recharge <MdOutlineCurrencyExchange /></button>
          <button className="flex flex-row text-xl gap-2   bg-slate-400 text-white hover:bg-slate-500 p-2 rounded-md mt-5 items-center" onClick={balance_load}>Bal. Check <MdOutlineBalance /></button>
        </div>

        <div className="items-center justify-center flex flex-col bg-white shadow-md rounded-md border-gray-500 border-[1px] mt-8 py-6 p-1 gap-2">

        <PhoneInput
     className={`w-full md:w-508 border-[1px] border-gray-600 rounded-md before:empty:bg-transparent outline-none p-3 placeholder-neutral-700 ${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
     placeholder='Sender (eg: 0756101993)' value={phoneNumber} maxLength={15} 
        country="LK"
        defaultCountry='LK'
        international
        withCountryCallingCode
      onChange={setPhoneNumber}
      
      />

         

          <textarea className="w-full md:w-508 border-[1px] border-gray-600 rounded-md  outline-none p-3 empty:bg-transparent focus:bg-white placeholder-neutral-400"  placeholder='Type your message here!' name="msg_box" id="msg_box" cols="30" rows="10" maxLength={159} value={textMsg} onChange={(e) => {

            setTextMsg(e.target.value);
          
          let counter = document.getElementById("msg_counter");

          
          if(e.target.value.length !== 0){


            counter.innerHTML = (159 - e.target.value.length) + " / 159";
          }else{

            counter.innerHTML = "159 / 159";
          }

          
            
          }
          }></textarea>

          <div className="justify-between"><label className="" htmlFor="msg_box" id="msg_counter" >159 / 159</label></div>

         <div className="w-auto items-center md:justify-between flex flex-col sm:flex-row gap-0 sm:gap-3">
         <button className="flex flex-row text-xl gap-2 bg-red-500 text-white hover:bg-red-800 p-2 rounded-md mt-5 items-center" onClick={clearForm}>Clear <MdDeleteOutline /></button>
        <button className="flex flex-row text-xl gap-2 bg-emerald-500 text-white hover:bg-emerald-800 p-2 rounded-md mt-5 items-center" onClick={(e) => {SendSms(e)}}>SEND SMS <MdSend /></button>
         </div>
    
        </div>
    </div>
</div>
    ) : (

    
    	
      <Navigate to="/" />
        )
      

    
  )
}

export default ManageSMS