import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link, Navigate, useNavigate, useParams} from "react-router-dom";
import { useStateValue } from '../context/StateProvider';
import { motion } from 'framer-motion';
import { MdCloudUpload, MdDelete, MdOutlineFormatAlignJustify, MdSave, MdOutlineDeleteOutline, MdSignpost, MdPhone, MdKeyboardArrowRight} from 'react-icons/md';
import Loader from './Loader';
import {deleteObject, getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import { storage } from '../firebase.config';
import {getAllUsers, saveUser} from '../utils/firebaseFunctions';
import { HiOutlineCake, HiOutlineUserPlus } from 'react-icons/hi2';
import { HiOutlineUser } from 'react-icons/hi';
import {BiIdCard, BiRename} from 'react-icons/bi';
import {SiGmail} from 'react-icons/si';
import {AiFillAlert} from 'react-icons/ai';
import {BsBriefcase, BsPen} from 'react-icons/bs';
import DatePicker from "react-datepicker";
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input/input';
import { actionType } from '../context/reducer';
import "react-datepicker/dist/react-datepicker.css";
import { GiRotaryPhone } from 'react-icons/gi';
import { fetchRole, fetchUser } from '../utils/fetchLocalStorageData';
import LoaderCustom from './LoaderCustom';
import { useTable } from 'react-table';
import Avatar from '../img/avatar.png';


const ManageUsers = ({pass = undefined}) => {

  const {userId} = useParams();
  const history = useNavigate();
  const currentURL = window.location.href;

useEffect(() => {



  if(userId === 'masterAdmin'){


      
    window.createNotification("warning","Unable to Load Master Admin Account!","",2000);

    history(-1);


}
}, [currentURL]);


    const [surname, setSurname] = useState("");
    const [fname, setFname] = useState("");
    const [mname, setMname] = useState("");
    const [lname, setLname] = useState("");
    const [jobRole, setJobRole] = useState("");
    const [gender, setGender] = useState("");

    const [customJobRole, setCustomJobRole] = useState("");


    const [profilePhoto, setProfilePhoto] = useState(null);
    const [nicFPhoto, setNicFPhoto] = useState(null);
    const [nicBPhoto, setNicBPhoto] = useState(null);

    const [nicNo, setNicNo] = useState("");
    const [bDate, setBDate] = useState(new Date('2000-01-01'));
    const [gmail, setGmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [landPhoneNumber, setLandPhoneNumber] = useState(null);
    const [emPhoneNumber, setEmPhoneNumber] = useState(null);
    const [address, setAddress] = useState("");
    const [note, setNote] = useState("");

    const [joinedDate, setJoinedDate] = useState(new Date());


    
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [{vipUsers}, dispatch] = useStateValue();





  

    const user =  fetchUser();

  const userRole = fetchRole();

  const data = vipUsers ? vipUsers : [{}];

  useEffect(() => {


    if(pass === 'new'){

      clearData();
    }

    if(pass === 'manage'){

    
      fetchData();
     
    }



    if(pass === 'solo' && userId && vipUsers){

      const specItem = vipUsers.find(item => item.id === userId);

      if(specItem){

        setSurname(specItem.surname);
        setFname(specItem.fname);
        setMname(specItem.mname);
        setLname(specItem.lname);

        setGender(specItem.gender);

        setProfilePhoto(specItem.profilePhoto);
        setNicFPhoto(specItem.nicFPhoto);
        setNicBPhoto(specItem.nicBPhoto);

       setJoinedDate(specItem.joinedDate ? specItem.joinedDate.toDate() : new Date());
       setJobRole(specItem.jobRole);
       setCustomJobRole(specItem.customJobRole);
       setBDate(specItem.bDate ? specItem.bDate.toDate() : new Date('2000-01-01'));
       setNicNo(specItem.nicNo);
       setGmail(specItem.gmail);
       setPhoneNumber(specItem.phoneNumber);
       setEmPhoneNumber(specItem.emPhoneNumber);
       setLandPhoneNumber(specItem.landPhoneNumber);

       setAddress(specItem.address);
       setNote(specItem.note);
     

       

      }else{

        console.log("poil");
      }


      
    }},[currentURL]);



    const renderImageCell = (cellProps) => {
      const value = cellProps.value;

      
  
  
      return <div className='w-[100px] md:w-full flex flex-col items-center justify-center'><img className='w-20 h-20 rounded-full' src={value? value : Avatar} alt="Image" /></div>;
    };
  


    const columns = useMemo(() => [
      {
  
        Header : "ID",
        accessor: "id"
      },  
  
      {
  
        Header : "NAME",
        accessor: ({ fname, mname, lname }) => `${fname} ${mname} ${lname}`,
        
      },  
  
      {
  
        Header : "NIC",
  
        accessor: "nicNo"
      },

        
      {
  
        Header : "Profile",
  
        accessor: "profilePhoto",
        id : "image"
      }
    ],[]);

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});
   
   

    


    const toCamelCase = (str) => {
        
        return str
          .split(' ')
          .map((word, index) => {
            if (index === -1) {
              return word.toLowerCase();
            } else {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
          })
          .join(' ');
      };
    

  const uploadImage = (e,to) => {

   
    
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(storage,`ProfileImages/${Date.now()}-${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on('state_changed', (snapshot) => {

      const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


    }, (error) => {
      
      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);


    }, () => {

      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL =>{

        console.log(downloadURL);

        if(to == 'profile'){

            setProfilePhoto(downloadURL);
        }else if(to == 'nic_f'){

            setNicFPhoto(downloadURL);
        }else if(to == 'nic_b'){


            setNicBPhoto(downloadURL);
        }else{


           //deleteImage(downloadURL);
        }

       
        setIsLoading(false);
        setFields(true);
        setMsg("Image Uploaded Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      });

    });


  };
  const deleteImage = (url, to = undefined) => {

    if(userId === 'masterAdmin'){

      return false;
    }

    setIsLoading(true);
    const deleteRef = ref(storage, url);
    deleteObject(deleteRef).then(() => {

        if(to == 'profile'){

            setProfilePhoto(null);
        }else if(to == 'nic_f'){

            setNicFPhoto(null);
        }else if(to == 'nic_b'){


            setNicBPhoto(null);
        }
      setIsLoading(false);
      setFields(true);
        setMsg("Image Deleted Successfully!");
        setAlertStatus("success");

        setTimeout(() => {
          
          setFields(false);
        }, 4000);
      
    });
  };
  const saveDetails = async (mode = 'save') => {

    if(userId === 'masterAdmin'){

      return false;
    }

  

    setIsLoading(true);

    try{

      if((!fname || !lname || !jobRole || !gender || !nicNo || !bDate || !gmail || !phoneNumber || !address)){


      

        setFields(true);
        setMsg("All Requeired Fields can't be empty!");
        setAlertStatus('danger');
        //clearData();
  
        setTimeout(() => {
  
          setFields(false);
          setIsLoading(false);
          
        }, 4000);

      
  
      }else{


        let id = `${Date.now()}`;

        if(mode === 'update'){

          id = userId;
        }


        console.log(124);
        
        


        const data = {
          id: id,
          surname: surname ? surname : "",
          fname: fname,
          mname: mname ? mname : "",
          lname: lname,
          jobRole: jobRole,
          customJobRole: customJobRole ? customJobRole : "",
          gender: gender,
          profilePhoto: profilePhoto ? profilePhoto : "",
          nicFPhoto: nicFPhoto ? nicFPhoto : "",
          nicBPhoto: nicBPhoto ? nicBPhoto : "",
          nicNo: nicNo,
          bDate: bDate,
          gmail: gmail,
          phoneNumber: phoneNumber,
          emPhoneNumber: emPhoneNumber ? emPhoneNumber : "",
          landPhoneNumber: landPhoneNumber ? landPhoneNumber : "",
          address: address,
          joinedDate: joinedDate,
          note:note

        
        }
        if(await saveUser(id,data,gmail,nicNo,phoneNumber, mode) == false){

          setIsLoading(false);
          setFields(true);
            setMsg("This user is already registered in the system!");
            //clearData();
            setAlertStatus("danger");
    
            setTimeout(() => {
              
              setFields(false);
          
            }, 2000);

        }else{

      
        setIsLoading(false);
        setFields(true);
          setMsg("User has been saved Successfully!");
          if(mode === 'update'){
            clearData(mode);
          }else{
            clearData();
          }
          setAlertStatus("success");
  
          setTimeout(() => {
            
            setFields(false);
        
          }, 2000);

        }
      }


    }catch (error){

      console.log(error);
      setFields(true);
      setMsg("Error while uploading : Try Again!");
      setAlertStatus('danger');

      setTimeout(() => {

        setFields(false);
        setIsLoading(false);
        
      }, 4000);

    }
  };

    const clearData = (mode) => {

      if(mode === 'update'){




      }else{

        setSurname("");
        setFname("");
        setMname("");
        setLname("");
        setJobRole(null);
        setCustomJobRole("");
        setGender(null);
        setProfilePhoto(null);
        setNicNo("");
        setGmail("");
        setBDate(new Date('2000-01-01'));
        setPhoneNumber("");
        setEmPhoneNumber("");
        setLandPhoneNumber("");
        setAddress("");
        setJoinedDate(new Date());
        setNote("");
      };
    
    }
   


      const fetchData = async () => {

        await getAllUsers().then(data => {

      
 
          dispatch({
            type: actionType.SET_USERS,
            vipUsers : data
          });
        });
      };
     
  return (

    
    user && userRole && (userRole === "admin") ?
    
    (
    
        pass === "new" ? (

          <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/systemUsers"}>Manage Users</Link><MdKeyboardArrowRight />Create New User</div>
        
        <div className="w-full min-h-screen h-auto flex items-center justify-center mt-10">
        <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center justify-center gap-4 bg-white">
  
    
  
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BiRename className="text-gray-700 text-2xl" />
                        <input type="text" required value={surname} onChange={(e) => setSurname(toCamelCase(e.target.value)) } placeholder="Surname (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                      </div>
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BiRename className="text-gray-700 text-2xl" />
                        <input type="text" required value={fname} onChange={(e) => setFname(toCamelCase(e.target.value.replace(/\s/g, "")))} placeholder="First Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                      </div>
                      
            </div>

            <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <BiRename className="text-gray-700 text-2xl" />
                        <input type="text" required value={mname} onChange={(e) => setMname(toCamelCase(e.target.value)) } placeholder="Middle Name (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                      </div>
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                         <BiRename className="text-gray-700 text-2xl" />
                        <input type="text" required value={lname} onChange={(e) => setLname(toCamelCase(e.target.value.replace(/\s/g, "")))} placeholder="Last Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                      </div>
                      
            </div>

            
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
            <select onChange={(e) => setJobRole(e.target.value)} className="w-full bg-white outline-none text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer" value={!jobRole && ("default") || jobRole && (jobRole)} >
                <option disabled value="default" className="bg-white text-gray-400">Select Job Role</option>
                <option value='admin' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Administrator</option>
                <option value='stockmng' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Stock Manager</option>
                <option value='financemng' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Financial Manager</option>
                <option value='cashier' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Cashier</option>
                <option value='other' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Other</option>
              </select>
  
              <select onChange={(e) => setGender(e.target.value)} className="w-full bg-white outline-none text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer" value={!gender && ("default") || gender && (gender)} >
                <option disabled value="default" className="bg-white text-gray-400">Gender</option>
                <option value='male' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Male</option>
                <option value='female' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Female</option>
                <option value='other' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Other</option>
         
              </select>
                      
            </div>

            <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      {
                        jobRole === 'other' && (<div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <BsBriefcase className="text-gray-700 text-2xl" />
                          <input type="text" required value={customJobRole} onChange={(e) => setCustomJobRole(e.target.value) } placeholder="Custom Job Role (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={50} />
                        </div>)
                      }
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BsPen className="text-gray-700 text-2xl" />
                      <DatePicker selected={joinedDate}  className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor"  onChange={(date) => setJoinedDate(date)} />
                      </div>
                      
                </div>
  
        
  
            <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                  {isLoading ? <Loader />: (<>
                  
                  {!profilePhoto ? (<>
                  
                  <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">
  
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                      <MdCloudUpload className="text-3xl" />
                      <p className="">Upload Profile Picture (Optional)</p>
                    </div>
                    <input type="file" name="uploadimage" accept="image/*" onChange={(e) => uploadImage(e,'profile')} className="w-0 h-0" />
                  </label>
                  
                  </>) : (<>
                  
                    <div className="relative h-full">
                      <img src={profilePhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                      <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={(e) => deleteImage(profilePhoto,'profile')}><MdDelete className="text-white" /></button>
                    </div>
  
                  </>)}
  
                  </>)}
            </div>

            <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                  {isLoading ? <Loader />: (<>
                  
                  {!nicFPhoto ? (<>
                  
                  <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">
  
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                      <MdCloudUpload className="text-3xl" />
                      <p className="">Upload NIC/Passport Front Side (Optional)</p>
                    </div>
                    <input type="file" name="uploadimage" accept="image/*" onChange={(e) => uploadImage(e,'nic_f')}  className="w-0 h-0" />
                  </label>
                  
                  </>) : (<>
                  
                    <div className="relative h-full">
                      <img src={nicFPhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                      <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out"  onClick={(e) => deleteImage(nicFPhoto,'nic_f')}><MdDelete className="text-white" /></button>
                    </div>
  
                  </>)}
  
                  </>)}
            </div>


            <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                  {isLoading ? <Loader />: (<>
                  
                  {!nicBPhoto ? (<>
                  
                  <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">
  
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                      <MdCloudUpload className="text-3xl" />
                      <p className="">Upload NIC/Passport Back Side (Optional)</p>
                    </div>
                    <input type="file" name="uploadimage" accept="image/*"  onChange={(e) => uploadImage(e,'nic_b')} className="w-0 h-0" />
                  </label>
                  
                  </>) : (<>
                  
                    <div className="relative h-full">
                      <img src={nicBPhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                      <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={(e) => deleteImage(nicBPhoto,'nic_b')}><MdDelete className="text-white" /></button>
                    </div>
  
                  </>)}
  
                  </>)}
            </div>
  
     
                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BiIdCard className="text-gray-700 text-2xl" />
                        <input type="text" required value={nicNo} onChange={(e) => setNicNo(e.target.value) } placeholder="NIC / Driving License /  Passport Number" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={12} />
                      </div>
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <HiOutlineCake className="text-gray-700 text-2xl" />
                      <DatePicker selected={bDate}  className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor"  onChange={(date) => setBDate(date)} />
                      </div>
                      
                </div>

                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <SiGmail className="text-gray-700 text-2xl" />
                        <input type="email" required value={gmail} onChange={(e) => setGmail(e.target.value) } placeholder="Gmail Address" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={150} />
                      </div>
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <MdPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Phone Number (eg: 0756101993)' value={phoneNumber} maxLength={12} 
                        rules={{ required: true }}
                            country="LK"
                            defaultCountry='LK'
                            international
                          
                        onChange={setPhoneNumber} />
                      </div>
                      
                </div>

                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <AiFillAlert className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${emPhoneNumber && isValidPhoneNumber(emPhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Emergency Number (Optional)' value={emPhoneNumber} maxLength={12} 
                        rules={{ required: true }}
                        country="LK"
                        defaultCountry='LK'
                        international
                      
                        onChange={setEmPhoneNumber} />
                      </div>
  
                      <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                        <GiRotaryPhone className="text-gray-700 text-2xl" />
                        <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${landPhoneNumber && isValidPhoneNumber(landPhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                        placeholder='Land Phone Number (Optional)' value={landPhoneNumber} maxLength={12} 
                        rules={{ required: true }}
                        country="LK"
                        defaultCountry='LK'
                        international
                      
                        onChange={setLandPhoneNumber} />
                      </div>
                      
                </div>

                
                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdSignpost className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={address} onChange={(e) => setAddress(e.target.value) } placeholder="Address" className="w-full h-10 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>
  
                    
                </div>
            

                

                <div className="w-full flex flex-col md:flex-row items-center gap-3">
                      <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                        <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                        <textarea type="text" required value={note} onChange={(e) => setNote(e.target.value) } placeholder="Note about user (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                      </div>
  
                    
                </div>
  
                {
              fields && (
  
                <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                  {msg}
                </motion.p>
              )
            }
                
  
                    <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                    <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                      <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={saveDetails}>Save <MdSave /></button>
                    </div>
        </div>
      </div> </div>
      ) : pass === "solo" ? (

        

        <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
        <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/systemUsers"}>Manage Users</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/systemUsers/manage"}>Manage All Users</Link><MdKeyboardArrowRight />{userId}</div>
      
      <div className="w-full min-h-screen h-auto flex items-center justify-center mt-10">
      <div className="w-[90%] md:w-[75%] border border-gray-400 rounded-lg p-4 flex flex-col items-center justify-center gap-4 bg-white">

  

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <BiRename className="text-gray-700 text-2xl" />
                      <input type="text" required value={surname} onChange={(e) => setSurname(toCamelCase(e.target.value)) } placeholder="Surname (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <BiRename className="text-gray-700 text-2xl" />
                      <input type="text" required value={fname} onChange={(e) => setFname(toCamelCase(e.target.value.replace(/\s/g, "")))} placeholder="First Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BiRename className="text-gray-700 text-2xl" />
                      <input type="text" required value={mname} onChange={(e) => setMname(toCamelCase(e.target.value)) } placeholder="Middle Name (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                       <BiRename className="text-gray-700 text-2xl" />
                      <input type="text" required value={lname} onChange={(e) => setLname(toCamelCase(e.target.value.replace(/\s/g, "")))} placeholder="Last Name" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" />
                    </div>
                    
          </div>

          
          <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <select onChange={(e) => setJobRole(e.target.value)} className="w-full bg-white outline-none text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer" value={!jobRole && ("default") || jobRole && (jobRole)} >
              <option disabled value="default" className="bg-white text-gray-400">Select Job Role</option>
              <option value='admin' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Administrator</option>
              <option value='stockmng' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Stock Manager</option>
              <option value='financemng' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Financial Manager</option>
              <option value='cashier' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Cashier</option>
              <option value='other' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Other</option>
            </select>

            <select onChange={(e) => setGender(e.target.value)} className="w-full bg-white outline-none text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer" value={!gender && ("default") || gender && (gender)} >
              <option disabled value="default" className="bg-white text-gray-400">Gender</option>
              <option value='male' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Male</option>
              <option value='female' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Female</option>
              <option value='other' className="text-base border-0 outline-none capitalize bg-white text-headingColor">Other</option>
       
            </select>
                    
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    {
                      jobRole === 'other' && (<div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <BsBriefcase className="text-gray-700 text-2xl" />
                        <input type="text" required value={customJobRole} onChange={(e) => setCustomJobRole(e.target.value) } placeholder="Custom Job Role (Optional)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={50} />
                      </div>)
                    }

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <BsPen className="text-gray-700 text-2xl" />
                    <DatePicker selected={joinedDate}  className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor"  onChange={(date) => setJoinedDate(date)} />
                    </div>
                    
              </div>

      

          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!profilePhoto ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Upload Profile Picture (Optional)</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*" onChange={(e) => uploadImage(e,'profile')} className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={profilePhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={(e) => deleteImage(profilePhoto,'profile')}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>

          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!nicFPhoto ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Upload NIC/Passport Front Side (Optional)</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*" onChange={(e) => uploadImage(e,'nic_f')}  className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={nicFPhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out"  onClick={(e) => deleteImage(nicFPhoto,'nic_f')}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>


          <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 cursor-pointer rounded-lg">
                {isLoading ? <Loader />: (<>
                
                {!nicBPhoto ? (<>
                
                <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer select-none text-gray-500 hover:text-gray-700">

                  <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                    <MdCloudUpload className="text-3xl" />
                    <p className="">Upload NIC/Passport Back Side (Optional)</p>
                  </div>
                  <input type="file" name="uploadimage" accept="image/*"  onChange={(e) => uploadImage(e,'nic_b')} className="w-0 h-0" />
                </label>
                
                </>) : (<>
                
                  <div className="relative h-full">
                    <img src={nicBPhoto} alt="Uploaded Image" className="w-full h-full object-cover" />
                    <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all ease-in-out" onClick={(e) => deleteImage(nicBPhoto,'nic_b')}><MdDelete className="text-white" /></button>
                  </div>

                </>)}

                </>)}
          </div>

   
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <BiIdCard className="text-gray-700 text-2xl" />
                      <input type="text" required value={nicNo} onChange={(e) => setNicNo(e.target.value) } placeholder="NIC / Driving License /  Passport Number" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={12} />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <HiOutlineCake className="text-gray-700 text-2xl" />
                    <DatePicker selected={bDate}  className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor"  onChange={(date) => setBDate(date)} />
                    </div>
                    
              </div>

              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                    <SiGmail className="text-gray-700 text-2xl" />
                      <input type="email" required value={gmail} onChange={(e) => setGmail(e.target.value) } placeholder="Gmail Address" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor" maxLength={150} />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <MdPhone className="text-gray-700 text-2xl" />
                      <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${phoneNumber && isValidPhoneNumber(phoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                      placeholder='Phone Number (eg: 0756101993)' value={phoneNumber} maxLength={12} 
                      rules={{ required: true }}
                          country="LK"
                          defaultCountry='LK'
                          international
                        
                      onChange={setPhoneNumber} />
                    </div>
                    
              </div>

              <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <AiFillAlert className="text-gray-700 text-2xl" />
                      <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${emPhoneNumber && isValidPhoneNumber(emPhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                      placeholder='Emergency Number (Optional)' value={emPhoneNumber} maxLength={12} 
                      rules={{ required: true }}
                      country="LK"
                      defaultCountry='LK'
                      international
                    
                      onChange={setEmPhoneNumber} />
                    </div>

                    <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
                      <GiRotaryPhone className="text-gray-700 text-2xl" />
                      <PhoneInput className={`w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor${landPhoneNumber && isValidPhoneNumber(landPhoneNumber) ?  ' after:empty: bg-emerald-200 text-emerald-800' : 'focus:bg-red-200 text-red-800'}`}
                      placeholder='Land Phone Number (Optional)' value={landPhoneNumber} maxLength={12} 
                      rules={{ required: true }}
                      country="LK"
                      defaultCountry='LK'
                      international
                    
                      onChange={setLandPhoneNumber} />
                    </div>
                    
              </div>

              
              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdSignpost className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={address} onChange={(e) => setAddress(e.target.value) } placeholder="Address" className="w-full h-10 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>

                  
              </div>
          

              

              <div className="w-full flex flex-col md:flex-row items-center gap-3">
                    <div className="w-full py-2 border-b border-gray-300 flex  gap-2">
                      <MdOutlineFormatAlignJustify className="text-gray-700 text-2xl" />
                      <textarea type="text" required value={note} onChange={(e) => setNote(e.target.value) } placeholder="Note about user (Optional)" className="w-full h-20 md:h-30 text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor items-center justify-center text-justify scrollbar-thumb-emerald-400 scrollbar-thin scroll-smooth scrollbar-corner-transparent scrollbar-track-slate-200" maxLength={500} ></textarea>
                    </div>

                  
              </div>

              {
            fields && (

              <motion.p className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${alertStatus === 'danger' ? 'bg-red-200 text-red-800' : 'bg-emerald-200 text-emerald-800'}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
                {msg}
              </motion.p>
            )
          }
              

                  <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
                  <button type="button" className="ml-0  flex items-center justify-center text-center gap-3  w-full md:w-auto border-none outline-none bg-red-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={clearData}>Clear <MdOutlineDeleteOutline /></button>
                    <button type="button" className="ml-0 flex items-center justify-center text-center gap-3 md:ml-auto w-full md:w-auto border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg text-lg text-white font-semibold" onClick={(e) => saveDetails('update')}>Save <MdSave /></button>
                  </div>
      </div>
    </div> </div>
    
      ) : pass === "manage" ? (

        


        /* Manage Items */

      <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none over'>
      <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/systemUsers"}>Manage Users</Link><MdKeyboardArrowRight />Manage All Users</div>
        <div className="w-full flex flex-auto justify-center items-center mt-6 overflow-auto">


          <div className='w-full  flex overflow-auto scrollbar-thin scrollbar-thumb-slate-400 bg-slate-200 shadow-lg rounded-lg'>

            {
             vipUsers ? (
                <table className='w-full' {...getTableProps()}>

              <thead>

              {headerGroups.map((headerGroup) => (

                  <tr className='bg-white hover:bg-slate-50 font-semibold text-xl' {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map((column) => (

                      <th className='py-4' {...column.getHeaderProps()}>

                        {column.render("Header")}

                      </th>
                    ))}

                  </tr>

              ))}

              </thead>

              <tbody className='hover:bg-slate-300' {...getTableBodyProps()}>

                      {
                        rows.map((row) => {

                          prepareRow(row)

                          return (


                            <tr className='text-lg hover:bg-slate-400 border-t-2 border-white hover:text-slate-50 hover:font-semibold cursor-pointer' {...row.getRowProps()} onClick={(e) => {row.cells[0].value !== 'masterAdmin' ? history(row.cells[0].value) :  window.createNotification("warning","Unable to Edit / View Master Admin Account!","",2000); console.log("Master Admin");}}>

                              {row.cells.map((cell) => (
                              
                                <td className='py-4 px-3' {...cell.getCellProps()}>
                               
                               {cell.column.id === 'image' ? (
                                    renderImageCell(cell)
                                  ) : (
                                    cell.render('Cell')
                                  )}  
                                    
                                 
                                </td>
                               
                              ))}
 
                            </tr>
                          )

                        })
                      }

              </tbody>


            </table>
              ) : (<LoaderCustom />)
            }

          </div>



            </div>

    </div>
    
    



     


      ) : (<div className='text-center py-16 w-full flex flex-col gap-8 justify-center items-center m-auto select-none'> 

<div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight />Manage Users</div>
      
      <Link to={"../admin/systemUsers/new"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-emerald-500 p-3 rounded-lg hover:bg-emerald-400 w-full'>Create a New User <HiOutlineUserPlus /></button>
      </Link> 

      <Link to={"../admin/systemUsers/manage"} className='w-full md:w-[40%]'>
        <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-blue-500 p-3 rounded-lg hover:bg-blue-400 w-full'>Manage Users <HiOutlineUser /></button>
      </Link>
      </div>)
    

    
    )
    
    : (<Navigate to="/" />)
  )
}

export default ManageUsers