import React, { useState } from 'react';
import {getAuth, linkWithPhoneNumber, PhoneAuthProvider, RecaptchaVerifier, unlink} from 'firebase/auth';
import { app } from '../firebase.config';
import {FiLink2} from 'react-icons/fi';
import { MdOutline123, MdOutlineLinkOff, MdSend } from 'react-icons/md';
import CryptoJS from 'crypto-js';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { fetchCustomer } from '../utils/fetchLocalStorageData';


const LinkPhone = ({pNumber = null}) => {


    const firebaseAuth = getAuth(app);
    const provider = new PhoneAuthProvider();
    const [{user}, dispatch] = useStateValue();

    const [expandForm, setExpandForm] = useState(false);
    const [otpCode, setOtpCode] = useState(null);

    const customer = fetchCustomer();

    const generateRecaptcha = () => {

        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //onSignInSubmit();
            }
          }, firebaseAuth);

      };
      const requestOTP = (e) => {

     
        generateRecaptcha();

        if(pNumber.length >= 12){

            setExpandForm(true);

           generateRecaptcha();

           let appVerifier = window.recaptchaVerifier;

           linkWithPhoneNumber(firebaseAuth.currentUser, pNumber,appVerifier).then(

            confirmationResult => {

                window.confirmationResult = confirmationResult;

                //console.log(confirmationResult);
            
                
                

                
                setExpandForm(true);

            }

           ).catch((error) => {

            console.log("Not Sent! - " + error);
            setExpandForm(false);
           });


        }
      };

      const verifyOTP = () => {
        if(otpCode.length === 6){

            let confirmationResult = window.confirmationResult;

            confirmationResult.confirm(otpCode).then((result) => {

                const user = result.user;
                dispatch({
                  type: actionType.SET_USER,
                  user: user
              });
             

                localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user),localStorage.getItem('ffkey')).toString());
               setExpandForm("success");

               setTimeout(async () => {

                setExpandForm(false);
                
               }, 2000);


            }).catch((error) => {

                setExpandForm(false);
                console.log(error);
            })


        }else{

          window.createNotification('error',"Invalid OTP Code!",5000);
        }

      }

      const unlinkPhone = () => {

        unlink(firebaseAuth.currentUser, PhoneAuthProvider.PROVIDER_ID).then((result) => {
        
          const user = result;
          dispatch({
            type: actionType.SET_USER,
            user: user
        });
       
          localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user),localStorage.getItem('ffkey')).toString());

      
            // Auth provider unlinked from account
            // ...
          }).catch((error) => {
            // An error happened
            // ...
          });
      }


  return (
    <div className='w-full'>

        <div  id="recaptcha-container"></div>

        <div className='flex flex-col w-full gap-4'>
            <p className='text-xl font-semibold text-blue-600 w-full text-center justify-center flex items-center underline underline-offset-4'>DOCS</p>
            <p className='text-lg font-sans text-justify'>You are able to link/unlink your Phone Number {pNumber && (<span className='font-semibold italic'>({pNumber})</span>)} with this user account though this feature. If you link your Phone Number with this account, you are able to sign-in with Phone Number in the next time. </p>
            
        </div>

        {pNumber ? (

<div className='flex flex-col sm:flex-row w-full justify-center items-center mt-10 border-t-2 border-gray-600 p-4'>


                {!expandForm ? (<div className='w-full flex flex-col sm:flex-row gap-4 justify-center items-center'>

{customer.customerPhone !== pNumber ? (<div className='font-semibold text-red-600 italic'>Your current saved phone number is "{customer.customerPhone}" and you wish to link "{pNumber}". Unsaved numbers cannot be linked with this user account. First, save the number and link the saved number!</div>) : !user.phoneNumber ? (<button className='p-3 flex flex-row justify-center items-center gap-3 font-semibold text-lg bg-green-600 text-white rounded-md shadow-md w-[150px] hover:bg-green-700' onClick={(e) => {requestOTP();}}>Link <FiLink2 /></button>): !user.email ? (<div className='font-semibold text-red-600 italic'>Your account only has been linked with a Phone Number. If you wish to unlink the current phone number, please link your account with a Gmail Account!</div>) : (<button className='p-3 flex flex-row justify-center items-center gap-3 font-semibold text-lg bg-red-600 text-white rounded-md shadow-md w-[150px] hover:bg-red-700' onClick={(e) => {unlinkPhone();}}>Unlink <MdOutlineLinkOff /></button>)}



</div>


): expandForm === 'success' ? (<div className=' flex flex-col md:flex-row justify-center items-center gap-3'>

    <p className='text-md sm:text-lg md:text-xl text-emerald-700 font-semibold'>Successfully Linked!</p>

</div>):  (<div className=' flex flex-col md:flex-row justify-center items-center gap-3'>
     <MdOutline123 className="text-gray-700 text-2xl" />
            <input type="text" required value={otpCode ? otpCode : ""} onChange={(e) => {setOtpCode((e.target.value))} } placeholder="*One Time Password (OTP)" className="w-full h-full text-lg bg-transparent outline-none border-none placeholder:text-gray-400 text-textColor text-center" />
            <button className='rounded-full outline-none bg-emerald-500 text-white text-md p-3 hover:bg-emerald-600 hover:text-lg hover:drop-shadow-lg' onClick={(e) => {verifyOTP(e)}}><MdSend /></button>


</div>)}

            </div>
        
        
        
        
        ) : (<div className='text-lg font-semibold text-red-600'>Unable to proceed with this Phone Number!</div>)}


    </div>
  )
}

export default LinkPhone