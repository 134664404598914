import React, { useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';
import {MdChevronRight, MdShoppingBasket} from 'react-icons/md';
import {FcShop} from 'react-icons/fc';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import LoaderCustom from './LoaderCustom';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { useState } from 'react';
import { fetchBranch, fetchCart } from '../utils/fetchLocalStorageData';


const RowContainer = ({flag, data, scrollValue}) => {

  const rowContainer = useRef();

  const [items, setItems] = useState(fetchCart());

  const [branch, setBranch] = useState(fetchBranch());
  

  const [{cartItems,user}, dispatch] = useStateValue();

  const validateItem = (item) => {

    if(user){

     
     
  if(fetchBranch()){
    if(cartItems.findIndex(itemT => itemT.id === item.id) === -1){

      item.qty = 1;

      setItems([...cartItems, item]);
    }else{

        let index = parseInt(cartItems.findIndex(itemT => itemT.id === item.id));


        cartItems[index].qty = parseInt(cartItems[index].qty) + 1;
      
    
        addtocart();
    
     

        //Clear the Cart
    
    
      //console.log(cartItems.indexOf(item));
   

     
     // window.createNotification("warning","Unables to Add to Cart","This Item is already available in the cart!",3000);
    }

  }else{
    window.createNotification("error","Unable to Add to Cart","Please set the branch first!",3000);

  }
  }else{
    window.createNotification("error","Unable to Add to Cart","Please login to the system first!",3000);
  }
  };

  const addtocart = () => {

   

  

   
      
    dispatch({
      type: actionType.SET_CART_ITEMS,
      cartItems : items,
    });




    localStorage.setItem('cartItems',CryptoJS.AES.encrypt(JSON.stringify(items),localStorage.getItem('ffkey')).toString());;
   
    

    

  };

  useEffect(() => {

    if(user){

    addtocart();

  }else{
    console.log("Login to Handle Cart!");
  }
 
  }, [items]);
  


  var isDown = false;
  var startX;
  var scrollLeft;
 
  useEffect(() => {
 

      if (rowContainer && rowContainer.current) {


          rowContainer.current.addEventListener("mousedown",  (e) => {


            isDown = true;
            startX = e.pageX - rowContainer.current.offsetLeft;
            scrollLeft = rowContainer.current.scrollLeft;

        

         



          }, false);

          rowContainer.current.addEventListener("mouseleave",  (e) => {

            isDown = false;

          }, false);

          rowContainer.current.addEventListener("mouseup",  (e) => {

            isDown = false;

          }, false);

          rowContainer.current.addEventListener("mousemove",  (e) => {

            if(!isDown) { 
              
              
              
             
              return false;
            
            
            }
            e.preventDefault();

          

            const x = e.pageX - rowContainer.current.offsetLeft;

           const walk = x - startX;

           rowContainer.current.scrollLeft = scrollLeft  - walk;

          }, false);

 
         

      }
  }, []);

useEffect(() => {

  rowContainer.current.scrollLeft += scrollValue;

}, [scrollValue]);
  
  return (
   branch ? (
    <div 

    ref={rowContainer}


    
    className={`w-full flex items-center gap-4 my-12 select-none   cursor-grab active:cursor-grabbing ${flag ? 'overflow-x-scroll scrollbar-none' : 'overflow-x-hidden flex-wrap justify-center'}`}>
    {data? data.length > 0 ? data.map(item => (




        <div key={item?.id} className="w-275 min-w-[275px] md:w-300 h-[300px] md:min-w-[300px] bg-cardOverlay rounded-lg my-12 p-2 backdrop-blur-lg hover:drop-shadow-lg flex flex-col items-center justify-evenly relative">
        <div className="w-full flex items-center justify-between">

          <motion.div className='w-40 h-40 -mt-8 drop-shadow-2xl'  whileHover={{scale:1.2}}>

          <img src={item?.imageURL} alt={"FoodItem_" + item?.id} className="w-full h-full object-contain" />

          </motion.div>

       <div className="block gap-4 z-10">  

       <motion.div className="w-10 h-10 rounded-full bg-red-600 flex items-center justify-center cursor-pointer hover:shadow-md m-2" whileTap={{scale:0.75}} whileHover={{scale:1.2}} onClick={() => validateItem(item)}>
            <MdShoppingBasket className="text-white" />
          </motion.div>
       <Link to={"./item/"+item.id}>
          <motion.div className="w-10 h-10 rounded-full bg-green-600 flex items-center justify-center cursor-pointer hover:shadow-md m-2" whileTap={{scale:0.75}} whileHover={{scale:1.2}}>
            <MdChevronRight className="text-white text-3xl" />
          </motion.div>
        </Link>
       </div>
        </div>

        <div className="w-full flex flex-col items-end justify-end">
          <p className="text-textColor font-semibold text-base md:text-lg">{item?.title}</p>
          <p className="mt-1 text-sm text-gray-500">Calories : {item?.calories}</p>

          <div className="flex items-center gap-8">
            <p className="text-lg text-headingColor font-semibold">
              <span className="text-sm text-red-500">LKR</span> {item?.price}
            </p>
          </div>

        </div>


        </div>


    )) : (<div className='p-6 flex flex-col w-full justify-center items-center text-center font-semibold text-base sm:text-lg md:text-xl gap-2 text-red-600  drop-shadow-lg'>Sorry! No Stock Available!</div>) : (<div className='p-6 flex flex-col w-full justify-center items-center text-center font-semibold text-base sm:text-lg md:text-xl gap-2'>Loading...<LoaderCustom width={12} height={12} fg={'fill-red-600'} /> </div>)}
          
    </div>
   ) : (
   <div className='w-full flex flex-col justify-center items-center my-10 gap-4 border-2 border-red-600 py-4 px-2'  ref={rowContainer}>
     <span className='font-semibold text-lg text-red-500'>Please select your nearest branch to continue...</span>
     <Link to={'../map'}><button className='flex flex-row justify-center items-center gap-2 rounded-lg bg-yellow-500 font-semibold text-xl p-3 hover:drop-shadow-lg hover:bg-yellow-600' ><FcShop /> Set My Branch</button></Link>
    </div>
    )
  ) 
}

export default RowContainer