import React, { useEffect, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {VscChevronUp, VscChevronDown} from 'react-icons/vsc'
Chart.register(ArcElement);

Chart.register(ArcElement, Tooltip, Legend);



const MainStock = ({pass = undefined}) => {

  const [anView, setAnView] = useState(true);
  
  const [opView, setOpView] = useState(true);

  

  const now = new Date();
    
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  const today = `${year}-${month}-${day}`;


 const options = {
    legend: {
      "position": "bottom",
      align:'start',
      display:true,
      itemWrap: true,
      
    },
    
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
  };
  
const data = {
    labels: ['Out of Stocks', 'Available Stocks', 'Authorized Stock'],
    datasets: [
      {
        label: 'Items Count:',
        data: [12, 19, 3],
        backgroundColor: [
          'rgba(204, 0, 0, 0.7)',
          'rgba(0, 153, 51, 0.7)',
          'rgba(255, 206, 86, 0.7)',
  
        ],
        borderColor: [
          'rgba(204, 0, 0, 1)',
          'rgba(0, 153, 51, 1)',
          'rgba(255, 206, 86, 1)',
  
        ],
        borderWidth: 2,
      },
    ],
  };
  
    

   

    
  return (
    <div className='w-full  bg-gray-100'>

 {

  pass === 'new' ? (
  
    <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/mainStock"}>Main Stock</Link><MdKeyboardArrowRight />Add New Item</div> 
          
          
          
      </div>
  
  
  ) :   pass === 'manage' ? (
  
    <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/mainStock"}>Main Stock</Link><MdKeyboardArrowRight />Manage All Items</div> 
          
          
          
      </div>
  
  
  ) :   pass === 'transfer' ? (
  
    <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock/mainStock"}>Main Stock</Link><MdKeyboardArrowRight />Transfer Items</div> 
          
          
          
      </div>
  
  
  ) : (

    <div className='text-center py-16 w-full flex flex-col justify-center items-center m-auto select-none'>
          <div className='w-full text-left sm:flex items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base md:text-lg select-none'><Link className='text-blue-600 hover:text-blue-500' to={"../admin"}>Admin</Link><MdKeyboardArrowRight /><Link className='text-blue-600 hover:text-blue-500' to={"../admin/manageStock"}>Manage Stock</Link><MdKeyboardArrowRight />Main Stock</div>


        <div className="w-full flex flex-col gap-3 justify-center items-center p-5 drop-shadow-xl rounded-md bg-white my-5" id="dataVisual">
          <div className='w-full font-semibold  text-lg flex flex-row justify-between items-center'><span className='text-sm sm:text-md md:text-lg'>Dialy Analytics ({today})</span><span><button className='text-2xl font-semibold rounded-lg hover:bg-yellow-500 hover:text-white' onClick={(e)  => {setAnView(!anView);}}>{anView ? (<VscChevronUp />) : (<VscChevronDown />)}</button></span></div>
         {
          anView  && ( <div className='h-[500px] border-2 border-gray-400 p-4 w-full bg-gray-100 rounded-lg'>
          <Pie  options={options}  data={data} />
        
          </div>)

         }
        </div>

        <div className='w-full flex flex-col gap-3 justify-center items-center p-5 drop-shadow-xl rounded-md bg-white my-5 '>
        <div className='w-full font-semibold  text-lg flex flex-row justify-between items-center'><span className='text-sm sm:text-md md:text-lg'>Operations</span><span><button className='text-2xl font-semibold rounded-lg hover:bg-yellow-500 hover:text-white' onClick={(e)  => {setOpView(!opView);}}>{opView ? (<VscChevronUp />) : (<VscChevronDown />)}</button></span></div>

        {
          opView && (
            <div className='border-2 border-gray-400 w-full flex flex-col gap-3 justify-center items-center p-5 drop-shadow-xl rounded-md bg-white '>

            <Link to={"../admin/manageStock/mainStock/new"} className='w-full md:w-[40%]'>
            <button className='text-2xl flex flex-row items-center justify-center text-center text-emerald-50 bg-red-500 p-3 rounded-lg hover:bg-red-400 gap-2 w-full'>Add Stock Item</button>
          </Link> 
    
          <Link to={"../admin/manageStock/mainStock/manage"} className='w-full md:w-[40%]'>
            <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-yellow-700 p-3 rounded-lg hover:bg-yellow-600 gap-2 w-full'>Manage Stock Items</button>
          </Link>
  
          
          <Link to={"../admin/manageStock/mainStock/transfer"} className='w-full md:w-[40%]'>
            <button className='text-2xl flex flex-row items-center justify-center text-center text-blue-50 bg-orange-500 p-3 rounded-lg hover:bg-orange-400 gap-2 w-full'>Transfer Items</button>
          </Link>
  
  
  
            </div>
          )
        }
       
        </div>
        
    </div>
  )
 }
        
        
    </div>
  )
}

export default MainStock