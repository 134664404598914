import React from 'react';
import CryptoJS, { enc } from 'crypto-js';
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchBranch, fetchCart, fetchCustomer } from '../utils/fetchLocalStorageData';
import {  useJsApiLoader, DistanceMatrixService } from "@react-google-maps/api";
import { useEffect } from 'react';
import { useState } from 'react';
import { MdArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import {BsCreditCardFill} from 'react-icons/bs';
import {ImPointDown} from 'react-icons/im';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { motion } from 'framer-motion';
import E404 from './E404';
import LoaderCustom from './LoaderCustom';
import { saveOrders } from '../utils/firebaseFunctions';

const CheckOut = () => {
    const google = window.google;
    const {isLoaded} = useJsApiLoader({

        googleMapsApiKey: "AIzaSyB3oP4tYA04fcICAntjIidCe3GEbp2JU3A",
    
    
      });
    
    const [{cartShow}, dispatch] = useStateValue();
    const {amount,hash} =  useParams();
    const [valid, setValid] = useState(false);
    const [customerLoc, setCustomerLoc] = useState();
    const [checkoutCart, setCheckoutCart] = useState([]);
   const [origin, setOrigin] = useState([]);
   const [destination, setDestination] = useState([]);
   const [runner, setrRunner] = useState(false);

   const [orderDistance, setOrderDistance] = useState(null);
   const [orderDeliveryTime, setOrderDeliveryTime] = useState(null);
   const [orderDeliveryCharge, setOrderDeliveryCharge] = useState(null);
   const [orderDeliveryStatus, setOrderDeliveryStatus] = useState(false);


    const history = useNavigate();

    const branch = fetchBranch();

    const cartItems = fetchCart();

    const customer = fetchCustomer();

   
 useEffect(() => {

    
    if(customerLoc && isLoaded){
        let realLoc;

  
    switch (customerLoc){

        case "home":
            realLoc = customer.customerHomeAddressPosition;
            break;

        case "alt":
            realLoc = customer.customerAlternativeAddressPosition;
            break;

                
        case "office":
            realLoc = customer.customerOfficeAddressPosition;
            break;

                    
        case "business":
            realLoc = customer.customerBusinessAddressPosition;
            break;
            

        default:
            realLoc = [];
    }

    realLoc = JSON.parse(realLoc);

    let orgLoc = [{
        lat: parseFloat(branch.lat),
        lng: parseFloat(branch.lng)
   }];

   let desLoc = [{
    lat: parseFloat(realLoc.lat),
    lng: parseFloat(realLoc.lng)
}];




    setDestination(JSON.parse(JSON.stringify(desLoc)));
    setOrigin(JSON.parse(JSON.stringify(orgLoc)));
 
    
    setrRunner(true);

    }else{
        console.log('Loading... [www.officialkasun.com]');
        setrRunner(false);
    }
    
 
 }, [customerLoc])
 

   const calcDistance = (resp) => {

  
    if(resp){

        let distances = resp.rows[0].elements[0].distance;
    let deliveryTime = resp.rows[0].elements[0].duration;

   


   if((parseFloat(distances.value))/1000 > parseFloat((branch).maxKm)){

    setOrderDeliveryCharge(null);
    setOrderDeliveryTime(null);
    setOrderDistance(distances);
    setOrderDeliveryStatus(false);
   


   }else{

    let deliveryCharge = parseFloat((branch).kmCharge) * Math.ceil((((distances.value))/1000)).toFixed(2);

    
    setOrderDeliveryCharge(deliveryCharge);
    setOrderDeliveryTime(deliveryTime);
    setOrderDistance(distances);
    setOrderDeliveryStatus(true);
   }

    }else{
        console.log('Loading_2... [www.officialkasun.com]');
    }

    setrRunner(false);

   };
    


    const showCart = () => {

        dispatch({
            type: actionType.SET_CART_SHOW,
            cartShow: true
        });

    };

    const isValidUrl = (string) => {
        // Regular expression pattern for URLs
        const urlRegex = /^(https?|ftp):\/\/(-\.)?([^\s/?.#]+\.?)+(\/[^\s]*)?$/i;
      
        // Test the given string against the regex pattern
        return urlRegex.test(string);
      }

      const generateUniqueId = () => {
        const uuid = uuidv4();
        const bytes = enc.Utf8.parse(uuid);
        const hash = CryptoJS.SHA256(bytes);
        const hex = hash.toString(enc.Hex);
        return hex.slice(0, 20);
      }
    const payNow = async (e) => {

        //window.createNotification("info", "Under Development", "IPG is currently not integrated!", 5000);

      if(customer && customer.customerPhone && customer.customerName){

        const total = calcPrice(true);
        
        window.createNotification("info","Payment is Processing...", `Your LKR ${total} /= Payment is Processing...`,1000);
        e.target.disabled = true;


        const payRef = generateUniqueId();

        const last_amount = calcPrice(true);


        await fetch('https://api.viphotels.lk/ipg/payment', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            
            firstname : customer.customerName.split(" ")[0],
            lastname: 'VIP_CUSTOMER_LK',
            email: 'prolearners@hotmail.com',
            tele: customer.customerPhone,
            pay: last_amount,
            ref: payRef,
            red: "https://viphotels.lk/order/verify/"+payRef
    })
        })
        .then(response => response.json())
        .then(data => {

          
            

            if(data.url && isValidUrl(data.url)){

                window.createNotification("success","Opening Payment Gateway", 'Payment Gateway will be opened in a new window!',5000);

                const transaction_id = data.trans_id;
                const timestamp = data.timestamp;


              saveOrders(transaction_id,checkoutCart,payRef,last_amount,timestamp,0);
               

              
               
              
               setTimeout(() => {

                const newWindow =  window.open(data.url, '_top');

        

             
              
            e.target.disabled = true; 

          const intMar = setInterval(() => {
                if (newWindow.closed) {
                    e.target.disabled = false;
                    return () => clearInterval(intMar);
                }
              }, 1000);
 
            
                
               }, 1000);


            }else{

                window.createNotification("error","Unable to Process", 'Misleading process has been detected!',3000);
                e.target.disabled = false;



            }
             return true;
           
         


        })
        .catch(error => {

            e.target.disabled = false;
            console.log(error);
            window.createNotification("error","Unable to Process", 'Unable to make the payment at this moment!',3000);
            return false;
          
        })

      }else{

        window.createNotification("error","Unable to Process", 'Please set a valid phone number!',3000);
        return false;


      }
    }

    const calcPrice = (total = false) => {

        let price =  0;
    
        cartItems && cartItems.map(item => {
    
          let subPrice = parseFloat(item.price) * parseFloat(item.qty);
    
          price += subPrice;
    
    
        });

        if(total && orderDeliveryCharge){
            price = parseFloat(price) + parseFloat(orderDeliveryCharge)
        }
    
        price = parseFloat(price).toFixed(2);
    
    
       
    
        return price;
    
      };

    const checkValid = () =>{

        const cartItemsT = cartItems; 

        const genCode = CryptoJS.MD5(JSON.stringify(cartItemsT)).toString();
   
        if(genCode === hash){

            if(parseFloat(calcPrice()).toFixed(2) === parseFloat(amount).toFixed(2)){
                setValid(true);
                setCheckoutCart(cartItemsT);

            }else{
                setValid(false);
                setCheckoutCart([]);

            }

       
        }else{
            setValid(false);
            setCheckoutCart([]);
        }

        return valid;

    };
    

    useEffect(() => {

        checkValid();

        
    }, [hash])
    



  return ( customer ? (
    <div className='items-center text-center justify-center sm:justify-between w-full mt-5 select-none'>
         
       

        <div className='w-full text-left flex flex-row items-center bg-slate-100 p-2 rounded-lg shadow-sm text-base sm:text-lg mb-10 select-none'><span onClick={(e) => history(-1)} className='text-blue-600 hover:text-blue-500 cursor-pointer' >Back</span><MdKeyboardArrowLeft /><span onClick={showCart} className='text-blue-600 hover:text-blue-500 cursor-pointer' >Cart</span><MdKeyboardArrowLeft />Checkout</div>

        {valid ? (
        <div className='mt-8 w-full justify-center items-center flex  flex-col gap-3 bg-white drop-shadow-xl rounded-xl py-10 px-5'>
           {
            customer &&  checkoutCart && checkoutCart.length > 0 ? checkoutCart.map(item => (

                <div className='w-full flex flex-1 flex-col justify-between items-center rounded-md  gap-3 sm:flex-row drop-shadow-md bg-gray-200 hover:bg-gray-300 p-2' key={item?.id}>

                    <div className='w-full justify-center sm:justify-start items-center flex flex-col sm:block'><img src={item?.imageURL} className='w-20 h-20 max-w-[60px] md:w-40 md:h-40 md:max-w-[160px] rounded-full object-contain' alt="" /></div>

                    <div className='flex flex-col justify-start w-full sm:text-left'><span className='text-base   sm:text-lg md:text-xl'>{item?.title}</span><span className='font-semibold text-red-500'>Qty: {item?.qty}</span></div>

                    <div className='flex flex-col justify-end w-full gap-2 sm:text-right'><span className='text-base sm:text-xl font-semibold'>{parseFloat(parseFloat(item?.price)*item?.qty).toFixed(2)} LKR </span><span className='font-semibold text-sm text-red-500'>Unit: {parseFloat(item?.price).toFixed(2)} LKR </span><span className='w-full justify-center sm:justify-end flex text-lg font-semibold'><Link to={'../item/'+ item?.id}><button className='bg-orange-500 hover:bg-orange-600 text-white py-1 px-3  text-lg sm:text-base flex items-center justify-center rounded-lg' >View <MdArrowRight/></button></Link></span></div>


                </div>
            )  

            
            
            ) 
            
            
            : (<>Error - 506</>)
           }
           <div className='w-full flex flex-1 flex-col sm:flex-row justify-end border-gray-600 mt-3 border-t-2 items-center gap-3 sm:gap-10  bg-gray-50  p-2'><span className=' font-semibold text-2xl sm:text-3xl'>Sub Total</span><span className='text-white text-xl font-semibold underline-offset-4 underline outline-dashed outline-red-800 p-3 bg-red-500'>{calcPrice()} LKR</span></div>

           {branch ? (

            <div className='w-full'>

            <div className='w-full mt-5 border-t-2 border-gray-700 py-4 font-semibold text-md sm:text-lg'>Deliver from {branch?.location} Branch [<Link className='text-blue-500 hover:text-blue-700' to={"../map"}>Change</Link>]</div>

            <div className='w-full flex flex-col justify-center items-center font-semibold text-lg'><span className='text-yellow-800'>Select Your Address</span> {customerLoc ? (<span className='text-3xl text-yellow-800' ><ImPointDown /></span>): (<motion.span className='text-3xl text-yellow-800' initial={{opacity:0, y:-10}} animate={{opacity:1, y:0}} exit={{opacity:0, y:-10, }} transition={{  type: "spring", duration:3000, stiffness: 200, delay:1 , repeat: Infinity } }><ImPointDown /></motion.span>)}</div>

            <div className='w-full h-[250px] overflow-y-hidden mt-5 gap-2 flex flex-row scrollbar-thin scrollbar-track-transparent scrollbar-thumb-yellow-500 items-center justify-between outline-dashed p-2 outline-yellow-800 bg-gray-100  '>

               

            { customer && customer.customerHomeAddress && customer.customerHomeAddressPosition  && (

                    <div className={`w-[200px] h-full   flex flex-col break-words p-4  hover:bg-slate-800 hover:text-white active:bg-yellow-600 rounded-lg drop-shadow-lg cursor-pointer ${customerLoc === 'home' ? 'bg-green-600 font-semibold text-white' : 'bg-gray-300'}`} onClick={(e) => {setCustomerLoc('home')}}> <span className='w-full text-xl flex justify-center font-semibold'>HOME</span><span className='w-full text-base flex justify-center  py-2 border-t-2 border-yellow-500'>{customer.customerHomeAddress}</span></div>

            )}

            { customer && customer.customerOfficeAddress && customer.customerOfficeAddressPosition  && (

<div className={`w-[200px] h-full  flex flex-col break-words p-4  hover:bg-slate-800 hover:text-white active:bg-yellow-600 rounded-lg drop-shadow-lg cursor-pointer ${customerLoc === 'office' ? 'bg-green-600 font-semibold text-white' : 'bg-gray-300'}`} onClick={(e) => {setCustomerLoc('office')}}> <span className='w-full text-xl flex justify-center font-semibold'>OFFICE</span><span className='w-full text-base flex justify-center  py-2 border-t-2 border-yellow-500'>{customer.customerOfficeAddress}</span></div>

            )}

{ customer && customer.customerAlternativeAddress && customer.customerAlternativeAddressPosition  && (

<div className={`w-[200px] h-full   flex flex-col break-words p-4  hover:bg-slate-800 hover:text-white active:bg-yellow-600 rounded-lg drop-shadow-lg cursor-pointer ${customerLoc === 'alt' ? 'bg-green-600 font-semibold text-white' : 'bg-gray-300'}`} onClick={(e) => {setCustomerLoc('alt')}}> <span className='w-full text-xl flex justify-center  font-semibold'>Alternative</span><span className='w-full text-base flex justify-center  py-2 border-t-2 border-yellow-500'>{customer.customerAlternativeAddress}</span></div>

            )}

{ customer && customer.customerBusinessAddress && customer.customerBusinessAddressPosition  && (

<div className={`w-[200px] h-full  flex flex-col break-words p-4  hover:bg-slate-800 hover:text-white active:bg-yellow-600 rounded-lg drop-shadow-lg cursor-pointer ${customerLoc === 'business' ? 'bg-green-600 font-semibold text-white' : 'bg-gray-300'}`} onClick={(e) => {setCustomerLoc('business')}}> <span className='w-full text-xl flex justify-center  font-semibold'>Business</span><span className='w-full text-base flex justify-center py-2 border-t-2 border-yellow-500'>{customer.customerBusinessAddress}</span></div>

            )}



{isLoaded && customerLoc && destination &&  origin && runner && (<DistanceMatrixService
            options={{
                      destinations: destination,
                      origins: origin,
                      travelMode: "DRIVING",
                    }}
            callback = {(response) => {calcDistance(response)}}
           />)}





            </div>

            
            <div className='w-full mt-5 mb-[100px]'>
            {orderDeliveryStatus ? (

<div className='w-full flex flex-col justify-center items-center'>
<div className='font-semibold text-sm sm:text-md md:text-lg  flex flex-col  w-full justify-center items-center gap-3 bg-emerald-100 p-3 outline-dotted outline-green-500 rounded-lg'>

    
<span className='text-green-600'>We can deliver!</span>
    
    <span className='text-black'>Total Delivery Distance is {orderDistance.text}.</span>

    <span className='text-black'>Total Delivery Charge is {parseFloat(orderDeliveryCharge).toFixed(2)} LKR/= </span>

    <span className='text-red-600'>Estimated Delivery Time (Minimum) is {orderDeliveryTime.text}.</span>

</div>

<div className='mt-10'>

    <button className='flex flex-row gap-2 rounded-lg drop-shadow-xl hover:shadow-2xl hover:bg-blue-800 items-center justify-center text-white bg-blue-700 text-lg p-3 font-semibold disabled:bg-gray-400 disabled:hover:bg-slate-500 disabled:cursor-not-allowed' onClick={(e) => {payNow(e);}}> <BsCreditCardFill /> Pay {calcPrice(true)} LKR</button>
</div>


</div>

) : !orderDistance ? (

<div className='font-semibold text-lg text-blue-600 flex flex-col sm:flex-row w-full justify-center items-center gap-3'><LoaderCustom width='8' height='8' fg='fill-blue-600'  /> Waiting for Address... </div>

) : (

<div className='font-semibold text-sm sm:text-md md:text-lg  flex flex-col  w-full justify-center items-center gap-3 bg-yellow-200 p-3 outline-dotted outline-orange-500 rounded-lg'>

    
<span className='text-red-600'>Sorry! Unable to Deliver!</span>
    
    <span className='text-black'>Total Delivery Distance is {orderDistance.text}.</span>

    <span className='text-red-600'>"{branch.location}" Branch doesn't allow to deliver over {branch.maxKm} km.</span>

</div>

)}
            </div>
               

            </div>
            
           ):(

            <div className='w-full mt-5 border-t-2 border-gray-700 py-4 font-semibold text-md sm:text-lg'>[<Link className='text-blue-500 hover:text-blue-700' to={"../map"}>Set Branch to Continue...</Link>]</div>
           )
        
        }
        </div>
        ):(
        <E404 pass={'Invalid Checkout or Checkout Session has been expired!'}/>
        )
        
    
    }

    </div>
  ):(<E404 />)
  )
}

export default CheckOut